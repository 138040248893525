<template>
  <li
    :class="{
      parent_li: node.children && node.children.length !== 0,
      [`depth${node.calculatedStep || 0}`]: true,
    }"
    :style="{
      'padding-left':
        (node.calculatedStep === undefined ? 0 : node.calculatedStep - 1) * 20 +
        'px',
    }"
    @mouseover.stop="visible = true"
    @mouseout.stop="visible = false"
  >
    <i
      v-if="node.calculatedStep && node.children && node.children.length !== 0"
      :class="{
        'fa-plus-square-o': !node.isOpen,
        'fa-minus-square-o': node.isOpen,
      }"
      tabindex="0"
      class="fa icon-open-state"
      @keyup.enter.stop="toggle(node)"
      @click="toggle(node)"
    /><span
      :title="node.organizationName"
      :class="{
        'vue-tree-focus': node.organizationId === organizationId,
        'vue-tree-search': node.isSearched,
      }"
      tabindex="0"
      @keyup.enter.stop="orgSelect(node)"
      @click="orgSelect(node)"
      ><i v-if="showIcon(node)" :class="nodeClass(node)" />{{
        node.organizationName
      }}
    </span>
    <!-- TODO: 법인 생성 기능 추가 후, 제거 해야 할 코드 -->
    <el-button
      v-show="
        visible &&
        isCreateOrg &&
        node.organizationStep !== lastOrganizationStep &&
        node.organizationTypeCodeName !== 'ROOT'
      "
      size="mini"
      icon="el-icon-plus"
      @click="moveToCreate(node)"
      >{{ $t("HOG1000.버튼.신규조직_생성") }}</el-button
    >

    <ul v-show="node.isOpen">
      <li v-show="node.showLoading && node._loading">
        <i class="fa fa-spinner fa-pulse" />
      </li>
      <tree-item
        v-for="item in node.children"
        v-show="node.isHidden !== true"
        :key="item.id"
        :org-select="orgSelect"
        :organization-id="organizationId"
        :node="item"
        :last-organization-step="lastOrganizationStep"
        :is-create-org="isCreateOrg"
      />
    </ul>
  </li>
</template>

<script>
import Vue from "vue";

export default {
  name: "TreeItem",
  props: {
    // eslint-disable-next-line vue/require-default-prop
    node: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    orgSelect: {
      type: Function,
    },
    // eslint-disable-next-line vue/require-default-prop
    organizationId: {
      type: Number,
    },
    lastOrganizationStep: {
      required: true,
      type: Number,
    },
    isCreateOrg: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    "node.isOpen": function (val) {
      // eslint-disable-next-line no-prototype-builtins
      if (!this.node.hasOwnProperty("_loading")) {
        Vue.set(this.node, "_loading", false);
      }
      if (val) {
        if (typeof this.node.onOpened === "function") {
          this.node.onOpened(this.node);
        }
      } else {
        if (typeof this.node.onClosed === "function") {
          this.node.onClosed(this.node);
        }
      }
    },
  },
  methods: {
    showIcon: function (node) {
      return node.icon || node.openedIcon || node.closedIcon;
    },
    nodeClass: function (node) {
      if (node.isOpen) {
        return node.openedIcon || node.icon;
      } else {
        return node.closedIcon || node.icon;
      }
    },
    toggle: function (node) {
      if (node.superOrganizationId === null && node.isOpen) {
        return;
      }
      // eslint-disable-next-line no-prototype-builtins
      if (node.hasOwnProperty("isOpen")) {
        node.isOpen = !node.isOpen;
      } else {
        Vue.set(node, "isOpen", true);
      }
    },
    btnClick: function (btn, node) {
      if (typeof btn.click === "function") {
        btn.click(node);
      }
    },
    moveToCreate(node) {
      this.$router.replace(
        {
          name: "HOG1000",
          params: {
            organizationId: node.organizationId,
            effectiveDate: this.$moment(
              this.$route.params.effectiveDate,
            ).format("YYYYMMDD"),
          },
          query: {
            tab: "HOG1020",
            new: "true",
          },
        },
        () => {},
        () => {},
      );
    },
  },
};
</script>

<style lang="scss">
@import "~@/styles/sass/components/org-tree";
</style>
