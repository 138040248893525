/**
 * this is page for local user settings
 */

const defaults = {
  currentLocale: "ko",
  tab: {
    currentIndex: null,
    currentTab: "", // ProgramCode
    tabs: [],
  },
};

const settings = {
  namespaced: true,

  state: Object.assign({}, defaults),

  mutations: {
    update(state, data) {
      state = Object.assign({}, defaults, data);
    },
    updatecurrentLocale(state, data) {
      state.currentLocale = Object.assign({}, defaults.currentLocale, data);
    },
    updateTab(state, data) {
      state.tab = Object.assign({}, defaults.tab, data);
    },
    updateLocale(state, data) {
      // state.currentLocale = Object.assign({}, defaults.currentLocale, data);
      state.currentLocale = data;
    },
    clear(state) {
      // eslint-disable-next-line no-unused-vars
      state = Object.assign(state, defaults);
    },
  },
  getters: {
    currentLocale: (state) => {
      return state.currentLocale;
    },
    currentTab: (state) => {
      return state.tab.currentTab;
    },
  },
  actions: {
    clear({ commit }) {
      // 클리어
      commit("clear");
    },
    update({ commit }, data) {
      commit("update", data);
    },
    updateTab({ commit }, data) {
      commit("updateTab", data);
    },
    updateLocale({ commit }, { instance, locale, callback }) {
      // instance.$i18n.locale = locale; // locale 정보 commit
      commit("updateLocale", locale);
      instance.$nextTick(() => {
        callback();
      });
    },
    updatecurrentLocale({ instance, locale }) {
      const getLanguage = (locale) => {
        instance
          .$http({
            url: "http://localhost:3000/" + locale + ".json",
            method: "get",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            return response.data;
          });
      };
      getLanguage(locale).then(() => {
        instance.$nextTick(() => {
          instance.$i18n.locale = locale; // locale 정보 commit
        });
      });
    },
  },
};

export default settings;
