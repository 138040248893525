<template>
  <a
    v-if="!forceStatic"
    :href="path"
    class="tab-link"
    @keyup.enter.prevent.stop="handleLinkClick"
    @click.prevent="handleLinkClick"
  >
    <slot />
  </a>
  <span v-else><slot /></span>
</template>

<script>
export default {
  name: "TabLink",
  props: {
    name: {
      type: String,
      default: "default",
    },
    params: {
      type: Object,
      default: () => {},
    },
    path: {
      type: String,
      default: "",
    },
    forceStatic: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleLinkClick() {
      this.$tabs.open(this.$props);
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-link {
  -webkit-text-fill-color: $--link-color;
  color: $--link-color;
  cursor: pointer;

  &:hover {
    -webkit-text-fill-color: $--link-hover-color;
    color: $--link-hover-color;
    text-decoration: underline;
  }
}
</style>
