<template>
  <div class="command-k-item__inner">
    <div class="command-k-item__profile-wrapper">
      <el-avatar
        :size="32"
        alt="Profile Image"
        class="command-k-item__profile-image"
        :src="item.rawData.userPhoto"
        @error="handleError"
      >
        <img :src="unknownUserPhoto" alt="Profile Image" />
      </el-avatar>
    </div>

    <div class="comment-k-item__label">
      <div class="comment-k-item__text">
        {{ item.rawData.userName }}
      </div>
      <div class="comment-k-item__text--secondary">
        {{ item.rawData.organizationName }}
      </div>
    </div>

    <div class="command-k-item__text--detail">
      {{ $t("커맨드케이.리스트.아이템.텍스트.상세보기") }}
    </div>
  </div>
</template>

<script>
import unknownUserPhoto from "@/assets/unknown.jpg";

export default {
  name: "CommandKItemUser",

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      unknownUserPhoto,
    };
  },

  methods: {
    handleError() {
      return true;
    },
  },
};
</script>
