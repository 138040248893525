<template>
  <a :href="href" class="command-k-item__inner" tabindex="-1" @click.prevent>
    <div class="command-k-item__icon-wrapper">
      <iconify-icon class="command-k-item__icon" :icon="item.icon" />
    </div>

    <div class="comment-k-item__label comment-k-item__label--column">
      <div class="comment-k-item__text--badge">
        {{ makeMenuPath(item.rawData.menuPath) }}
      </div>

      <div class="comment-k-item__text">
        {{ item.rawData.menuName }}
      </div>
    </div>

    <iconify-icon
      class="command-k-item__icon"
      icon="material-symbols:chevron-right-rounded"
    />
  </a>
</template>

<script>
import { makeHostPath } from "@/components/command-k/utils";

/**
 * cmdK 아이템 중 메뉴 타입.
 */
export default {
  name: "CommandKItemMenu",

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    href() {
      return makeHostPath(this.item.rawData.system, this.item.rawData.urlPath);
    },
  },

  methods: {
    makeMenuPath(path) {
      const separator = ">";
      if (!path.includes(separator)) {
        return path;
      }
      const newPath = path.split(separator);

      return newPath.slice(0, newPath.length - 1).join(` ${separator} `);
    },
  },
};
</script>
