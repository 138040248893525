// import helpers from '@/auth/helpers';
import axios from "axios";
import { apiBaseUrl } from "@/config/cloudEnv";

let notificationInterval;
let errCount = 0;

const defaults = {
  unreadCount: 0,
};

// Global module loaded on first app load.
export default {
  namespaced: true,

  state: Object.assign({}, defaults),

  mutations: {
    clear(state) {
      // eslint-disable-next-line no-unused-vars
      state = Object.assign({}, defaults);
    },
    updateUnreadCount(state, unreadCount) {
      state.unreadCount = unreadCount;
    },
  },

  getters: {
    getUnreadCount: (state) => {
      return state.unreadCount;
    },
  },

  actions: {
    init({ dispatch }) {
      if (notificationInterval) return; // if now palling
      errCount = 0;
      dispatch("getCount");
      notificationInterval = window.setInterval(async () => {
        dispatch("getCount");
      }, 3 * 60 * 1000);
    },
    getCount({ commit, dispatch }) {
      if (!window.navigator.onLine) return;
      const baseUrl = apiBaseUrl();
      axios
        .get(`${baseUrl}/gw/notification/msg/unreadCount`, {
          withCredentials: true,
        })
        .then((response) => {
          const unreadCount = response.data.payload;
          commit("updateUnreadCount", unreadCount);
        })
        .catch(() => {
          errCount++;
          if (errCount >= 3) {
            dispatch("stopPolling");
          }
        });
    },
    stopPolling() {
      window.clearInterval(notificationInterval);
      notificationInterval = undefined;
    },
  },
};
