<template>
  <span :style="wrapperStyle" class="photo-icon">
    <i
      :class="`photo-icon__inner--${type}`"
      :style="innerStyle"
      class="photo-icon__inner"
    />
  </span>
</template>

<script>
import unknownImage from "@/assets/unknown.jpg";
export default {
  name: "PhotoIcon",
  props: {
    type: {
      type: String,
      default: "round",
    },
    src: {
      type: String,
      required: true,
    },
    fallbackSrc: {
      type: String,
      default: unknownImage,
    },
    width: {
      type: [String, Number],
      default: 30,
    },
    height: {
      type: [String, Number],
      default: 30,
    },
  },
  computed: {
    wrapperStyle() {
      const width =
        typeof this.width === "string" ? this.width : this.width + "px";
      const height =
        typeof this.height === "string" ? this.height : this.height + "px";
      return {
        width: width,
        height: height,
      };
    },
    innerStyle() {
      return {
        "background-image": `url(${this.src}), url(${this.fallbackSrc})`,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.photo-icon {
  height: 30px;
  width: 30px;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  .photo-icon__inner {
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    flex: 1 1 100%;
    height: 100%;
    width: 100%;
    &--round {
      border-radius: 50%;
    }
    &:after {
      background-color: transparent;
      bottom: 0;
      content: " ";
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }
  }
}
</style>
