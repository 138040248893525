export const DEFAULT_TABLE_HEIGHT = 600;

export const PROGRAM_ID = {
  JOB_DEFINITION: "JDM1010",
  JOB_DESCRIPTION: "JDM1020",
};

/*
 * 직무유형코드
 */
export const JOB_TYPE_CODE = {
  ROOT: 126459, //최상위노드
  JOB_GROUP: 126428, //직군
  JOB_SERIES: 126429, //직렬
  JOB: 126430, //직무
};

/**
 * 직무 ID
 */
export const JOB_ID = {
  ROOT: 0,
  MANAGE: 1, //관리
  ENT: 2, //엔터
  ENT_MANAGE: 3, //엔터-관리
  ARTIST: 4, //아티스트
  COMPOSE: 5, //작곡가
  PRODUCER: 6, //프로듀서
  SINGER: 7, //가수
};

export const DEFAULT = {
  END_YMD: "29991231",
};

export const MANIPULATION_TYPE = {
  CREATE: "C",
  UPDATE: "U",
  DELETE: "D",
};

// N.미표시
// Y.표시(전체미포함)
// A.표시(전체포함)'
export const CORP_COMBO_SHOW_FLAG = {
  WITH_ALL: "A",
  NOT: "N",
  SHOW: "Y",
};

/**
 *  /users/me/menu-trees/hr API에서 전달되는 auth company type에 따라 보여지는 법인이 달라진다.
 *  A - 전체법인의 경우 필터 없이 lookupType 'ORG_COMPANY_CODE' 인 코드가 모두 보인다.
 *  N - 선택법인의 경우 법인 중 사용자별 권한에 따라 법인 전체 중 부분집합이 보인다.
 *  Y - 소속법인의 경우 사용자 정보에 등록된 1개의 법인만 보인다.
 **/
export const AUTH_COMPANY_TYPE = {
  ALL: "A",
  SELECTED: "N",
  BELONG: "Y",
};

/**
 * 재직상태 LOOKUP_TYPE_ID 207
 */
export const OFFICE_STATUS = {
  HOLD: 58612, // 재직,
  HOLD_AND_SUSPEND: 58613, // 재직 + 휴직
  SUSPEND: 58614, //휴직
  RETIRE: 58615, //퇴직
};

export const SKILL_TYPE_CODE = {
  EDU_LEVEL: 126434, //학력
  MAJOR: 126435, //전공
  CERT: 126436, //자격증
  FORLANG: 126437, //외국어
  JOBEXP: 126438, //업무경험
  SKILL: 126439, //스킬
  ATTITUDE: 126440, //태도
  YEARS_CAREER: 126441, //경력연수
  ETC: 126442, //기타요건
};

/**
 * 입사일, 퇴사일 구분 파라미터
 */
export const DATE_TYPE = {
  START_DAY: "E",
  RESIGNATION_DAY: "R",
};

/**
 * DTM_REQ_TYPE (신청서구분)
 */
export const DTM_REQ_TYPES = {
  ANNUAL: "110", //연차
  ATTENDANCE: "111", //근태
  CONDOLENCE: "120", //경조사
  PREGNANCY: "130", //임신기근로단축
  CHILDCARE: "140", //육아기근로단축
  FAMILY_CARE: "145", //가족돌봄
  INFERTILITY: "150", //난임휴가
  EDUCATION: "160", //교육신청
  BUSINESS_TRIP: "170", //출장
  ALTERNATIVE: "200", //대체휴가
  OVERTIME_WORK: "300", //연장근무
  ABSENCE: "400", //휴복직
  HOLIDAY_WORK: "703", //휴일근무
  RESIGNATION: "730", //사직서
  COMPENSATORY: "900", //보상휴가신청
};

export const DTM_TYPE_CD = {
  ANNUAL: "101", // 연차
  ANNUAL_AM: "102", // 오전반차
  ANNUAL_PM: "103", // 오후반차
  ANNUAL_QUARTER: "104", // 반반차
  ANNUAL_TIME: "105", // 시간차
  VACCINE: "109", // 백신접종휴가
  HEALTH: "111", // 보건휴가
  COM_ANNUAL: "901", // 연차(보상휴가)
  COM_ANNUAL_AM: "902", // 오전반차(보상휴가)
  COM_ANNUAL_PM: "903", // 오후반차(보상휴가)
  COM_ANNUAL_QUARTER: "904", // 반반차(보상휴가)
  COM_ANNUAL_TIME: "905", // 시간차(보상휴가)
  PRENATAL_DIAGNOSIS: "1250", // 태아검진

  ANNUAL_ID: "126481", // 연차 ID
  ANNUAL_AM_ID: "126482", // 오전반차 ID
  ANNUAL_PM_ID: "126483", // 오후반차 ID
  ANNUAL_QUARTER_ID: "127533", // 반반차 ID
  ANNUAL_TIME_ID: "127598", // 시간차 ID
  COM_ANNUAL_ID: "127646", // 연차(보상휴가) ID
  COM_ANNUAL_AM_ID: "127641", // 오전반차(보상휴가) ID
  COM_ANNUAL_PM_ID: "127642", // 오후반차(보상휴가) ID
  COM_ANNUAL_QUARTER_ID: "127643", // 반반차(보상휴가) ID
  COM_ANNUAL_TIME_ID: "127644", // 시간차(보상휴가) ID
};
