<template>
  <div>
    <el-dialog
      :modal-append-to-body="true"
      :title="lang(title)"
      :width="width"
      class="el-dialog__wrapper-big"
      visible
      @close="$emit('close')"
    >
      <div class="person-issued">
        <panel
          v-if="pageFlag === 'new' || pageFlag === 'all'"
          v-loading="pending"
          :add-header="true"
          element-loading-background="transparent"
        >
          <h2 slot="title">{{ lang("폼.제증명신청") }}</h2>
          <el-form
            ref="form"
            :model="searchParams"
            label-position="right"
            label-width="150px"
          >
            <el-row :gutter="8">
              <el-col :span="10">
                <el-form-item :required="true">
                  <span slot="label">{{ lang("그리드.대상자") }}</span>
                  <el-input
                    v-model="searchParams.params1.employeeNumber"
                    size="small"
                    :value="searchParams.params1.employeeNumber"
                    :placeholder="lang('폼.placeholder.사번')"
                    :disabled="isEditableApplyData"
                    style="margin-right: 5px"
                    readonly
                  />
                  <el-input
                    v-model="searchParams.params1.employeeName"
                    v-focus
                    size="small"
                    :value="searchParams.params1.employeeName"
                    :clearable="true"
                    :maxlength="100"
                    :placeholder="lang('폼.placeholder.구성원')"
                    :disabled="isEditableApplyData"
                    @clear="clearSearchEmployee"
                    @keyup.delete.native="
                      searchParams.params1.employeeNumber = null
                    "
                    @keyup.enter.native="(ev) => findEmployee(ev.target.value)"
                  >
                    <i
                      v-if="
                        (printCount < 1 &&
                          draftId === null &&
                          sevenAfterDay > currentDay) ||
                        pageFlag === 'new'
                      "
                      slot="suffix"
                      tabindex="0"
                      class="el-icon-search el-input__icon"
                      style="cursor: pointer"
                      @keyup.enter.stop="findEmployeeDialogVisible = true"
                      @click="findEmployeeDialogVisible = true"
                    />
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col :span="1">
                <el-form-item label-width="0px">
                  <el-checkbox
                    v-model="searchParams.params1.inOffi"
                    :label="lang('그리드.퇴직자_포함')"
                    :false-label="null"
                    true-label="N"
                /></el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item :required="true">
                  <span slot="label">{{ lang("그리드.신청구분") }}</span>
                  <el-select
                    size="small"
                    :value="searchParams.params1.requestType"
                    :clearable="false"
                    :disabled="isEditableApplyData"
                    @change="
                      (val) => changeValue(val, 'requestType', searchParams)
                    "
                  >
                    <el-option
                      v-for="item in requestTypeList"
                      :key="`${item.lookupType}_${item.lookupValueId}`"
                      :value="item.lookupValueId"
                      :label="item.meaning"
                      :disabled="item.disabled"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="8">
              <el-col :span="11">
                <el-form-item :required="true">
                  <span slot="label">{{ lang("그리드.증명종류") }}</span>
                  <el-select
                    size="small"
                    :value="searchParams.params1.certificateType"
                    :clearable="false"
                    :disabled="isEditableApplyData"
                    @change="
                      (val) => changeValue(val, 'certificateType', searchParams)
                    "
                  >
                    <el-option
                      v-for="item in certificateTypeList"
                      :key="`${item.lookupCode}_${item.lookupValueId}`"
                      :value="item.lookupValueId"
                      :label="item.meaning"
                      :disabled="item.disabled"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item :required="true">
                  <span slot="label">{{ lang("그리드.제출처") }}</span>
                  <el-input
                    v-model="searchParams.common.submitPlace"
                    size="small"
                    :value="searchParams.common.submitPlace"
                    :disabled="isEditableApplyData"
                    @change="(val) => (searchParams.common.submitPlace = val)"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="8">
              <el-col :span="11">
                <el-form-item :required="true">
                  <span slot="label">{{ lang("그리드.신청용도") }}</span>
                  <el-select
                    v-model="searchParams.params1.requestPurpose"
                    size="small"
                    :value="searchParams.params1.requestPurpose"
                    :clearable="false"
                    :disabled="isEditableApplyData"
                    @change="
                      (val) => changeValue(val, 'requestPurpose', searchParams)
                    "
                  >
                    <el-option
                      v-for="item in requestPurposeList"
                      :key="`${item.lookupCode}_${item.lookupValueId}`"
                      :value="item.lookupValueId"
                      :label="item.meaning"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item :required="true">
                  <span slot="label">{{ lang("그리드.수령방법") }}</span>
                  <el-select
                    size="small"
                    :value="searchParams.params1.receiveType"
                    :clearable="false"
                    :disabled="isEditableApplyData"
                    @change="
                      (val) => changeValue(val, 'receiveType', searchParams)
                    "
                  >
                    <el-option
                      v-for="item in receiveTypeList"
                      :key="`${item.lookupCode}_${item.lookupValueId}`"
                      :value="item.lookupValueId"
                      :label="item.meaning"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="8">
              <el-col :span="11">
                <el-form-item :required="true">
                  <span slot="label">{{ lang("그리드.신청부수") }}</span>
                  <el-input
                    v-model="searchParams.params1.requestCount"
                    size="small"
                    :maxlength="3"
                    :disabled="isEditableApplyData"
                    @keyup.native="(val) => checkReg(val)"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item :required="true">
                  <span slot="label">{{ lang("그리드.희망수령일") }}</span>
                  <el-date-picker
                    v-model="searchParams.params1.wishYmd"
                    v-bind="datePicker"
                    :value="searchParams.params1.wishYmd"
                    :disabled="isEditableApplyData"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              v-if="
                checkMainTaskRequired ||
                checkDismissalRequired ||
                checkBusinessTrip
              "
              :gutter="8"
            >
              <el-col :span="11">
                <el-form-item
                  :required="
                    checkMainTaskRequired ||
                    checkDismissalRequired ||
                    checkBusinessTrip
                  "
                >
                  <span slot="label">{{ lang("그리드.기간") }}</span>
                  <el-date-picker
                    v-model="searchParams.params1.startYmd"
                    :disabled="
                      !(
                        checkMainTaskRequired ||
                        checkDismissalRequired ||
                        checkBusinessTrip
                      ) || isEditableApplyData
                    "
                    v-bind="datePicker"
                    :value="searchParams.params1.startYmd"
                  />
                  <span class="datebar">~</span>
                  <el-date-picker
                    v-model="searchParams.params1.endYmd"
                    :disabled="
                      !(
                        checkMainTaskRequired ||
                        checkDismissalRequired ||
                        checkBusinessTrip
                      ) || isEditableApplyData
                    "
                    v-bind="datePicker"
                    :value="searchParams.params1.endYmd"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="checkBusinessTrip" :gutter="8">
              <el-col :span="21">
                <el-form-item :required="checkBusinessTrip">
                  <span slot="label">{{ lang("그리드.출장지역") }}</span>
                  <el-input
                    v-model="searchParams.common.businessTripPlace"
                    size="small"
                    :disabled="!checkBusinessTrip || isEditableApplyData"
                    :value="searchParams.common.businessTripPlace"
                    :maxlength="100"
                    @change="
                      (val) => (searchParams.common.businessTripPlace = val)
                    "
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="checkBusinessTrip" :gutter="8">
              <el-col :span="21">
                <el-form-item :required="checkBusinessTrip">
                  <span slot="label">{{ lang("그리드.출장목적") }}</span>
                  <el-input
                    v-model="searchParams.common.businessTripPurpose"
                    size="small"
                    :disabled="!checkBusinessTrip || isEditableApplyData"
                    :value="searchParams.common.businessTripPurpose"
                    :maxlength="100"
                    @change="
                      (val) => (searchParams.common.businessTripPurpose = val)
                    "
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="checkDismissalRequired" :gutter="8">
              <el-col :span="21">
                <el-form-item :required="checkDismissalRequired">
                  <span slot="label">{{ lang("그리드.자격") }}</span>
                  <el-input
                    v-model="searchParams.common.licenseDescription"
                    size="small"
                    :disabled="!checkDismissalRequired || isEditableApplyData"
                    :value="searchParams.common.licenseDescription"
                    :maxlength="100"
                    @change="
                      (val) => (searchParams.common.licenseDescription = val)
                    "
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="checkDismissalRequired" :gutter="8">
              <el-col :span="21">
                <el-form-item :required="checkDismissalRequired">
                  <span slot="label">{{ lang("그리드.용역내역") }}</span>
                  <el-input
                    v-model="searchParams.common.printDescription"
                    size="small"
                    :disabled="!checkDismissalRequired || isEditableApplyData"
                    :value="searchParams.common.printDescription"
                    :maxlength="200"
                    @change="
                      (val) => (searchParams.common.printDescription = val)
                    "
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="checkMainTaskRequired" :gutter="8">
              <el-col :span="21">
                <el-form-item :required="checkMainTaskRequired">
                  <span slot="label">{{ lang("그리드.업무내용") }}</span>
                  <el-input
                    v-model="searchParams.common.printDescription"
                    size="small"
                    :disabled="!checkMainTaskRequired || isEditableApplyData"
                    :value="searchParams.common.printDescription"
                    :maxlength="200"
                    @change="
                      (val) => (searchParams.common.printDescription = val)
                    "
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="checkMainTaskRequired" :gutter="8">
              <el-col :span="21">
                <el-form-item :required="checkMainTaskRequired">
                  <span slot="label">{{ lang("그리드.미수행시문제점") }}</span>
                  <el-input
                    v-model="searchParams.common.unperformedArise"
                    size="small"
                    :disabled="!checkMainTaskRequired || isEditableApplyData"
                    :value="searchParams.common.unperformedArise"
                    :maxlength="1000"
                    type="textarea"
                    resize="none"
                    @change="
                      (val) => (searchParams.common.unperformedArise = val)
                    "
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="8">
              <el-col :span="21">
                <el-form-item>
                  <span slot="label">{{ lang("그리드.비고") }}</span>
                  <el-input
                    v-model="searchParams.params1.note"
                    size="small"
                    :value="searchParams.params1.note"
                    :maxlength="200"
                    :disabled="isEditableApplyData"
                    @change="(val) => (searchParams.params1.note = val)"
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </panel>

        <panel
          v-if="pageFlag === 'all'"
          v-loading="pending"
          :add-header="true"
          element-loading-background="transparent"
        >
          <h2 slot="title">{{ lang("폼.제증명_출력_내용") }}</h2>
          <el-form
            :model="searchParams"
            label-position="right"
            label-width="150px"
          >
            <el-row :gutter="8">
              <el-col :span="11">
                <el-form-item :required="true">
                  <span slot="label">{{ lang("그리드.대상자명") }}</span>
                  <el-input
                    v-model="searchParams.params2.printEmployeeName"
                    size="small"
                    :maxlength="100"
                    :value="searchParams.params2.printEmployeeName"
                    :disabled="isEditableApplyData"
                    @change="
                      (val) => (searchParams.params2.printEmployeeName = val)
                    "
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="8">
              <el-col :span="11">
                <el-form-item :required="true">
                  <span slot="label">{{ lang("그리드.직책") }}</span>
                  <el-input
                    v-model="searchParams.params2.printDutyName"
                    size="small"
                    :maxlength="100"
                    :value="searchParams.params2.printDutyName"
                    :disabled="isEditableApplyData"
                    @change="
                      (val) => (searchParams.params2.printDutyName = val)
                    "
                  />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item :required="true">
                  <span slot="label">{{ lang("그리드.소속") }}</span>
                  <el-input
                    v-model="searchParams.params2.printOrganizationName"
                    size="small"
                    :maxlength="1000"
                    :value="searchParams.params2.printOrganizationName"
                    :disabled="isEditableApplyData"
                    @change="
                      (val) =>
                        (searchParams.params2.printOrganizationName = val)
                    "
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="8">
              <el-col :span="11">
                <el-form-item :required="true">
                  <span slot="label">{{ lang("그리드.신청용도") }}</span>
                  <!-- <code-select
                    :clearable="false"
                    :value="searchParams.params2.printRequestPurpose"
                    v-model="searchParams.params2.printRequestPurpose"
                    style="width:300px"
                    code="PHM_REQUEST_PURPOSE"
                    @change="val => searchParams.params2.printRequestPurpose = val"
                  /> -->
                  <el-input
                    v-model="searchParams.params2.printRequestPurpose"
                    size="small"
                    :value="searchParams.params2.printRequestPurpose"
                    :maxlength="100"
                    :disabled="isEditableApplyData"
                    @change="
                      (val) => (searchParams.params2.printRequestPurpose = val)
                    "
                  />
                </el-form-item>
              </el-col>
              <el-col :span="10">
                <el-form-item :required="true">
                  <span slot="label">{{ lang("그리드.제출처") }}</span>
                  <el-input
                    size="small"
                    :maxlength="100"
                    :value="searchParams.common.submitPlace"
                    :disabled="isEditableApplyData"
                    @change="(val) => (searchParams.common.submitPlace = val)"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row
              v-if="
                checkMainTaskRequired ||
                checkDismissalRequired ||
                checkBusinessTrip
              "
              :gutter="8"
            >
              <el-col :span="21">
                <el-form-item :required="false">
                  <span slot="label">{{ lang("그리드.기간") }}</span>
                  <el-input
                    size="small"
                    :maxlength="100"
                    :value="searchParams.params2.printTerm"
                    :disabled="isEditableApplyData"
                    @change="(val) => (searchParams.params2.printTerm = val)"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="8">
              <el-col :span="21">
                <el-form-item :required="false">
                  <span slot="label">{{ lang("그리드.주소") }}</span>
                  <el-input
                    size="small"
                    :maxlength="300"
                    :value="searchParams.params2.printAddress"
                    :disabled="isEditableApplyData"
                    @change="(val) => (searchParams.params2.printAddress = val)"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="checkBusinessTrip" :gutter="8">
              <el-col :span="21">
                <el-form-item :required="checkBusinessTrip">
                  <span slot="label">{{ lang("그리드.출장지역") }}</span>
                  <el-input
                    size="small"
                    :maxlength="100"
                    :value="searchParams.common.businessTripPlace"
                    :disabled="isEditableApplyData"
                    @change="
                      (val) => (searchParams.common.businessTripPlace = val)
                    "
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="checkBusinessTrip" :gutter="8">
              <el-col :span="21">
                <el-form-item :required="checkBusinessTrip">
                  <span slot="label">{{ lang("그리드.출장목적") }}</span>
                  <el-input
                    size="small"
                    :maxlength="100"
                    :value="searchParams.common.businessTripPurpose"
                    :disabled="isEditableApplyData"
                    @change="
                      (val) => (searchParams.common.businessTripPurpose = val)
                    "
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="checkDismissalRequired" :gutter="8">
              <el-col :span="21">
                <el-form-item :required="checkDismissalRequired">
                  <span slot="label">{{ lang("그리드.자격") }}</span>
                  <el-input
                    size="small"
                    :maxlength="100"
                    :value="searchParams.common.licenseDescription"
                    :disabled="isEditableApplyData"
                    @change="
                      (val) => (searchParams.common.licenseDescription = val)
                    "
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="checkDismissalRequired" :gutter="8">
              <el-col :span="21">
                <el-form-item :required="checkDismissalRequired">
                  <span slot="label">{{ lang("그리드.용역내역") }}</span>
                  <el-input
                    size="small"
                    :maxlength="200"
                    :value="searchParams.common.printDescription"
                    :disabled="isEditableApplyData"
                    @change="
                      (val) => (searchParams.common.printDescription = val)
                    "
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="checkMainTaskRequired" :gutter="8">
              <el-col :span="21">
                <el-form-item :required="checkMainTaskRequired">
                  <span slot="label">{{ lang("그리드.업무내용") }}</span>
                  <el-input
                    size="small"
                    :maxlength="200"
                    :value="searchParams.common.printDescription"
                    :disabled="isEditableApplyData"
                    @change="
                      (val) => (searchParams.common.printDescription = val)
                    "
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="checkMainTaskRequired" :gutter="8">
              <el-col :span="21">
                <el-form-item :required="checkMainTaskRequired">
                  <span slot="label">{{ lang("그리드.미수행시문제점") }}</span>
                  <el-input
                    size="small"
                    :value="searchParams.common.unperformedArise"
                    :disabled="isEditableApplyData"
                    :maxlength="1000"
                    type="textarea"
                    resize="none"
                    @change="
                      (val) => (searchParams.common.unperformedArise = val)
                    "
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </panel>

        <div class="save-btn-warrap">
          <el-button
            slot="button"
            :disabled="
              printCount > 0 ||
              draftId !== null ||
              (pageFlag === 'all' && sevenAfterDay < currentDay)
            "
            type="primary"
            size="small"
            @click="saveCertificate(searchParams)"
            >{{ lang("버튼.저장") }}
          </el-button>
        </div>
      </div>
    </el-dialog>

    <!-- 사원 찾기 -->
    <HPE1210
      v-if="findEmployeeDialogVisible"
      :value="searchParams.params1.employeeName"
      :company-original-id="companyId"
      :in-offi-yn="searchParams.params1.inOffi"
      @change="setSearchEmployee"
      @close="findEmployeeDialogVisible = false"
    />
  </div>
</template>

<script>
import helpers from "@/auth/helpers";
import model from "@/services/model/HPE/HPE1915";
import Panel from "@/components/portlets/Panel";
import { i18nTranslator } from "@/mixins/i18n";
// import { authEventUtil } from '@/mixins/auth';
import validator from "../../lib/validator";
import { datePicker } from "@/lib/element-base-options";
import { dateFormat } from "@/lib/util";
import CodeSelect from "@/components/form/CodeSelect";
import { CommonAPI } from "@/services/api";
import HPE1210 from "@/pages/POPUP/HPE1210";
import currentTabFetchMixin from "@/mixins/currentTabFetchMixin";
import safe from "@/mixins/safe";
import moment from "moment";

export default {
  name: "HPE1915",
  components: {
    Panel,
    // eslint-disable-next-line vue/no-unused-components
    CodeSelect,
    HPE1210,
  },
  mixins: [
    i18nTranslator("HPE1915"),
    safe("HPE1910", ["searchParams"]),
    currentTabFetchMixin,
  ],
  props: {
    // eslint-disable-next-line
    keyword: String,
    // eslint-disable-next-line
    pageFlag: String,
    // eslint-disable-next-line
    perCertificateId: Number,
    title: {
      type: String,
      default: "폼.제증명신청",
    },
    width: {
      type: String,
      default: "1000px",
    },
  },
  data() {
    return {
      previousElement: null,
      pending: false,
      gridData: [],
      empInputText: null,
      findEmployeeDialogVisible: false,
      printCount: null,
      draftId: null,
      sevenAfterDay: null, // 7일 뒤 날짜
      currentDay: moment().format("YYYYMMDD"), // 오늘 날짜
      requestTypeList: [], // 신청구분 코드
      certificateTypeList: [], // 증명종류 코드
      employmentCertificateList: [], // 증명종류 중 재직증명서(재직증명서_경력포함) list
      departmentManagementList: [], // 주관부서수령만 나오는 list
      defaultReceiveTypeList: [],
      codeStatus: true,
      // receiveTypeList: [], // 수령방법 코드
      registrationNumberMarkTypeList: [], // 주민번호표기 코드
      sealPrintTypeList: [], // 직인출력 코드,
      list: [], // 선택한 증명서에 따라 수령방법이 다르게 하기 위한 list
      requestPurposeList: [], // 신청용도 코드
      datePicker,
      dateFormat,
      employeeId: null,
      organizationId: null,
      dutyCode: null,
      form: {},
      searchParams: {
        params1: {
          // 제증명신청 부분
          employeeNumber: null, // 사번
          employeeName: null, // 이름
          requestType: null, // 신청구분
          requestOrganizationId: null, // 신청 조직Id
          requestPositionId: null, // 신청 직책
          certificateType: null, // 증명종류
          requestPurpose: null, // 신청용도
          receiveType: null, // 수령방법
          registrationNumberMarkType: null, // 주민번호표기
          wishYmd: null, // 희망수령일
          requestCount: null, // 신청부수
          sealPrintType: null, // 직인출력
          startYmd: null, // 기간-시작일
          endYmd: null, // 기간-종료일
          note: null, // 비고
          inOffi: null,
        },
        params2: {
          // 제증명 출력 내용 부분
          printEmployeeName: null, // 대상자명
          printRegistrationNumber: null, // 주민번호
          printDutyName: null, // 직책
          printOrganizationName: null, // 소속
          printRequestPurpose: null, // 신청용도
          printTerm: null, // 기간
          printAddress: null, // 주소
        },
        common: {
          submitPlace: null, // 제출처
          businessTripPlace: null, // 출장지역
          businessTripPurpose: null, // 출장목적
          licenseDescription: null, // 자격
          printDescription: null, // 경력, 업무내용, 용역내역
          unperformedArise: null, // 미수행시문제점
          perCertificateId: null, // Update를 위한 DB의 PK값
        },
        orgData: {},
      },
      gridSearch: {
        rowData: {},
        useFlag: false,
        empName: null,
        selectedRow: null,
        empStr: null,
        selectedColumn: null,
        commentStr: null,
      },
    };
  },
  computed: {
    receiveTypeList() {
      /* 증명종류에 따라, 수령방법이 다르므로, 값을 불러왔을 때 증명종류에 맞게 수령방법 데이터가 나오도록 처리 */
      return this.codeStatus
        ? this.defaultReceiveTypeList
        : this.departmentManagementList;
    },
    companyId() {
      return this.$store.getters["common/searchParams"].lookupValueId;
    },
    emptyModel() {
      const { employeeId, personId } = this.employee;
      return Object.assign({}, model, {
        employeeId,
        personId,
      });
    },
    // 재직증명서
    checkCertificateRequired() {
      const certificate = this.certificateTypeList.filter((list) => {
        return list.lookupCode.charAt(0) === "1";
      });
      const lookupValueIdMapper = certificate.map((list) => list.lookupValueId);

      return lookupValueIdMapper.includes(
        this.searchParams.params1.certificateType,
      );
    },
    // 재직증명서_경력포함
    checkCertificateCareerRequired() {
      const certificateCareer = this.certificateTypeList.filter((list) => {
        return list.lookupCode.charAt(0) === "2";
      });
      const lookupValueIdMapper = certificateCareer.map(
        (list) => list.lookupValueId,
      );

      return lookupValueIdMapper.includes(
        this.searchParams.params1.certificateType,
      );
    },
    // 경력증명서
    checkCareerRequired() {
      const career = this.certificateTypeList.filter((list) => {
        return list.lookupCode.charAt(0) === "3";
      });
      const lookupValueIdMapper = career.map((list) => list.lookupValueId);

      return lookupValueIdMapper.includes(
        this.searchParams.params1.certificateType,
      );
    },
    // 퇴직증명서
    checkRetirementRequired() {
      const retirement = this.certificateTypeList.filter((list) => {
        return list.lookupCode.charAt(0) === "4";
      });
      const lookupValueIdMapper = retirement.map((list) => list.lookupValueId);

      return lookupValueIdMapper.includes(
        this.searchParams.params1.certificateType,
      );
    },
    // 출장증명서
    checkBusinessTrip() {
      const businessTrip = this.certificateTypeList.filter((list) => {
        return list.lookupCode.charAt(0) === "5";
      });
      const lookupValueIdMapper = businessTrip.map(
        (list) => list.lookupValueId,
      );

      return lookupValueIdMapper.includes(
        this.searchParams.params1.certificateType,
      );
    },
    // 주요업무수행확인서
    checkMainTaskRequired() {
      const mainTask = this.certificateTypeList.filter((list) => {
        return list.lookupCode.charAt(0) === "7";
      });
      const lookupValueIdMapper = mainTask.map((list) => list.lookupValueId);

      return lookupValueIdMapper.includes(
        this.searchParams.params1.certificateType,
      );
    },
    // 해촉증명서
    checkDismissalRequired() {
      const dismissal = this.certificateTypeList.filter((list) => {
        return list.lookupCode.charAt(0) === "8";
      });
      const lookupValueIdMapper = dismissal.map((list) => list.lookupValueId);

      return lookupValueIdMapper.includes(
        this.searchParams.params1.certificateType,
      );
    },
    // 신청용도 - 기타
    checkPurposeRequired() {
      const purpose = this.requestPurposeList.filter((list) => {
        return list.lookupCode === "99";
      });
      const lookupValueIdMapper = purpose.map((list) => list.lookupValueId);

      return lookupValueIdMapper.includes(
        this.searchParams.params1.requestPurpose,
      );
    },
    defaultRequestType() {
      // 신청구분 - 주직(겸직)
      return this.requestTypeList.find((e) => e.lookupCode === "1")
        ?.lookupValueId;
    },
    isEditableApplyData() {
      /*
      신청 내용 수정 불가 조건
      1. 출력횟수가 1 이상
      2. EA에서 신청한 기안
      3. 신규 = 'new', 상세보기 = 'all'
      4. 신청한지 7일 이후 기안
      */

      const hasPrinted = this.printCount > 0;
      const hasDraft = this.draftId !== null;
      const isViewPage = this.pageFlag === "all";
      const isOverOneWeek = this.sevenAfterDay < this.currentDay;

      return hasPrinted || hasDraft || (isViewPage && isOverOneWeek);
    },
  },
  created() {
    this.previousElement = document.activeElement;
  },

  destroyed() {
    this.previousElement && this.previousElement.focus();
  },
  mounted() {
    this.getCode();
    this.fetch();
  },
  methods: {
    async getCode() {
      /* 신청구분 코드 가져오기 */
      const requestTypeList = await CommonAPI.getCodes({
        lookupType: "PHM_REQUEST_TYPE",
      });
      this.requestTypeList = requestTypeList.data.payload;

      /* 증명종류 코드 가져오기 */
      const requestCertificateType = await CommonAPI.getCodes({
        lookupType: "PHM_CERTIFICATE_TYPE",
      });
      this.certificateTypeList = requestCertificateType.data.payload;

      // 재직증명서(재직증명서_경력포함)만 들어있는 list
      this.employmentCertificateList = this.certificateTypeList.filter(
        (el) =>
          el.lookupCode.charAt(0) === "1" || el.lookupCode.charAt(0) === "2",
      );

      /* 수령방법 코드 가져오기 */
      const requestReceiveType = await CommonAPI.getCodes({
        lookupType: "PHM_RECEIVE_TYPE",
      });
      this.defaultReceiveTypeList = requestReceiveType.data.payload;

      // 주관부서수령만 나오도록
      this.departmentManagementList = this.defaultReceiveTypeList.filter(
        (el) => el.lookupCode === "20",
      );

      /* 주민번호표기 코드 가져오기 */
      const requestRegistrationNumberMarkType = await CommonAPI.getCodes({
        lookupType: "PHM_REGISTRATION_NUMBER_MARK_TYPE",
      });
      this.registrationNumberMarkTypeList =
        requestRegistrationNumberMarkType.data.payload;

      /* 직인출력 코드 가져오기 */
      const requestSealPrintType = await CommonAPI.getCodes({
        lookupType: "PHM_SEAL_PRINT_TYPE",
      });
      this.sealPrintTypeList = requestSealPrintType.data.payload;

      /* 신청용도 코드 가져오기 */
      const requestPurpose = await CommonAPI.getCodes({
        lookupType: "PHM_REQUEST_PURPOSE",
      });
      this.requestPurposeList = requestPurpose.data.payload;
    },
    async fetch() {
      this.pending = true;

      try {
        // HPE1910에서 넘어온 perCertificatedId가 있으면 실행(상세 클릭시)
        if (this.perCertificateId !== null) {
          const {
            data: { payload },
          } = await helpers.get(`/proof/apply/${this.perCertificateId}/detail`);
          // 사원ID 저장
          this.employeeId = payload.employeeId;
          this.organizationId = payload.organizationId;
          this.dutyCode = payload.dutyCode;
          this.printCount = payload.printCount;
          this.draftId = payload.draftId;
          this.sevenAfterDay = moment(payload.applicationYmd)
            .add(7, "days")
            .format("YYYYMMDD");

          // 저장시 필수 parameter 셋팅
          for (let i in payload) {
            if (i.includes("org")) {
              this.searchParams.orgData[i] = payload[i];
            }
          }

          const payloadEntry = Object.entries(payload);
          const params1 = Object.entries(this.searchParams.params1).map(
            (data) => data[0],
          );
          const params2 = Object.entries(this.searchParams.params2).map(
            (data) => data[0],
          );
          const common = Object.entries(this.searchParams.common).map(
            (data) => data[0],
          );

          const setParams = (data, str) => {
            for (let i = 0; i < payloadEntry.length; i++) {
              for (let j = 0; j < data.length; j++) {
                if (payloadEntry[i][0] === data[j]) {
                  this.searchParams[str][data[j]] = payloadEntry[i][1];
                }
              }
            }
          };

          setParams(params1, "params1");
          setParams(params2, "params2");
          setParams(common, "common");
        }
      } finally {
        this.pending = false;
      }
    },
    clearSearchEmployee() {
      const searchParams = this.searchParams;
      searchParams.params1.employeeName = null;
      searchParams.params1.employeeNumber = null;
      this.employeeId = null;
    },
    async findEmployee(val) {
      if (val) this.searchParams.params1.employeeName = val.trim();
      if (!this.searchParams.params1.employeeName) return;

      const {
        data: { payload },
      } = await helpers.get(`/employees`, {
        companyOriginalId: this.companyOriginalId,
        inputText: this.searchParams.params1.employeeName,
        inOffi: this.searchParams.params1.inOffi,
      });

      if (payload.length === 1) {
        this.setSearchEmployee(payload[0]);
      } else if (payload.length > 1) {
        this.findEmployeeDialogVisible = true;
      } else {
        this.$message({
          type: "warning",
          message: this.lang("msg.noResult"),
        });
      }
    },
    async setSearchEmployee(employee) {
      const {
        employeeName,
        employeeNumber,
        employeeId,
        organizationId,
        dutyCode,
      } = employee;

      this.searchParams.params1 = {
        ...this.searchParams.params1,
        employeeName,
        employeeNumber,
        employeeId,
        requestOrganizationId: organizationId,
        requestPositionId: dutyCode,
        requestType: null,
      };
      this.employeeId = employeeId;
      this.organizationId = organizationId;
      this.dutyCode = dutyCode;
      await this.setRequestTypeListDisabled(employeeId);

      this.findEmployeeDialogVisible = false;
    },
    async saveCertificate(data) {
      /*
         신규 버튼 클릭 시 pageFlag 는 new
         상세보기 버튼 클릭 시 pageFlag 는 all
      */
      const manipulationType = this.pageFlag === "new" ? "C" : "U";

      if (manipulationType === "U") {
        data.common.perCertificateId = this.perCertificateId;
      }

      // 기간 시작일, 종료일 체크
      if (data.params1.startYmd > data.params1.endYmd) {
        return this.$message({
          type: "warning",
          message: this.lang("메세지.일자재선택필요"),
        });
      }

      // 신청부수 count 체크
      const count = Number(data.params1.requestCount);

      if (
        data.params1.employeeNumber == null ||
        data.params1.employeeName == null
      ) {
        return this.$message({
          type: "warning",
          message: this.lang("메세지.대상자필수입력"),
        });
      }
      if (data.params1.certificateType == null) {
        return this.$message({
          type: "warning",
          message: this.lang("메세지.증명종류필수입력"),
        });
      }
      if (data.params1.requestPurpose == null) {
        return this.$message({
          type: "warning",
          message: this.lang("메세지.신청용도필수입력"),
        });
      }

      if (count < 1) {
        return this.$message({
          type: "warning",
          message: this.lang("메세지.신청부수재입력"),
        });
      }

      if (
        data.common.submitPlace == null ||
        data.common.submitPlace.length === 0
      ) {
        return this.$message({
          type: "warning",
          message: this.lang("메세지.제출처필수입력"),
        });
      }
      if (data.params1.receiveType == null) {
        return this.$message({
          type: "warning",
          message: this.lang("메세지.수령방법필수입력"),
        });
      }
      if (data.params1.wishYmd == null) {
        return this.$message({
          type: "warning",
          message: this.lang("메세지.희망수령일필수입력"),
        });
      }

      data.params1.requestCount = count;

      // 희망수령일 fomatter
      if (data.params1.wishYmd) {
        data.params1.wishYmd = moment(data.params1.wishYmd).format("YYYYMMDD");
      }

      // 시작일, 종료일 있는 경우 fomatter
      if (data.params1.startYmd && data.params1.endYmd) {
        data.params1.startYmd = moment(data.params1.startYmd).format(
          "YYYYMMDD",
        );
        data.params1.endYmd = moment(data.params1.endYmd).format("YYYYMMDD");
      }

      data.common = {
        ...data.common,
        ...{
          companyOriginalId: this.companyOriginalId,
          requestCompanyOriginalId: this.companyOriginalId,
          employeeId: this.employeeId,
        }, // 필수 데이터 추가
      };

      // 통합 데이터
      const params = {
        ...data.params1,
        ...data.params2,
        ...data.common,
        ...data.orgData,
        manipulationType,
      };

      // 값에 앞뒤 공백이 있는지 체크
      for (let i in params) {
        if (typeof params[i] === "string") {
          params[i] = params[i].trim();

          // 앞 뒤공백을 제거한 데이터가 ''일 경우 null로 치환
          if (!params[i]) {
            params[i] = null;
          }
        }
      }
      this.pending = true;
      try {
        if (this.validationBeforeSave(params, manipulationType)) {
          const response = await helpers.put(
            `/proof/apply/basic`,
            [params], // 데이터 한건 put할때는 []
          );

          if (response.data.payload) {
            this.$message({
              type: "success",
              message: this.lang("msg.saveSuccess"),
            });
          }

          this.pending = false;
          this.$emit("save");
        }
      } finally {
        this.pending = false;
      }
    },
    changeValue(value, type, data) {
      // 증명종류일 경우
      if (type === "certificateType") {
        // 증명종류를 변경했을 경우 초기화
        this.clearData(data);

        data.params1.certificateType = value;
        data.params1.certificateTypeName = this.certificateTypeList.filter(
          (el) => el.lookupValueId === value,
        )[0].meaning;
        data.params1.receiveType = null;
        /*
        const code = this.certificateTypeList.filter(el => el.lookupValueId === value)[0].lookupCode;
        const codeList = this.employmentCertificateList.map(list => list.lookupCode);

        // '주관부서수령'만 나오는 경우 code => false
        this.codeStatus = codeList.includes(code);
        */
      } else if (type === "receiveType") {
        // 수령방법일 경우
        data.params1.receiveType = value;
        data.params1.receiveTypeName = this.defaultReceiveTypeList.filter(
          (el) => el.lookupValueId === value,
        )[0].meaning;
        // 직접출력, 주관부서출력 각각 희망수령일 default값 세팅
        if (
          value ===
          this.defaultReceiveTypeList.filter((el) => el.lookupCode === "10")[0]
            .lookupValueId
        ) {
          data.params1.wishYmd = moment().format("YYYY-MM-DD");
        } else {
          data.params1.wishYmd = null;
        }
      } else if (type === "registrationNumberMarkType") {
        // 주민번호표기일 경우
        data.params1.registrationNumberMarkType = value;
        data.params1.registrationNumberMarkTypeName =
          this.registrationNumberMarkTypeList.filter(
            (el) => el.lookupValueId === value,
          )[0].meaning;
      } else if (type === "sealPrintType") {
        // 직인출력일 경우
        data.params1.sealPrintType = value;
        data.params1.sealPrintTypeName = this.sealPrintTypeList.filter(
          (el) => el.lookupValueId === value,
        )[0].meaning;
      } else if (type === "requestType") {
        // 신청구분
        const meaning = this.requestTypeList.filter(
          (el) => el.lookupValueId === value,
        )[0].meaning;

        data.params1 = {
          ...data.params1,
          certificateType: null,
          requestType: value,
          requestTypeName: meaning,
          requestOrganizationId: this.organizationId,
          requestPositionId: this.dutyCode,
        };
        this.setCertificateTypeListDisabled();
      }
    },
    validationBeforeSave(data, type) {
      let integratedRules = [];

      const rules = {
        /* 필수 */
        // employeeNumber: {
        //   msg: this.lang("그리드.대상자"),
        //   needValidation: true,
        // },
        // employeeName: { msg: this.lang("그리드.대상자"), needValidation: true },
        // certificateType: {
        //   msg: this.lang("그리드.증명종류"),
        //   needValidation: true,
        // },
        // requestPurpose: {
        //   msg: this.lang("그리드.신청용도"),
        //   needValidation: true,
        // },
        // receiveType: {
        //   msg: this.lang("그리드.수령방법"),
        //   needValidation: true,
        // },
        // registrationNumberMarkType: { msg: this.lang('그리드.주민번호표기'), needValidation: true },
        // wishYmd: { msg: this.lang("그리드.희망수령일"), needValidation: true },
        requestCount: {
          msg: this.lang("그리드.신청부수"),
          needValidation: true,
        },
        // sealPrintType: { msg: this.lang('그리드.직인출력'), needValidation: true },
        /* 조건 필수 */
        note: {
          msg: this.lang("그리드.비고"),
          needValidation:
            this.checkPurposeRequired || this.checkCertificateCareerRequired,
        },
        startYmd: {
          msg: this.lang("그리드.기간"),
          needValidation:
            this.checkMainTaskRequired ||
            this.checkDismissalRequired ||
            this.checkBusinessTrip,
        },
        endYmd: {
          msg: this.lang("그리드.기간"),
          needValidation:
            this.checkMainTaskRequired ||
            this.checkDismissalRequired ||
            this.checkBusinessTrip,
        },
      };
      const common = {
        // submitPlace: { msg: this.lang("그리드.제출처"), needValidation: true },
        businessTripPlace: {
          msg: this.lang("그리드.출장지역"),
          needValidation: this.checkBusinessTrip,
        },
        businessTripPurpose: {
          msg: this.lang("그리드.출장목적"),
          needValidation: this.checkBusinessTrip,
        },
        licenseDescription: {
          msg: this.lang("그리드.자격"),
          needValidation: this.checkDismissalRequired,
        },
        unperformedArise: {
          msg: this.lang("그리드.미수행시문제점"),
          needValidation: this.checkMainTaskRequired,
        },
      };
      const printRules = {
        printEmployeeName: {
          msg: this.lang("그리드.대상자명"),
          needValidation: true,
        },
        //  printRegistrationNumber: { msg: this.lang('그리드.주민번호'), needValidation: true },
        printDutyName: { msg: this.lang("그리드.직책"), needValidation: true },
        printOrganizationName: {
          msg: this.lang("그리드.소속"),
          needValidation: true,
        },
        printRequestPurpose: {
          msg: this.lang("그리드.신청용도"),
          needValidation: true,
        },
      };
      /*
      if (this.checkCareerRequired || this.checkCertificateCareerRequired) {
        common.printDescription = {
          msg: this.lang('그리드.경력'),
          needValidation: true
        };
      }
      */
      if (this.checkMainTaskRequired) {
        common.printDescription = {
          msg: this.lang("그리드.업무내용"),
          needValidation: true,
        };
      }

      if (this.checkDismissalRequired) {
        common.printDescription = {
          msg: this.lang("그리드.용역내역"),
          needValidation: true,
        };
      }

      if (type === "U") {
        // 업데이트시 validation
        integratedRules = { ...rules, ...common, ...printRules };
      } else {
        // 추가시 validation
        integratedRules = { ...rules, ...common };
      }

      const originDataList = Object.entries(data);
      const rulesList = Object.entries(integratedRules);
      const checkStatus = [];

      for (let i = 0; i < originDataList.length; i++) {
        for (let j = 0; j < rulesList.length; j++) {
          if (originDataList[i][0] === rulesList[j][0]) {
            if (!originDataList[i][1] && rulesList[j][1].needValidation) {
              checkStatus.push(false);

              this.$message({
                message: this.lang(`메세지.필수값체크`, "name", {
                  name: rulesList[j][1].msg,
                }),
                type: "warning",
              });
            } else {
              checkStatus.push(true);
            }
          }
        }
      }

      return !checkStatus.includes(false);
    },
    clearData(data) {
      // 공통으로 쓰이는 것들 중 submitPlace 를 제외한 데이터만 초기화
      for (let i in data.common) {
        if (data.common[i] !== data.common.submitPlace) {
          data.common[i] = null;
        }
      }

      data.params1.startYmd = null;
      data.params1.endYmd = null;
      data.params1.note = null;
    },
    checkReg(val) {
      let value = val.target.value;
      this.searchParams.params1.requestCount = validator.replacePattern(
        value,
        "numNot",
      );
    },
    async setRequestTypeListDisabled(employeeId) {
      try {
        const {
          data: { payload: hasAssignmentHistory },
        } = await helpers.get(`assignment/hasGroupTransfer/${employeeId}`);

        this.requestTypeList.forEach((e, i) => {
          this.$set(
            this.requestTypeList[i],
            "disabled",
            e.lookupCode === "2" ? !hasAssignmentHistory : false,
          );
        });
      } catch {
        /* continue regardless of error */
      }
    },
    setCertificateTypeListDisabled() {
      const requestType = this.searchParams.params1.requestType;
      const isDisabled = requestType !== this.defaultRequestType;

      this.certificateTypeList.map((e) => {
        if (["101", "102"].includes(e.lookupCode)) {
          e.disabled = isDisabled;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.person-issued {
  padding: 16px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.el-dialog__wrapper-big {
  .save-btn-warrap {
    padding-top: 16px;
    text-align: right;
  }
}
</style>
