import moment from "moment";
import rule from "../config/rule";

const { DATE_FORMAT } = rule;

export const nowDate = () => {
  let date = new Date();
  if (!date || !moment(date).isValid()) return null;
  if (!moment.isMoment(date)) date = moment(date);

  return date.format(DATE_FORMAT["longDateValue"]);
};

export const nowMonth = () => {
  let date = new Date();
  if (!date || !moment(date).isValid()) return null;
  if (!moment.isMoment(date)) date = moment(date);

  return date.format(DATE_FORMAT["longMonthValue"]);
};

export const nowYear = () => {
  let date = new Date();
  if (!date || !moment(date).isValid()) return null;
  if (!moment.isMoment(date)) date = moment(date);

  return date.format("YYYY");
};

export const nowYearFirstDate = () => {
  let date = new Date(new Date().getFullYear() + "/01/01");
  if (!date || !moment(date).isValid()) return null;
  if (!moment.isMoment(date)) date = moment(date);

  return date.format(DATE_FORMAT["longDateValue"]);
};

export const nowYearLastDate = () => {
  let date = new Date(new Date().getFullYear() + "/12/31");
  if (!date || !moment(date).isValid()) return null;
  if (!moment.isMoment(date)) date = moment(date);

  return date.format(DATE_FORMAT["longDateValue"]);
};

export const dateDiff = (from, to) => {
  let fromDate = new Date(
    from.substr(0, 4) + "/" + from.substr(4, 2) + "/" + from.substr(6, 2),
  );
  if (!fromDate || !moment(fromDate).isValid()) return null;
  if (!moment.isMoment(fromDate)) fromDate = moment(fromDate);

  let toDate = new Date(
    to.substr(0, 4) + "/" + to.substr(4, 2) + "/" + to.substr(6, 2),
  );
  if (!toDate || !moment(toDate).isValid()) return null;
  if (!moment.isMoment(toDate)) toDate = moment(toDate);

  const dateDiff = fromDate - toDate;

  return dateDiff / (24 * 60 * 60 * 1000);
};

export const dateAdd = (num) => {
  let date = new Date();
  date.setDate(date.getDate() + num);

  if (!date || !moment(date).isValid()) return null;
  if (!moment.isMoment(date)) date = moment(date);

  return date.format(DATE_FORMAT["longDateValue"]);
};

export default {
  nowDate,
  nowMonth,
  nowYear,
  nowYearFirstDate,
  nowYearLastDate,
  dateAdd,
  dateDiff,
};
