<template>
  <article
    :class="[
      {
        'portlet--has-header': addHeader,
      },
    ]"
    class="portlet"
  >
    <div v-if="addHeader" class="portlet-header">
      <div class="title__slot">
        <slot name="title" />
        <div v-show="collapsed" class="subtitle__slot">
          <slot name="subtitle" />
        </div>
      </div>
      <div class="count__slot">
        <slot name="totalCount" />
      </div>
      <div v-show="collapsed" class="button__slot">
        <slot name="button" />
      </div>
    </div>

    <div
      v-show="collapsed"
      :class="innerPadding ? 'portlet-body' : 'portlet-body-table'"
    >
      <slot />
    </div>

    <div v-if="addFooter" class="portlet-footer">
      <slot name="footer" />
    </div>
  </article>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Panel",
  props: {
    addHeader: {
      type: Boolean,
      default: true,
    },
    addFooter: {
      type: Boolean,
      default: false,
    },
    toggleBtn: {
      type: Boolean,
      default: false,
    },
    defaultCollapsed: {
      type: Boolean,
      default: true,
    },
    innerPadding: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      collapsed: this.defaultCollapsed,
    };
  },
  computed: {
    style() {
      return {
        padding: "0",
      };
    },
  },
  methods: {
    changeCollapsed(value) {
      this.collapsed = value;
    },
  },
};
</script>

<style lang="scss" scoped>
.portlet {
  position: relative;

  &:not(.portlet[style*="display: none"]) + & {
    margin-top: $gpro-padding-base;
  }

  &.portlet--has-header {
    > .portlet-body {
      @include card-layout;
      border-top: 0;
      border-radius: 0 0 $--border-radius-base $--border-radius-base;

      form.el-form--label-right {
        padding-right: 10px;
      }
    }
    > .portlet-body-table {
      border-radius: 0 0 $--border-radius-base $--border-radius-base;
      border-top: none;
    }
  }

  &-header {
    display: flex;
    height: 55px;
    padding: 16px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 1px solid $--border-color-base;
    min-width: 0;
    background: $--color-white;
    border-radius: $--border-radius-base $--border-radius-base 0 0;

    .title__slot {
      flex: 1 0 0;

      h2 {
        margin: 0;
        font-size: $--font-size-medium;
        font-weight: $gpro-font-weight-500;
        color: $--color-text-primary;
        display: inline-block;
        vertical-align: middle;
      }
    }

    .subtitle__slot {
      display: inline-block;
      vertical-align: middle;

      p {
        margin-left: 10px;
        color: $gpro-warm-gray-600;
        -webkit-text-fill-color: $gpro-warm-gray-600;
        font-size: $--font-size-extra-small;
        font-weight: $gpro-font-weight-400;
      }
    }

    .count__slot {
      color: $--color-text-primary;
      font-weight: $gpro-font-weight-500;
      margin: 0 16px 0 8px;
    }

    .button__slot {
      margin-right: 0;

      .el-checkbox {
        margin-left: 12px;
      }
    }

    > .portlet-body-table {
      border-radius: 0 0 $--border-radius-base $--border-radius-base;
    }
  }

  &-body {
    background-color: $--color-white;
    padding: $gpro-padding-base;
    border-radius: $gpro-border-radius-base;
    @include card-layout;

    .template-aside {
      min-height: 100%;
      border: 0;
    }
  }

  .portlet-body-table {
    @include card-layout;
    background-color: $--color-white;
    border-radius: $--border-radius-base;
    overflow: hidden;

    .template-aside {
      min-height: 100%;
      border: 0;
    }
  }

  &-filter {
    .portlet-body {
      border-radius: $gpro-border-radius-base;
    }
  }

  &.tree {
    .portlet-body {
      border-radius: 0;
    }
  }
}

.portlet-first {
  .portlet-body {
    border: $gpro-border-base;
    background-color: $--background-color-base;
    border-radius: $gpro-border-radius-base;
    position: relative;
  }
}
</style>
