// // Testing
// export const DEBUG = false;
// export const HOST = 'http://10.99.20.231:8083';
// export const LOCALHOST = 'http://localhost:' + '8093'; // HR : 8093
// // Backend API endpoints
// export const VUE_APP_API_BASE_URL = HOST + '/api/v1/test';
// export const AUTH_URI = HOST + '/api/oauth/authorize';
// export const VUE_APP_REDIRECT_URI = LOCALHOST + '/login';
// export const REFRESH_TOKEN_URL = HOST + '/api/v1/session';
// export const USERINFO_URL = HOST + '/api/v1/session';

// this js file should be merge with /config (not @/config) and separate them into 2ways(prod,dev);

/**
 * Key for local storage.
 *
 * Set the key to use in local storage to hold persistant data. If logged in,
 * you can see this key by going to Chrome > dev tools > application tab,
 * then choosing "Local Storage" and "http://localhost:8093".
 *
 * @type {string}
 */

export const STORAGE_KEY = "aipers-vue-sample";
