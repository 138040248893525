<template>
  <el-dialog
    :modal-append-to-body="false"
    :title="title"
    :width="width"
    visible
    @close="$emit('close')"
  >
    <div class="employee-find template">
      <el-form
        :model="searchParams"
        class="search-form"
        @submit.native.prevent="handleSearch"
      >
        <el-row :gutter="8">
          <el-col :span="11">
            <el-form-item class="search-form__item" label-width="70px" required>
              <span slot="label">{{ lang("폼.기간") }}</span>
              <el-row :gutter="8">
                <el-col :span="11">
                  <el-date-picker
                    v-model="searchParams.applyYmdStart"
                    v-bind="datePicker"
                    :placeholder="lang('폼.placeholder.pleasePickDate')"
                  />
                </el-col>
                <el-col :span="2"
                  ><span class="daterange_bar">{{
                    lang("폼.기간물결")
                  }}</span></el-col
                >
                <el-col :span="11">
                  <el-date-picker
                    v-model="searchParams.applyYmdEnd"
                    v-bind="datePicker"
                    :placeholder="lang('폼.placeholder.pleasePickDate')"
                  />
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item
              class="search-form__item search-form__item--input"
              label-width="100px"
            >
              <span slot="label">{{ lang("폼.발령제목") }}</span>
              <el-input
                v-model="searchParams.documentTitle"
                size="small"
                :autofocus="true"
                :placeholder="lang('폼.placeholder.pleaseInputKeyword')"
                @keypress.enter.native="handleSearch"
              />
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <div class="button-group">
              <el-button
                v-if="permissionR"
                type="primary"
                size="small"
                @click="handleSearch"
                >{{ lang("버튼.조회") }}</el-button
              >
            </div>
          </el-col>
        </el-row>
      </el-form>

      <el-table-extend
        ref="table"
        :data.sync="gridData"
        :loading="pending"
        :empty-model="emptyModel"
        :show-top-bar="true"
        :remove-button-option="{ hidden: true, handler: fnRemoveCustom }"
        :editable="true"
        :show-pagination="false"
        :labels="columns"
        :admin-auth="searchParams.adminAuth"
        row-key="documentId"
        program-code="HAS1200"
        resizable
        highlight-current-row
        @save="handleSaveClick"
      >
        <el-table-column-extend
          v-for="column in columns"
          :key="column.label"
          :label="column.label"
          :type="column.type"
          :code="column.code"
          :align="column.align"
          :formatter="column.formatter"
          :date-format="column.dateFormat"
          :true-label="column.trueLabel"
          :false-label="column.falseLabel"
          :required="column.required"
          :validator="column.validator"
          :on-invalid="column.onInvalid"
          :width="column.width"
          :prop="
            column.displayProp !== undefined ? column.displayProp : column.prop
          "
          :use-template="column.useTemplate"
          :show-overflow-tooltip="column.showOverflowTooltip"
          header-align="left"
        >
          <template
            v-if="column.useTemplate"
            slot-scope="{ row, update, current }"
          >
            <template v-if="column.prop === 'documentId'">
              <el-checkbox @change="$emit('change', row)" />
            </template>
            <template v-if="column.prop === 'documentTitle'">
              <el-input
                v-if="current && row.assignmentProcessPopYn !== 'Y'"
                v-focus
                size="small"
                :value="row[column.prop]"
                :maxlength="column.maxLength"
                @change="(val) => update(val)"
              />
              <span v-else>
                {{ row[column.prop] }}
              </span>
            </template>
            <template v-if="column.prop === 'companyOriginalId'">
              <code-select
                :placeholder="column.label"
                :value="row[column.prop]"
                :disabled="true"
                code="ORG_COMPANY_CODE"
              />
            </template>
          </template>
        </el-table-column-extend>
      </el-table-extend>
    </div>
  </el-dialog>
</template>
<script>
import helpers from "@/auth/helpers";
import validator from "@/lib/validator";
import currentTabFetchMixin from "@/mixins/currentTabFetchMixin";
import { i18nTranslator } from "@/mixins/i18n";
import { mapListToFormat } from "@/lib/util";
import { datePicker, daterangePicker } from "@/lib/element-base-options";
import { authEventUtil } from "@/mixins/auth";
import { DATE_FORMAT } from "@/config/rule";
import masterModel from "@/services/model/HAS/HAS1200";
import CodeSelect from "@/components/form/CodeSelect";

import moment from "moment";
import date from "@/lib/date";
import { mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "HAS1200",
  components: { CodeSelect },
  mixins: [
    i18nTranslator("HAS1200"),
    authEventUtil("HAS1200"),
    currentTabFetchMixin,
  ],
  props: {
    title: {
      type: String,
      default() {
        return this.$tc("HAS1200.발령번호찾기");
      },
    },
    width: {
      type: String,
      default: "1000px",
    },
    // eslint-disable-next-line
    value: String,
    // eslint-disable-next-line
    adminAuth: String,
    // eslint-disable-next-line
    date: String,
  },
  data() {
    const lang = this.lang;
    return {
      isDeletionHeaderChanged: false,
      pending: false,
      gridData: [],
      selectedMasterRow: masterModel,
      searchParams: {
        applyYmdEnd: moment().format("YYYYMMDD"),
        applyYmdStart: moment().add(-1, "months").format("YYYYMMDD"),
        adminAuth: this.adminAuth,
        assignmentProcessYn: "",
        assignmentProcessPopYn: "",
        documentId: "",
        documentTitle: this.value,
        documentNo: "",
        companyOriginalId: this.companyOriginalId,
        organizationName: this.organizationName,
      },
      columns: [
        {
          label: lang("그리드.선택"),
          prop: "documentId",
          useTemplate: true,
          width: 60,
        },
        {
          label: lang("그리드.법인"),
          prop: "companyOriginalId",
          code: "ORG_COMPANY_CODE",
          required: true,
          useTemplate: true,
          validator: (row, column, cellValue) => validator.required(cellValue),
          onInvalid: () => {
            this.$message({
              message: lang(`msg.requirementInvalid`, "name", {
                name: lang("그리드.법인"),
              }),
              type: "warning",
            });
          },
        },
        {
          label: lang("그리드.발령번호"),
          prop: "documentNo",
          width: 150,
        },
        {
          label: lang("그리드.발령제목"),
          prop: "documentTitle",
          required: true,
          useTemplate: true,
          validator: (row, column, cellValue) => validator.required(cellValue),
          onInvalid: () => {
            this.$message({
              message: lang(`msg.requirementInvalid`, "name", {
                name: lang("그리드.발령제목"),
              }),
              type: "warning",
            });
          },
        },
        {
          label: lang("그리드.작성일"),
          prop: "applyYmd",
          width: 90,
          align: "center",
          dateFormat: DATE_FORMAT["longDate2"],
          formatter: (row) => {
            return this.$util.dateFormat(row.applyYmd);
          },
        },
        {
          label: lang("그리드.작성자"),
          prop: "employeeName",
          width: 90,
          align: "center",
        },
        {
          label: lang("그리드.확정여부"),
          prop: "assignmentProcessPopYn",
          width: 70,
          align: "center",
        },
      ],
      masterModel,
    };
  },
  computed: {
    ...mapGetters("user", { user: "getUserInfo" }),
    emptyModel() {
      const { userName, employeeNumber } =
        this.$store.getters["user/getUserInfo"];
      return Object.assign({}, masterModel, {
        companyOriginalId: this.companyOriginalId,
        roweditYn: "Y",
        applyYmd: date.nowDate(),
        employeeName: userName,
        employeeNumber,
      });
    },
    searchDate: {
      get() {
        return [this.searchParams.applyYmdStart, this.searchParams.applyYmdEnd];
      },
      set(newValue) {
        this.searchParams.applyYmdStart = newValue[0];
        this.searchParams.applyYmdEnd = newValue[1];
      },
    },
    DATE_FORMAT() {
      return DATE_FORMAT;
    },
    daterangePicker() {
      return daterangePicker;
    },
    datePicker() {
      return datePicker;
    },
    rules() {
      return {
        companyOriginalId: [
          {
            required: true,
            message: this.lang("msg.requirementInvalid", "name", {
              name: this.lang("폼.회사"),
            }),
          },
        ],
      };
    },
  },
  mounted() {
    let companyId = this.companyOriginalId;

    if (companyId == null || companyId === 0) {
      this.$store.commit(
        "common/updateLookupValueId",
        this.$store.getters["user/getUserInfo"].companyCode,
      );
    }

    this.fetch();
    window.vm = this;
    window.Vue = Vue;
  },
  methods: {
    async fetch() {
      const companyId = this.companyOriginalId;
      if (!companyId) {
        this.gridData = [];
        return;
      }
      if (!this.searchParams.applyYmdStart) {
        this.$message({
          type: "warning",
          message: this.lang("메세지.날짜선택"),
        });
        return;
      }
      if (!this.searchParams.applyYmdEnd) {
        this.$message({
          type: "warning",
          message: this.lang("메세지.날짜선택"),
        });
        return;
      }
      this.pending = true;

      let params = {
        companyOriginalId: companyId,
        applyYmdStart: this.searchParams.applyYmdStart,
        applyYmdEnd: this.searchParams.applyYmdEnd,
        documentTitle: this.searchParams.documentTitle,
        documentNo: this.searchParams.documentNo,
        documentId: this.searchParams.documentId,
        adminAuth: this.searchParams.adminAuth,
      };
      if (this.companyOriginalId !== 0) {
        params.companyOriginalId = this.companyOriginalId;
      }
      // delete params.count;
      await helpers
        .get(`/assdocument/document`, params)
        .then((res) => {
          this.gridData = mapListToFormat(res.data.payload, [
            "applyYmdEnd",
            "applyYmdStart",
            "applyYmd",
          ]);
          this.pending = false;
        })
        .catch(() => {
          this.pending = false;
        });

      for (let i in this.gridData) {
        if (this.gridData[i].assignmentProcessYn === "Y") {
          this.gridData[i].checkboxDelete = true;
          this.gridData[i].deleteDisabled = true;
        }
      }
      this.fnRemoveCustom();
    },

    getCompanyId() {
      let companyId = this.companyOriginalId;

      if (companyId === 0) {
        companyId = this.$store.getters["user/getUserInfo"].companyCode;
      }

      return companyId;
    },
    async handleSaveClick(data) {
      if (data.length < 1 || this.pending) {
        return;
      }
      const response = await helpers.put(`/assdocument/document`, data);
      if (response.data.payload) {
        this.$message({
          type: "success",
          message: this.lang("msg.saveSuccess"),
        });

        this.isDeletionHeaderChanged = false;
        this.fetch(this.getCompanyId);
      }
    },
    async handleSearch() {
      this.fetch();
    },
    handlePageChange(paginationObj) {
      Object.assign(this.pagination, paginationObj);
      this.fetch();
    },
    fnRemoveCustom() {
      const th = this.$refs.table.$el.querySelector(
        "thead .el-table-column--deletion",
      );
      if (th) {
        th.querySelector(".el-checkbox__input").classList.remove("is-checked");
        th.querySelector(".el-checkbox__input").classList.remove(
          "is-indeterminate",
        );
        th.querySelector("[type=checkbox]").setAttribute("checked", false);
      }
      this.isDeletionHeaderChanged = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.employee-find {
  padding: 16px;
  height: 100%;
  display: flex;
  justify-content: stretch;
  flex-direction: column;

  .search-form {
    align-items: center;
    margin-bottom: 20px;
    flex: 0 1 auto;

    &__item {
      margin-bottom: 0;

      &--checkbox {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: 120px;
        padding-left: 6px;
        margin-right: 18px;
      }

      &--input {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;

        .button-group {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 0;
        }
      }
    }
  }
  .el-table {
    flex: 1 1 auto;
    margin-top: 0;
  }
}
.daterange_bar {
  font-size: $--font-size-small;
  line-height: 24px;
  display: block;
  text-align: center;
}
</style>
