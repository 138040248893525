import { apiBaseUrl } from "@/config/cloudEnv";
import Vue from "vue";

export default {
  namespaced: true,

  state: {
    visible: false,
    // allMenus data 를 받아오기 전까지는 false
    ready: false,
    // 통합검색 통신 상태
    fetching: false,
    // 글로벌 로딩 상태
    loading: false,
    search: "",
    selectedNode: undefined,
    filtered: {
      count: 0,
      items: new Map(),
      groups: new Set(),
    },
    // 통합 검색을 통해 받아온 데이터
    asyncData: {
      count: 0,
    },
    // 앱 초기화 때 갱신하는 전체 메뉴
    allMenus: [],
    // focus trap 순환 객체
    focusableElements: [],
  },

  getters: {
    isSearching(state) {
      return !!state.search;
    },
  },

  mutations: {
    setFocusableElements(state, elements) {
      state.focusableElements = elements;
    },
    setReady(state, boolean) {
      state.ready = boolean;
    },
    setAllMenus(state, allMenus) {
      state.allMenus = allMenus;
    },
    setFetching(state, boolean) {
      state.fetching = boolean;
    },
    setLoading(state, boolean) {
      state.loading = boolean;
    },
    setVisible(state, visible) {
      state.visible = visible;
    },
    setSelectedNode(state, node) {
      state.selectedNode = node;
    },
    setSearch(state, search) {
      state.search = search;
    },
    setFiltered(state, filtered) {
      state.filtered = filtered;
    },
    setAsyncData(state, asyncData) {
      state.asyncData = asyncData;
    },
  },

  actions: {
    async fetchAllMenus({ commit, dispatch }) {
      try {
        const {
          data: { payload },
        } = await Vue.auth.get(`/common/menus/all`);
        commit("setAllMenus", payload);
        dispatch("favoriteMenus/updateAllMenus", payload, { root: true });
      } catch {
        commit("setAllMenus", []);
        dispatch("favoriteMenus/updateAllMenus", [], { root: true });
      } finally {
        commit("setReady", true);
      }
    },
    async fetchUnifiedSearch({ commit }, query) {
      try {
        commit("setFetching", true);
        const {
          data: { payload },
        } = await Vue.auth.get(
          `${apiBaseUrl()}/es/unified/search?searchText=${encodeURIComponent(
            query,
          )}`,
        );
        commit("setFetching", false);
        return payload;
      } catch {
        return false;
      }
    },
    reset({ commit }) {
      commit("setSearch", "");
      commit("setSelectedNode", undefined);
      commit("setAsyncData", {
        count: 0,
      });
      commit("setFiltered", {
        count: 0,
        items: new Map(),
        groups: new Set(),
      });
    },
  },
};
