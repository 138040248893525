<template>
  <div id="app" :class="{ 'top-banner-visible': bannerIsVisible }">
    <command-k />

    <top-banner
      v-if="bannerIsVisible"
      :cloud-info="cloudInfo"
      @close="closeBanner"
    />

    <div class="app__header">
      <app-header :cloud-ready="cloudReady" />
    </div>

    <div class="app__body">
      <div class="app__container">
        <router-view />
      </div>
    </div>
    <!-- 노무수령통지서 팝업 -->
    <el-dialog
      class="no-padding"
      :close-on-press-escape="false"
      :show-close="false"
      :visible="isNoticeSendPopup"
      width="650px"
      :title="$tc('노무수령거부.타이틀')"
    >
      <notice-send
        v-if="isNoticeSendPopup"
        @close="changeNoticeY()"
      ></notice-send>
    </el-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { CommonAPI } from "@/services/api";

import AppHeader from "@/components/layouts/app-header";
import TopBanner from "@/components/banner/topBanner";
import CommandK from "@/components/command-k";

import appVersionPinter from "@/lib/appVersionPinter";
import noticeSend from "@/views/main/popup/notice-send.vue";

import helpers from "@/auth/helpers";

export default {
  name: "App",

  components: {
    CommandK,
    AppHeader,
    TopBanner,
    noticeSend,
  },

  data() {
    return {
      cloudReady: false,
      closed: false,
      foreLeaveAnnualDetailId: null,
      isNoticeSendPopup: false,
    };
  },

  computed: {
    ...mapState("user", {
      userInfo: (state) => state.userInfo,
    }),
    ...mapState("common", {
      cloudInfo: (state) => state.cloudInfo,
    }),
    bannerIsVisible() {
      return this.topBannerFlag && !this.closed;
    },
    topBannerFlag() {
      return this.cloudInfo.isFreeTrial === true;
    },
  },

  created() {
    this.onCloudApiLoad();
  },

  mounted() {
    // 노무 수령 여부 확인
    this.noticeSend();
    appVersionPinter();
  },

  methods: {
    closeBanner() {
      this.closed = true;
    },
    async onCloudApiLoad() {
      const [
        {
          data: { payload },
        },
        {
          data: { payload: logoList },
        },
      ] = await Promise.all([
        CommonAPI.getCloudInfo(),
        helpers.get("/cloud/logo"),
      ]);
      const { blockedUrl, isBlocked } = payload;

      if (isBlocked === true && blockedUrl) {
        window.location.href = blockedUrl;
        return;
      }

      this.$store.commit("common/updateCloudInfo", payload);
      this.$store.commit("common/updateCloudLogo", logoList);
      this.$nextTick(() => {
        this.cloudReady = true;
      });
    },
    async noticeSend() {
      const res = await helpers.get(
        "/employeeinfo/leave/annual/fore/detail/overlap",
      );
      const payload = res.data.payload;
      // 통지 대상일 경우

      if (payload && payload.length > 0) {
        if (payload[0].noticeYn === "N") {
          this.isNoticeSendPopup = true;
        }
      }
    },
    async changeNoticeY() {
      const res = await helpers.put("/employeeinfo/leave/annual/fore/detail");
      const payload = res.data.payload;
      //       {
      //     "success": true,
      //     "payload": {
      //         "deleteCnt": 0,
      //         "updateCnt": 1,
      //         "insertCnt": 0
      //     }
      // }

      if (payload.updateCnt > 0) {
        this.$message({
          type: "success",
          message: this.$t("노무수령거부.메시지.노무수령통지"),
        });
      }
      this.isNoticeSendPopup = false;
    },
  },
};
</script>

<style lang="scss" scoped>
$gpro-header-z-index: 2000;

#app {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.top-banner-visible {
    .app__header {
      top: $gpro-top-banner-height;
      .gnb-menu-wrapper {
        top: $gpro-header-height + $gpro-top-banner-height;
      }
    }

    .app__container {
      height: calc(100% - #{$gpro-header-height + $gpro-top-banner-height});
      //height: 100%;
      //padding-top: $gpro-header-height + $gpro-top-banner-height;
      margin-top: $gpro-header-height + $gpro-top-banner-height;
    }
  }
}

.app__header {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: $gpro-gnb-background-color;
  z-index: $gpro-header-z-index;
  @include bs-shadow("medium");

  &.top-banner-visible {
    top: $gpro-top-banner-height;
  }
}

.app__body {
  height: 100%;
  width: 100%;
  overflow: hidden;

  .app__container {
    display: flex;
    width: 100%;

    height: calc(100% - #{$gpro-header-height});
    margin-top: $gpro-header-height;

    overflow: hidden;
  }
}
</style>
