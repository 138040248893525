import axios from "axios";
import router from "./router";

export const http = {
  install(Vue) {
    Vue.prototype.$http = Vue.http = axios.create();
  },
};

export { router };
