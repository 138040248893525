<template>
  <el-dialog
    ref="dialog1"
    :modal-append-to-body="false"
    :title="title"
    :width="width"
    visible
    @close="$emit('close')"
  >
    <div class="employee-find modal-find">
      <panel :add-header="false" class="portlet-first">
        <el-form
          :model="searchParams"
          class="search-form"
          @submit.native.prevent="handleSearch"
        >
          <el-form-item class="search-form__item search-form__item--input">
            <span slot="label">{{ lang("사번/성명") }}</span>
            <el-input
              ref="search"
              v-model="searchParams.inputText"
              v-focus
              size="small"
            />
          </el-form-item>

          <el-form-item class="search-form__item search-form__item--checkbox">
            <el-checkbox
              v-model="searchParams.inOffi"
              :label="lang('퇴직자_포함')"
              :false-label="null"
              true-label="N"
            />
          </el-form-item>

          <div class="button-group">
            <el-button
              v-if="permissionR"
              type="primary"
              size="small"
              @click="handleSearch"
              >{{ lang("버튼.조회") }}</el-button
            >
          </div>
        </el-form>
      </panel>
      <panel :add-header="false" :inner-padding="false">
        <el-table-extend
          :data.sync="gridData"
          :loading="pending"
          :max-height="getHeight"
          :editable="false"
          :show-pagination="false"
          :height="650"
          program-code="HPE1210"
          row-key="employeeId"
          resizable
          style="width: 100%"
          @page-change="handlePageChange"
        >
          <el-table-column-extend
            v-for="column in columns"
            :key="column.label"
            :label="column.label"
            :type="column.type"
            :formatter="column.formatter"
            :width="column.width ? column.width : 'auto'"
            :prop="column.prop"
            :use-template="column.useTemplate"
            :link-options="column.linkOptions"
            header-align="left"
          >
            <template v-if="column.prop === 'empName'" slot-scope="{ row }">
              <photo-icon
                v-if="row.employeeId"
                :src="userPhoto(row.employeeId)"
              />
              <anchor @click="$emit('change', row)">{{ row.empName }}</anchor>
            </template>
          </el-table-column-extend>
        </el-table-extend>
      </panel>
    </div>
  </el-dialog>
</template>

<script>
import helpers from "@/auth/helpers";
import { i18nTranslator } from "@/mixins/i18n";
import { authEventUtil } from "@/mixins/auth";
import { photoUrl } from "@/config/cloudEnv";
import Panel from "@/components/portlets/Panel";
export default {
  name: "HPE1210",
  components: {
    Panel,
  },
  mixins: [i18nTranslator("HPE1210"), authEventUtil("HPE1210")],
  props: {
    title: {
      type: String,
      default() {
        return this.$tc("HPE1210.사원찾기");
      },
    },
    width: {
      type: String,
      default: "1000px",
    },
    // eslint-disable-next-line
    value: String,
    // eslint-disable-next-line
    date: String,
    // eslint-disable-next-line
    adminAuth: String,
    // eslint-disable-next-line
    companyOriginalId: Number,
    // eslint-disable-next-line
    assYn: String,
    inOffiYn: {
      type: String,
      default: null,
    },
  },
  data() {
    const lang = this.lang;
    const self = this;
    return {
      previousElement: null,
      pending: false,
      getHeight: 0,
      pagination: {
        page: 1,
        count: 0,
        limit: 20,
      },
      gridData: [],
      searchParams: {
        inputText: this.value,
        adminAuth: this.adminAuth,
        assYn: this.assYn,
        inOffi: !this.inOffiYn ? null : this.inOffiYn, // N이면 퇴직자포함 조회
      },
      columns: [
        { label: lang("그리드.사번"), prop: "employeeNumber", width: 110 },
        { label: lang("그리드.성명"), prop: "empName", useTemplate: true },
        { label: lang("그리드.회사"), prop: "companyOriginalIdName" },
        {
          label: lang("그리드.부서"),
          prop: "organizationIdName",
          type: "link",
          linkOptions: ({ row }) => {
            const formatRetiredDate = self
              .$moment(row.retireYmd, "YYYYMMDD")
              .isValid()
              ? self.$moment(row.retireYmd, "YYYY-MM-DD").format("YYYYMMDD")
              : null;
            return {
              name: "HOG1000",
              params: {
                organizationId: row.organizationId,
                effectiveDate: formatRetiredDate,
              },
            };
          },
        },
        {
          label: lang("그리드.재직상태"),
          prop: "inOffiYn",
          formatter: (row) =>
            row.inOffiYn === "Y"
              ? lang("그리드.재직자")
              : lang("그리드.퇴직자"),
        },
        {
          label: lang("그리드.입사일"),
          prop: "hireYmd",
          formatter: (row) => this.$util.dateFormat(row.hireYmd, "longDate"),
        },
      ],
    };
  },
  created() {
    this.previousElement = document.activeElement;
  },

  destroyed() {
    this.previousElement && this.previousElement.focus();
  },

  mounted() {
    this.getHeight =
      this.$refs?.dialog1?.$el.querySelector(".el-dialog").offsetHeight - 180;
    if (!this.$util.isBlank(this.searchParams.inputText)) {
      this.searchParams.inputText = this.searchParams.inputText.trim();
      this.fetch();
    }
  },
  methods: {
    userPhoto(employeeId) {
      return photoUrl(employeeId);
    },
    async fetch() {
      try {
        this.pending = true;
        this.searchParams.page = this.pagination.page;
        this.searchParams.limit = this.pagination.limit;
        this.searchParams.companyOriginalId = this.companyOriginalId || "";

        const res = await helpers.get(`/employees`, this.searchParams);

        this.gridData = res.data.payload || [];
        this.pagination.count = res.data.pagination.count;
      } finally {
        this.pending = false;
      }
    },
    async handleSearch() {
      if (this.$util.isBlank(this.searchParams.inputText)) {
        this.$message({
          type: "warning",
          message: this.lang("메세지.badKeyword"), // 성명/사번을 입력하고 검색해야합니다.
        });
        return false;
      } else {
        this.searchParams.inputText = this.searchParams.inputText.trim();
      }
      this.pagination.page = 1;
      this.fetch();
    },
    handlePageChange(paginationObj) {
      Object.assign(this.pagination, paginationObj);
      this.fetch();
    },
  },
};
</script>

<style lang="scss" scoped>
$--tab-bar-height: 28px;
.employee-find {
  padding: 16px;

  .search-form {
    display: flex;
    align-items: center;
    flex: 0 1 auto;

    &__item {
      margin-bottom: 0;
      display: flex;
      justify-content: stretch;

      &--checkbox {
        flex-grow: 0;
        flex-shrink: 1;
        flex-basis: auto;
        padding-left: 6px;
        margin-right: 20px;
      }

      &--input {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;

        .button-group {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 0;
        }
      }
    }
  }
  .el-table {
    flex: 1 1 auto;
    margin-top: 0;
  }
}
</style>
