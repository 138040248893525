import { mapGetters } from "vuex";
import { dateFormat } from "@/lib/util";

/**
 * 이 믹스인은 pageHeader 컴포넌트에서 기준일과 법인ID가 변경되었을 때 각 탭별로 fetch 함수를 실행시킵니다.
 * 19-07-22 열린 프로그램들이 법인ID를 공유하지 않고 각자 개별적으로 법인ID를 소유하는 것으로 요구사항이 변경되었습니다.
 */
export default {
  data: function () {
    return {
      willFetch: true,
    };
  },
  computed: {
    ...mapGetters("user", { user: "getUserInfo" }),
    ...mapGetters("common", {
      store$searchParams: "searchParams",
      tabsCompanyCode: "tabsCompanyCode",
      tabsStandardYmd: "tabsStandardYmd",
    }),
    currentTab() {
      if (this.$route.meta) return this.$route.meta.code;
      return "";
    },
    effectiveDate: {
      get() {
        let tabsStandardYmd = this.tabsStandardYmd;
        if (tabsStandardYmd === undefined || tabsStandardYmd === null) {
          tabsStandardYmd = dateFormat(new Date(), "longDateValue");
        }
        return tabsStandardYmd;
      },
      set() {},
    },
    companyOriginalId: {
      get() {
        let tabsCompanyCode = this.tabsCompanyCode;
        if (tabsCompanyCode === undefined) {
          tabsCompanyCode = this.user.companyCode;
        }
        return tabsCompanyCode;
      },
      set() {},
    },
  },
  watch: {
    effectiveDate: {
      handler(val, oldVal) {
        if (oldVal !== undefined && val !== oldVal && this.isActiveTab()) {
          !this.disableEffectiveDateFetch &&
            this.$nextTick(async () => {
              await this.fetch();
            });
        }
      },
    },
    companyOriginalId: {
      handler(val, oldVal) {
        if (oldVal !== undefined && val !== oldVal && this.isActiveTab()) {
          !this.disableCompanyOriginalIdFetch &&
            this.$nextTick(async () => {
              await this.fetch();
            });
        }
      },
    },
  },
  methods: {
    isActiveTab() {
      // * 현재 탭만 업데이트 하도록 제한
      return this.currentTab === this.programId;
    },
  },
};
