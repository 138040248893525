<template>
  <!-- eslint-disable vue/no-v-html -->
  <i
    :class="[
      isHover ? 'hover' : '',
      isSelected ? 'selected' : '',
      isDisabled ? 'disabled' : '',
    ]"
    :style="{ transform: `rotate(${degree}deg)` }"
    class="base-svg"
    v-on="$listeners"
    @mouseover="mouseover"
    @mouseout="mouseout"
    v-html="require(`@/assets/svg/${name}.svg`)"
  />
</template>
<script>
/**
 * @deprecated
 * 정상 동작하지 않는 컴포넌트 입니다.
 * 아이콘이 필요한 경우 <iconify-icon> 를 사용하세요.
 */
export default {
  name: "BaseSvg",
  props: {
    name: {
      type: String,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    width: {
      type: [String, Number],
    },
    // eslint-disable-next-line vue/require-default-prop
    height: {
      type: [String, Number],
    },
    fill: {
      type: String,
      default: "transparent",
    },
    // eslint-disable-next-line vue/require-default-prop
    stroke: {
      type: String,
      // default: 'black'
    },
    selected: {
      type: Boolean,
      default: false,
    },
    static: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    degree: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      isHover: false,
    };
  },
  computed: {
    isSelected() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.isHover = false;
      return this.selected;
    },
    isDisabled() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.isHover = false;
      return this.disabled;
    },
  },
  watch: {
    stroke(newVal) {
      // 색 설정
      if (this.$el.querySelector("#icons")) {
        this.$el.querySelector(`#${this.name}`).style.fill = newVal;
      }
      if (this.$el.querySelector("#icons-reversed")) {
        this.$el.querySelector(`#${this.name}`).style.fill = newVal;
      }
    },
    fill(newVal) {
      if (this.$el.querySelector("#icons-reversed")) {
        this.$el.querySelector(`#${this.name}`).style.stroke = newVal;
      }
    },
  },
  mounted() {
    this.initIcon();
  },
  methods: {
    mouseover() {
      if (!this.isDisabled && !this.static && !this.isSelected) {
        this.isHover = true;
      }
    },
    mouseout() {
      if (!this.isDisabled && !this.static && !this.isSelected) {
        this.isHover = false;
      }
    },
    initIcon() {
      if (this.width) {
        this.$el.querySelector("svg").style.width = this.width.includes("px")
          ? this.width
          : this.width + "px";
      }
      if (this.height) {
        this.$el.querySelector("svg").style.height = this.height.includes("px")
          ? this.height
          : this.height + "px";
      }
      // 색 설정
      if (this.$el.querySelector("#icons")) {
        this.$el.querySelector(`#${this.name}`).style.fill = this.stroke;
      }

      if (this.$el.querySelector("#icons-reversed")) {
        this.$el.querySelector(`#${this.name}`).style.stroke = this.stroke;
        this.$el.querySelector(`#${this.name}`).style.fill = this.fill;
      }
    },
  },
};
</script>
<style lang="scss">
.base-svg {
  font-size: 0;
  display: inline-block;
  vertical-align: middle;
  transition: transform 0.1s ease;

  svg {
    vertical-align: middle;
  }

  &.disabled {
    #icons {
      fill: $gpro-warm-gray-300;
    }
  }

  &.selected {
    cursor: pointer;

    #icons {
      fill: $gpro-warm-gray-700;

      path {
        fill: child;
      }
    }
  }

  &.hover {
    cursor: pointer;

    #icon-arrow-down-thin {
      fill: $gpro-warm-gray-400;
    }
  }
}
</style>
