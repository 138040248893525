<template>
  <div class="command-k-item__inner">
    <div class="command-k-item__icon-wrapper">
      <iconify-icon class="command-k-item__icon" :icon="item.icon" />
    </div>

    <div class="comment-k-item__label comment-k-item__label--three-line">
      <div class="comment-k-item__text">
        {{ item.label }}
      </div>
      <div class="comment-k-item__text">
        {{ item.rawData.previewContentText }}
      </div>
      <div class="comment-k-item__text">
        <span>{{ item.rawData.regUserName }}</span
        ><span v-if="item.rawData.regUserOrganizationName"
          >・{{ item.rawData.regUserOrganizationName }}</span
        ><span>・{{ dateFormat(item.rawData.regDate, "longDate") }}</span>
      </div>
    </div>

    <iconify-icon
      class="command-k-item__icon"
      icon="material-symbols:chevron-right-rounded"
    />
  </div>
</template>

<script>
import { dateFormat } from "@/lib/util";

/**
 * cmdK 아이템 중 그룹웨어 타입.
 */
export default {
  name: "CommandKItemGroupware",

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  methods: {
    dateFormat,
  },
};
</script>
