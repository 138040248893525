import Vue from "vue";
import Router from "vue-router";
import routes from "./routes";
import store from "@/store";
import i18n from "@/i18n";
import Ga from "@/google-analytics";

// import RouterTab and styles
import RouterTab from "vue-router-tab";
import "vue-router-tab/dist/lib/vue-router-tab.css";

const user = store.state.user;
Vue.use(Router);
Vue.use(RouterTab);

const stripPathVariables = (path) => {
  return path.replace(/\/:[^/]+/g, "");
};

const autoFindValidPath = (to, next) => {
  const resolvedRoute = router.resolve({ path: to.fullPath });
  const matched = resolvedRoute.resolved.matched;
  if (matched.length === 0) {
    alert("권한이 없거나 존재하지 않는 페이지 입니다.");
  }

  if (
    matched.length === 0 ||
    (matched.length === 1 && matched[0].name === "home")
  ) {
    const newPath = stripPathVariables(
      routes.filter((route) => route.path === "/")[0].children[0].path,
    );
    next(newPath);
  } else {
    store.state.common.tab.currentTab = to.meta.code;
    store.dispatch("favoriteMenus/createFavoriteMenu", to).then();
    next();
  }
};

/**
 * Guard the route from unauthorized users.
 *
 * @param  {Route}    to   The route we want to access.
 * @param  {Route}    from The route from which we are coming from.
 * @param  {Function} next Callback for passing a route to be called next.
 * @return {void}
 */

/**
 * The Router instance containing all the routes for the application.
 */
const router = new Router({
  base: "/",
  mode: "history", // <-- uncomment to turn on history mode (preferred)
  routes: routes,
});
let nextPath;
// const registerTab = function(to) {
//   const programCode = to.params.code || null;
//   const regEx = /([a-zA-Z]{3,4})(\d{4})/g; // match 0 : full 1 : 3-4 matched letters 2 : 4digits
//   const path = regEx.exec(programCode)[1];
//   Vue.component(programCode, function(resolve) {
//     require(['@/pages/' + path + '/' + programCode + '.vue'], resolve)
//       .then(() => {})
//       .catch(err => {
//         if (err) {

//         }
//       });
//   });
// };

router.beforeEach((to, from, next) => {
  nextPath = to.fullPath;
  Ga.page(to.fullPath);

  if (user.userOn) {
    autoFindValidPath(to, next);
  } else {
    let waitingUserInfo = setInterval(() => {
      if (user.userOn) {
        i18n.locale = user.userInfo.language;
        store.state.settings.currentLocale = user.userInfo.language;
        clearInterval(waitingUserInfo);
        next(to);
      }
    }, 100);
  }
});

router.onError((error) => {
  if (error.name === "ChunkLoadError" && navigator.onLine) {
    window.location.href = nextPath || "/";
  }
});

export default router;
