import VueAnalytics, {
  page as gaPage,
  set as gaSet,
  event as gaEvent,
} from "vue-analytics";

import Vue from "vue";

// @todo ga id setting 추가
Vue.use(VueAnalytics, {
  id: "UA-159138861-1",
  autoTracking: {
    exception: false,
  },
  set: [{ field: "hostname", value: location.hostname }],
});

const page = function (...args) {
  gaPage(...args);
};

const set = function (...args) {
  gaSet(...args);
};
const event = function (...args) {
  gaEvent(...args);
};
const setUserId = function (userId) {
  set("userId", userId + "");
};

const Ga = {
  page,
  set,
  event,
  setUserId,
};

export default Ga;
