<template>
  <div
    v-show="isRender"
    :key="groupId"
    command-k-group=""
    class="command-k-group"
    role="presentation"
    :command-k-group-key="groupId"
  >
    <div
      v-if="heading"
      command-k-group-heading=""
      class="command-k-group__heading"
    >
      {{ heading }}
    </div>

    <div class="command-k-group__list" role="group">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "CommandKGroup",

  props: {
    heading: {
      type: String,
      default: "",
    },
    groupId: {
      type: String,
      default: "",
    },
    fixed: {
      type: Boolean,
    },
  },

  computed: {
    filtered() {
      return this.$store.state.commandK.filtered;
    },
    isRender() {
      return this.isSearching
        ? this.filtered.groups.has(this.groupId) || this.fixed
        : true;
    },
    isSearching() {
      return this.$store.getters["commandK/isSearching"];
    },
  },
};
</script>
