import FileSaver from "file-saver";
import _ from "lodash";
import helpers from "@/auth/helpers";
import moment from "moment";
import { CommonAPI } from "@/services/api";
import store from "@/store";
import { alertWrap } from "@/plugins/element-message-wrap";
import i18n from "@/i18n";
const XLSX = require("xlsx");

const propFilter = (acc, prop, displayProp, nameProp) => {
  if (displayProp || nameProp) {
    if (displayProp) {
      acc[2].push(displayProp);
    } else {
      acc[2].push(nameProp);
    }
  } else if (prop) {
    acc[2].push(prop);
  }
  return acc;
};

function convertToString(col, row) {
  const toColumnName = (num) => {
    let a = 1;
    let b = 26;
    let ret = "";
    for (ret; (num -= a) >= 0; a = b, b *= 26) {
      ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
    }
    return ret;
  };
  return `${toColumnName(col)}${row}`;
}

export function convert(columns, rows) {
  let merges = [];
  let subLabelsExist = false;
  let labels = [];
  let subLabels = [];
  let keys = [];
  const childPropMap = {};

  const columnHasChilds = columns.reduce((acc, col) => {
    if (col.childs && col.childs.length !== 0) {
      acc += 1;
    }
    return acc;
  }, 0);

  if (columnHasChilds !== 0) {
    const [a, b, c] = columns.reduce(
      (acc, element) => {
        const {
          label,
          prop,
          displayProp,
          nameProp,
          type,
          childs = [],
        } = element;
        if (
          (!prop && childs.length === 0) ||
          type === "deletion" ||
          prop === "checkboxSelect"
        ) {
          return acc;
        }

        if (childs.length !== 0) {
          subLabelsExist = true;
          for (let i = 0; i < childs.length; i++) {
            acc[0].push(i === 0 ? label : "");
            const { label: subLabel, prop, displayProp, nameProp } = childs[i];
            acc[1].push(subLabel);
            acc = propFilter(acc, prop, displayProp, nameProp);
          }
          const indexStartIdx = childPropMap?.[childs[0].label]
            ? childPropMap?.[childs[0].label]
            : 0;
          const firstChildIndex = acc[1].indexOf(
            childs[0].label,
            indexStartIdx,
          );
          childPropMap[childs[0].label] = firstChildIndex + 1;
          const merge = XLSX.utils.decode_range(
            `${convertToString(firstChildIndex + 1, 1)}:${convertToString(
              firstChildIndex + childs.length,
              1,
            )}`,
          );
          merges.push(merge);
        } else {
          acc[0].push(label);
          acc[1].push("");
          const colHasNoChildIndex = acc[0].indexOf(label);
          const merge = XLSX.utils.decode_range(
            `${convertToString(colHasNoChildIndex + 1, 1)}:${convertToString(
              colHasNoChildIndex + 1,
              2,
            )}`,
          );
          merges.push(merge);
        }
        propFilter(acc, prop, displayProp, nameProp);
        return acc;
      },
      [[], [], []],
    );
    labels = [...a];
    subLabels = [...b];
    keys = [...c];
  } else {
    const [a, c] = columns.reduce(
      (acc, element) => {
        const { label, prop, displayProp, nameProp, type } = element;
        if (!prop || type === "deletion" || prop === "checkboxSelect") {
          return acc;
        }
        acc[0].push(label);
        if (displayProp || nameProp) {
          if (displayProp) {
            acc[1].push(displayProp);
          } else {
            acc[1].push(nameProp);
          }
        } else if (prop) {
          acc[1].push(prop);
        }
        return acc;
      },
      [[], []],
    );
    labels = [...a];
    keys = [...c];
  }

  const isEmpty = (array) => array.join().replace(/,/g, "").length === 0;
  if (isEmpty(subLabels)) {
    merges = [];
  }
  const data = [labels];
  if (subLabelsExist === true) {
    data.push(subLabels);
  }
  const formatterMap = columns
    .reduce((acc, col) => {
      if (col.childs || Array.isArray(col.childs)) {
        acc = [...acc, ...col.childs];
      } else {
        acc.push(col);
      }
      return acc;
    }, [])
    .filter((col) => col.formatter)
    .reduce((acc, col) => acc.set(col.displayProp || col.prop, col), new Map());
  for (const row of rows) {
    const newRow = keys.map((key) => {
      let value = "";
      if (row[key] === 0) {
        value = row[key];
      } else {
        value = row[key] || "";
      }

      return formatterMap.has(key)
        ? formatterMap.get(key).formatter(row, null, value)
        : `${value}`;
    });
    data.push(newRow);
  }
  return { data, merges };
}

export function translateSeqToExcelColumn(num) {
  let index = num - 1;
  let letter = "";
  while (index >= 0) {
    letter = String.fromCharCode((index % 26) + 65) + letter;
    index = Math.floor(index / 26) - 1;
  }
  return letter;
}

export async function excelHourMinuteFormatter(ws, columns, rows, options) {
  const columnLocations = [];
  options["hourMinuteFormat"]["columns"].forEach((c) => {
    let idx = 1;
    columns.forEach((column) => {
      if (column.childs) {
        column.childs.forEach((child) => {
          if (child?.prop === c) {
            columnLocations.push(translateSeqToExcelColumn(idx));
          } else {
            idx++;
          }
        });
      } else if (column?.prop === c) {
        columnLocations.push(translateSeqToExcelColumn(idx));
      } else {
        idx++;
      }
    });
  });

  const hourMinuteFormat = /^([01][0-9]|2[0-3]):([0-5][0-9])$/;
  columnLocations.map((columnLocation) => {
    let rowNum = options["hourMinuteFormat"]["startRow"];
    rows.forEach(() => {
      const cellLocation = columnLocation + rowNum;
      if (hourMinuteFormat.test(ws?.[cellLocation]?.v)) {
        ws[cellLocation].f = `TIMEVALUE("${ws[cellLocation].v}")`;
        ws[cellLocation].t = "s";
        ws[cellLocation].z = "[h]:mm";
      }
      rowNum++;
    });
  });
}

export async function exportExcel(filename, columns, rows, options = {}) {
  if (!rows || !rows.length) {
    alertWrap(
      i18n.t("xlsxDownload.messages.데이터없음"),
      i18n.t("xlsxDownload.title"),
    );
    return;
  }
  const { data, merges } = convert(
    columns.filter((col) => !col.except),
    rows,
  );
  const ws = XLSX.utils.aoa_to_sheet(data);

  if (merges.length !== 0) {
    ws["!merges"] = merges;
  }

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, filename);

  if (
    options?.hourMinuteFormat?.columns &&
    options?.hourMinuteFormat?.startRow
  ) {
    await excelHourMinuteFormatter(ws, columns, rows, options);
  }

  if (process.env.VUE_APP_XLSX_PROTECTED) {
    let password = undefined;
    let reason = undefined;
    try {
      const obj = await new Promise((resolve, reject) => {
        store.commit("common/updatePasswordPromptOpen", true);
        store.commit("common/updatePasswordPromptResolveAndReject", {
          resolve,
          reject,
        });
      });
      console.log(obj);
      password = obj.password;
      reason = obj.reason;
    } catch (e) {
      console.log(e);
      return;
    }
    const data = XLSX.utils.sheet_to_json(ws, { header: 0 });

    const response = await helpers.post(
      "/xlsxDownloadWithReason",
      {
        data,
        filename,
        password,
        reason,
        merges,
      },
      {
        responseType: "blob",
      },
    );
    FileSaver.saveAs(
      new Blob([response.data], { type: "application/octet-stream" }),
      `${filename}.xlsx`,
    );
    return;
  }

  const vbout = XLSX.write(wb, {
    bookType: "xlsx",
    bookSST: true,
    type: "array",
  });
  try {
    FileSaver.saveAs(
      new Blob([vbout], { type: "application/octet-stream" }),
      `${filename}.xlsx`,
    );
  } catch (e) {
    console.log(e, vbout);
  }
  return vbout;
}

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    reader.readAsArrayBuffer(file);
  });
}

export async function fileToJson(file) {
  const result = await readFileAsync(file);
  const data = new Uint8Array(result);
  const workbook = XLSX.read(data, { type: "array" });
  const ws = workbook.Sheets[workbook.SheetNames[0]];
  return XLSX.utils.sheet_to_json(ws, { header: 1 });
}

export async function importExcel(file, orgColumns, validator = undefined) {
  let data = await fileToJson(file);

  // 행의 수를 넣어 준다.
  for (let i = 0; i < data.length; i++) {
    if (i !== 0 && data[i].length !== 0) {
      data[i].unshift(i + 1);
    }
  }
  const columns = _.cloneDeep(orgColumns);
  columns.unshift({ label: "행번호", prop: "rownum" });

  data = data.filter((dt) => {
    return dt.length !== 0;
  });
  if (validator) {
    validator(data, columns);
  }

  for (let i = 0; i < data.length; i++) {
    for (let j = 0; j < data[i].length; j++) {
      if (!data[i][j]) {
        data[i][j] = null;
      }
    }
  }

  const headers = data.shift();

  headers.unshift("행번호");

  const codeList = columns.reduce((acc, element) => {
    if (element.code) {
      acc.push(element.code);
    }
    return acc;
  }, []);

  const params = {
    effectiveDate: moment().format("YYYYMMDD"),
  };

  /* 사번관리 부서 처리를 위해 전체 조직 조회 */
  const {
    data: { payload },
  } = await helpers.get("/organization/history", params);

  const result = await Promise.all(
    codeList.map((code) =>
      CommonAPI.getCodes({
        lookupType: code,
      }),
    ),
  );
  const codes = result.reduce((acc, { data: { payload } }) => {
    acc[payload[0].lookupType] = payload;
    return acc;
  }, {});

  return data.map((row) => {
    return row.reduce((acc, element, index) => {
      if (element) {
        element = String(element);
      }
      const foundColumn = _.find(columns, (obj) => {
        return obj.label === headers[index];
      });
      if (foundColumn.displayProp) {
        if (foundColumn.code) {
          const filtered = (codes[foundColumn.code] || []).filter(
            ({ meaning }) => {
              return element === meaning;
            },
          );
          if (filtered.length !== 0) {
            acc[foundColumn.prop] = filtered[0].lookupValueId;
          }
          acc[foundColumn.displayProp] = element;
        } else {
          /* 사번관리 부서 처리를 위한 로직 추가 */
          if (foundColumn.displayProp === "organizationIdName") {
            const org = payload.filter(
              (organization) => organization.organizationName === element,
            );
            if (org && org.length > 0) {
              acc[foundColumn.displayProp] = org[0].organizationName;
              acc[foundColumn.prop] = org[0].organizationId;
              acc.companyOriginalId = org[0].companyOriginalId;
            }
          } else if (foundColumn.excelImportResultDateFormat) {
            acc[foundColumn.prop] = autoFormatExcelValueToDate(
              element,
              foundColumn.excelImportResultDateFormat,
            );
          } else {
            acc[foundColumn.prop] = element;
          }
        }
      } else {
        if (foundColumn.excelImportResultDateFormat) {
          acc[foundColumn.prop] = autoFormatExcelValueToDate(
            element,
            foundColumn.excelImportResultDateFormat,
          );
        } else {
          acc[foundColumn.prop] = element;
        }
      }
      acc.xlsx = true;
      acc.manipulationType = "U";
      return acc;
    }, {});
  });
}
/**
 * 엑셀 업로드시 사용자가 날짜입력과정에서 `2008-01-01` 과 같은 값이 자동으로 내부에선 `39448` 이란 숫자 값으로
 * 지정되거나, 20080101 을 그대로 입력시 숫자로 인식되어 입력되는 경우들을 사용자가 다시 셀형식을 텍스트로 바꾸
 * 어 입력하는 불편함없이 그대로 알아서 변환해주는 메소드.
 * @param value 엑셀로 부터 넘어온 날짜를 표현하는 임의의 값.
 * @param format 출력하는 날짜 형식
 * @returns {String}
 */
function autoFormatExcelValueToDate(value, format = "YYYY-MM-DD") {
  const showDate = format.indexOf("D") > -1;
  if (!value) {
    return value;
  }
  let date = null;
  if (typeof value === "string") {
    const itemSize = value.split("-").length;
    if (itemSize === 3) {
      date = moment(value, "YYYY-MM-DD");
    } else if (itemSize === 2) {
      if (showDate) {
        date = moment(value, "MM-DD");
      } else {
        date = moment(value, "YYYY-MM");
      }
    } else if (itemSize === 1) {
      if (value.length === 8) {
        date = moment(value, "YYYYMMDD");
      } else if (value.length === 6) {
        if (showDate) {
          date = moment(value, "YYMMDD");
        } else {
          date = moment(value, "YYYYMM");
        }
      } else if (value.length === 4) {
        if (showDate) {
          date = moment(value, "MMDD");
        } else {
          date = moment(value, "YYMM");
        }
      }
    }
  } else if (typeof value === "number") {
    const valueStr = value + "";
    if (valueStr.length === 8) {
      date = moment(valueStr, "YYYYMMDD");
    } else if (valueStr.length === 6) {
      if (showDate) {
        date = moment(valueStr, "YYMMDD");
      } else {
        date = moment(valueStr, "YYYYMM");
      }
    } else if (valueStr.length === 4) {
      if (showDate) {
        date = moment(valueStr, "MMDD");
      } else {
        date = moment(valueStr, "YYMM");
      }
    } else if (valueStr.length === 3) {
      if (showDate) {
        date = moment("0" + valueStr, "MMDD");
      }
    }
  }
  if (date && date.isValid()) {
    return date.format(format);
  }
  if (/[^0-9]/.test(value)) {
    return value;
  }
  date = moment(convertExcelDateValueToDateString(value * 1)).utcOffset(
    "+0000",
  );
  if (date && date.isValid()) {
    return date.format(format);
  }
  return value;
}

/**
 * 텍스트가 아닌 날짜형식으로 저장된 셀의 경우 `2008-01-01` 의 값이 `39448` 과 같은 숫자 값으로 읽어 들이게 된다.
 * 이 값은 1900-01-01 을 1일으로 시작하는 일수 값으로 엑셀에 존재하는 1900년 윤년 버그 문제를 고려하여
 * `2008-01-01T00:00:00Z` 과 같은 ISO 표준 일자및시간 표시형식으로 반환해주는 메소드.
 * @param value 숫자형태로 저장된 엑셀의 일자값.
 * @returns {string} IS날짜 데이터를 ISO 포맷으로 변환한 문자열
 */
function convertExcelDateValueToDateString(value) {
  // 엑셀에는 1900-02-29 일이 유효한 값으로 날짜가 계산되는 이슈가 있다.
  // https://docs.microsoft.com/en-us/office/troubleshoot/excel/wrongly-assumes-1900-is-leap-year
  if (value >= 60) {
    value--;
  }
  return new Date(
    new Date("1900-01-01T00:00:00Z").getTime() +
      (value - 1) * (24 * 60 * 60 * 1000),
  ).toISOString();
}
