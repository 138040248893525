<template>
  <el-dialog
    :modal-append-to-body="false"
    :before-close="handleBeforeClose"
    :title="lang(title)"
    :width="width"
    visible
    @close="$emit('close')"
  >
    <div
      v-loading="pending"
      class="attach-file"
      element-loading-background="transparent"
    >
      <div v-if="$slots.label" class="attach-file__head">
        <slot name="label" />
      </div>

      <div class="attach-file__file">
        <el-input
          :placeholder="lang('HPE1200.폼.placeholder.search')"
          :value="fileName"
          disabled
          size="small"
          class="value-input"
        >
          <template slot="suffix">
            <el-upload
              ref="upload"
              :auto-upload="false"
              :multiple="false"
              :show-file-list="false"
              :before-upload="handleBeforeUpload"
              :on-preview="handlePreview"
              :on-success="handleSuccess"
              :on-error="handleError"
              :on-exceed="handleExceed"
              :on-change="handleChange"
              :on-progress="handleProgress"
              :on-remove="
                (file, fileList) => $emit('on-remove', file, fileList)
              "
              :http-request="handleUpload"
              :action="uploadUrl"
              v-bind="bindUploadAttributes"
              name="uploadFile"
              list-type="text"
            >
              <el-button ref="searchButton" class="height"
                >{{ lang("HPE1200.버튼.찾아보기") }}
              </el-button>
            </el-upload>
          </template>
        </el-input>
      </div>

      <span class="attach-file__warning-tip">
        <template v-if="showTip">{{ tip }}</template>
        <span v-if="pending">{{ percentToFix }}</span>
      </span>

      <file-list-view
        v-if="showFileList"
        :group-id="groupId"
        :file-list="fileList"
        :before-remove="handleBeforeRemove"
        :removable="removable"
        downloadable
      />
      <div class="attach-file__button">
        <el-button :disabled="saveDisabled" size="small" @click="submitUpload"
          >{{ lang("HPE1200.버튼.첨부") }}
        </el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import helpers from "@/auth/helpers";
import { fileValidator } from "@/lib/validator";
import { i18nTranslator } from "@/mixins/i18n";
import { FILE as FILE_RULE } from "@/config/rule";
import { formatBytesToSize } from "@/lib/util";
import FileListView from "./FileListView";
import { apiBaseUrl } from "@/config/cloudEnv";

export default {
  name: "FileUpload",

  components: {
    FileListView,
  },

  mixins: [i18nTranslator(null)],

  props: {
    showFileList: {
      type: Boolean,
      default: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    visible: Boolean,
    showTip: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default() {
        return "HPE1200.파일첨부";
      },
    },
    width: {
      type: String,
      default: "600px",
    },
    attachGroupId: {
      type: Number,
      default: null,
    },
    allowMimeTypes: {
      type: Array,
      default() {
        return FILE_RULE.common.mimeTypes;
      },
    },
    allowExtensions: {
      type: Array,
      default() {
        return FILE_RULE.common.extensions;
      },
    },
    // 단위: KB
    maxSize: {
      type: Number,
      default: FILE_RULE.common.size,
    },
    limitWithRemote: {
      type: Number,
      default: 99999,
    },
    removable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      previousElement: null,
      saveFlag: false,
      uploadFile: null,
      pending: false,
      percent: -1,
      fileList: [],
      groupId: this.attachGroupId,
    };
  },

  computed: {
    percentToFix() {
      if (this.percent > -1) {
        return this.percent.toFixed(1) + "%";
      }
      return null;
    },
    bindUploadAttributes() {
      return {};
    },
    saveDisabled() {
      if (this.pending) return true;
      if (this.uploadFile) return false;
      return true;
    },
    fileName() {
      return this.uploadFile ? this.uploadFile.name : null;
    },
    tip() {
      return this.lang("msg.fileWarning1", null, {
        type: this.allowExtensions.join(", "),
        size: formatBytesToSize(this.maxSize),
      });
    },
    uploadUrl() {
      return `${apiBaseUrl()}/attachment/groups/${this.groupId}/files`;
    },
  },

  watch: {
    attachGroupId() {
      this.groupId = this.attachGroupId;
    },
  },

  created() {
    this.previousElement = document.activeElement;
  },

  destroyed() {
    this.previousElement && this.previousElement.focus();
  },

  mounted() {
    this.refreshFileList();
    this.$nextTick(() => {
      this.$refs.searchButton.$el.focus();
    });
  },

  methods: {
    handleBeforeClose(done) {
      // 업로드 중 닫기 불가능하도록
      if (!this.pending) {
        done();
      }
      const nodes = document.querySelector(".v-modal");
      nodes.remove();
    },
    submitUpload() {
      this.saveFlag = true;
      this.$refs.upload.submit();

      const nodes = document.querySelector(".v-modal");
      nodes.remove();
    },
    handleUpload(option) {
      const { file, filename } = option;
      const formData = new FormData();
      formData.append(filename, file, file.name);
      return helpers.post(this.uploadUrl, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
    },
    /**
     * @desc VALIDATION AND CREATE `ATTACH GROUP ID`
     * AttachGroupId 가 없는경우 생성
     */
    handleBeforeUpload(file) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const validate = fileValidator(file, {
          maxSize: this.maxSize,
          allowExtensions: this.allowExtensions,
          // allowMimeTypes: this.allowMimeTypes
        });
        if (!validate.valid) {
          this.$message({
            type: "warning",
            message: validate.errors[0],
          });
          this.uploadFile = null;
          // eslint-disable-next-line prefer-promise-reject-errors
          return reject();
        }

        if (!this.groupId) {
          const response = await helpers.post("/attachment/groups");
          const createdGroupId = response.data.payload.attachGroupId;
          if (createdGroupId) {
            this.groupId = createdGroupId;
            this.$emit("creationAttachGroupId", createdGroupId);
            this.pending = true;
            return resolve();
          } else {
            // eslint-disable-next-line prefer-promise-reject-errors
            return reject();
          }
        }
        this.pending = true;
        return resolve();
      });
    },
    handleBeforeRemove(file, fileList) {
      return new Promise(() => {
        const confirmOptions = {
          confirmButtonText: this.lang("HPE1200.버튼.삭제"),
          cancelButtonText: this.lang("HPE1200.버튼.취소"),
        };

        // else
        this.$confirm(
          this.lang("msg.fileRemoveConfirm", "name", {
            name: file.name,
          }), // 삭제하시겠습니까?
          this.lang("msg.noticeText"), // 알림
          confirmOptions,
        )
          .then(async () => {
            // eslint-disable-next-line no-unused-vars
            const response = await helpers.delete(
              `/attachment/groups/${this.groupId}/files/${file.attachValueId}`,
            );
            this.fileList.splice(
              this.fileList.findIndex(
                (_file) => _file.attachValueId === file.attachValueId,
              ),
              1,
            );
            this.$message({
              type: "success",
              message: this.lang("msg.removeSuccess"),
            });
            this.$emit("remove", file, fileList);
          })
          .catch(() => {});
      });
    },
    handleSuccess(response, file, fileList) {
      this.percent = -1;
      this.pending = false;
      this.fileList = this.mapFileList([file]);
      this.$emit("success", response, file, fileList);
    },
    handleError(err) {
      this.percent = -1;
      this.pending = false;
      console.error(err);
    },
    handleExceed() {
      // this.$message({type: 'warning', message: this.limit + '개를 초과!'});
    },
    handleChange(files, fileList) {
      fileList.splice(0, fileList.length, files);
      // check maximum file length with remote files
      if (
        this.saveFlag === false &&
        this.fileList.length + fileList.length > this.limitWithRemote
      ) {
        this.$message({
          type: "warning",
          message: this.lang("msg.fileWarning5", null, {
            total: this.fileList.length,
            current: this.fileList.length,
          }),
        });
        fileList.splice(0, fileList.length);
        this.uploadFile = null;
        this.saveFlag = false;
        return;
      }
      this.uploadFile = files;
    },
    handlePreview() {
      // window.location.href = process.env.VUE_APP_API_BASE_URL + f.url;
    },
    async refreshFileList() {
      if (this.groupId) {
        const response = await helpers.get(
          `/attachment/groups/${this.groupId}/files`,
        );
        const list = response.data.payload;
        this.fileList = this.mapFileList(list);
      }
    },
    mapFileList(files = []) {
      return files.map((f) => {
        return {
          name: f.originalFileName,
          url: apiBaseUrl() + f.url,
          attachValueId: f.attachValueId,
        };
      });
    },
    handleProgress(event) {
      this.percent = event.percent;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .height {
  height: 33px;
  line-height: 0;
}
</style>
