<template>
  <nav
    id="navbar"
    class="navbar none-text"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar__section navbar__section--logo">
      <a :href="toHub('/')" class="navbar-logo">
        <img
          v-if="currentLogo && cloudReady && $store.state.user.userOn"
          :src="currentLogo"
          class="navbar-logo__img"
          alt="Brand Logo"
        />
        <base-svg
          v-else-if="!currentLogo && cloudReady && $store.state.user.userOn"
          class="navbar-logo__img"
          name="gpro-logo"
        ></base-svg>
      </a>
    </div>

    <div class="navbar__section navbar__section--header">
      <div class="header-channel">
        <template v-for="channel in filteredChannelList">
          <router-link
            v-if="channel.id === 3"
            :key="channel.id"
            :to="channel.link"
            class="header-channel__title active"
            @click.native="changeChannel($event, channel)"
            >{{ channel.menuName }}
          </router-link>

          <a
            v-else
            :key="channel.id"
            :href="channel.link"
            class="header-channel__title"
            @click.stop.prevent="changeChannel($event, channel)"
            >{{ channel.menuName }}
          </a>
        </template>
      </div>

      <div class="header-controls">
        <div class="header-controls__item">
          <app-header-search />
        </div>

        <div class="header-controls__item">
          <a
            :href="toHub('/orgChart')"
            :title="$tc('app-header.조직도')"
            class="header-link"
          >
            <base-svg class="icon" name="icon-orgchart" />
          </a>
        </div>

        <div class="header-controls__item">
          <a
            :href="toHub('/userFind')"
            :title="$tc('app-header.구성원조회')"
            class="header-link"
          >
            <base-svg class="icon" name="icon-orgperson" />
          </a>
        </div>

        <div
          :class="{ active: dropdownVisible.notification }"
          class="header-controls__item notification"
        >
          <a
            :href="toHub('/community/notification')"
            class="header-link"
            :title="$tc('app-header.알림')"
          >
            <div v-if="unreadNotificationCount !== 0" class="new"></div>
            <base-svg class="icon" name="icon-alert" />
          </a>
        </div>

        <div class="header-controls__item">
          <a
            :href="toHub('/ess')"
            :title="$tc('app-header.마이페이지')"
            class="header-link"
          >
            <base-svg class="icon" name="icon-ess" />
          </a>
        </div>

        <div v-if="userInfo.adminFlag === '1'" class="header-controls__item">
          <a
            :class="{ active: $route.path.includes('/admin') }"
            :href="toHub('/admin/menu')"
            :title="$tc('app-header.시스템관리')"
            class="header-link"
          >
            <base-svg class="icon" name="icon-setting" />
          </a>
        </div>

        <div class="header-controls__item gnb dropdown">
          <app-header-dropdown
            :border="false"
            :dropped.sync="dropdownVisible.gnb"
            :class="{ active: dropdownVisible.gnb }"
            align="center"
            top="50px"
          >
            <base-svg slot="label" class="header-link icon" name="icon-menu" />
          </app-header-dropdown>
        </div>

        <div class="header-controls__item profile">
          <app-header-dropdown
            :dropped.sync="dropdownVisible.profile"
            :border="0"
            align="right"
            top="51px"
          >
            <div
              slot="label"
              :style="{
                'background-image': `url('${encodeURI(userPhotoSrc)}')`,
              }"
              class="profile-photo"
            />
            <app-header-profile class="header-controls__item__profile" />
          </app-header-dropdown>
        </div>
      </div>
    </div>
    <!-- dropdown -->
    <gnb-menu
      v-show="dropdownVisible.gnb"
      :menu-list="menuList"
      class="gnb-wrap"
    />
  </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import channelTalk from "@/plugins/channelTalk";
import { i18nTranslator } from "@/mixins/i18n";
import {
  apiBaseUrl,
  eaBaseHost,
  hubBaseHost,
  peBaseHost,
  payrollBaseHost,
} from "@/config/cloudEnv";

import AppHeaderDropdown from "@/components/app/AppHeaderDropdown";
import AppHeaderSearch from "@/components/app/AppHeaderSearch";
import AppHeaderProfile from "@/components/app/AppHeaderProfile";
import GnbMenu from "@/components/app/menu/GnbMenu";

import unknownImage from "@/assets/unknown.jpg";

export default {
  name: "AppHeader",
  components: {
    AppHeaderProfile,
    AppHeaderDropdown,
    AppHeaderSearch,
    GnbMenu,
    // eslint-disable-next-line vue/no-unused-components
    channelTalk,
  },
  mixins: [i18nTranslator("app-header")],
  props: {
    cloudReady: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      channelList: [
        {
          id: 1,
          menuName: this.lang("소통"),
          type: "channel",
          link: `${hubBaseHost()}/community`,
          visible: true,
        },
        {
          id: 2,
          menuName: this.lang("결재"),
          type: "system",
          link: `${eaBaseHost()}`,
          visible: true,
        },
        {
          id: 3,
          menuName: this.lang("인사"),
          type: "Home",
          link: "",
          visible: true,
        },
        {
          id: 4,
          menuName: this.lang("재무"),
          type: "system",
          link: `${eaBaseHost()}/finance`,
          visible: true,
        },
        {
          id: 5,
          menuName: this.lang("협업"),
          type: "system",
          link: `${hubBaseHost()}/kanban`,
          visible: false,
        },
        {
          id: 6,
          menuName: this.lang("평가"),
          type: "system",
          link: `${peBaseHost()}/pe`,
          visible: false,
        },
        {
          id: 7,
          menuName: this.lang("급여"),
          type: "system",
          link: `${payrollBaseHost()}/payroll`,
          visible: false,
        },
        {
          id: 8,
          menuName: this.lang("자산"),
          type: "system",
          link: `${hubBaseHost()}/ga`,
          visible: true,
        },
      ],
      menuList: [],
      dropdownVisible: {
        gnb: false,
        user: false,
        profile: false,
        notification: false,
      },
      isPeople: true,
      isWorkspace: false,
      isCommunity: false,
      isFinance: false,
      isKanban: false,
      currentChannel: 0,
      showPoint: false,
    };
  },
  computed: {
    ...mapGetters("common", {
      cloudLogo: "cloudLogo",
      cloudInfo: "cloudInfo",
    }),
    ...mapGetters("user", {
      userInfo: "getUserInfo",
      userMenu: "userMenu",
    }),
    ...mapGetters("auth", {
      accessToken: "accessToken",
    }),
    ...mapGetters("settings", {
      currentLocale: "currentLocale",
    }),
    ...mapGetters("notification", {
      unreadNotificationCount: "getUnreadCount",
    }),
    currentCompanyId() {
      return this.userInfo.companyCode;
    },
    currentLogo() {
      for (const logo of this.cloudLogo) {
        if (logo.companyId === this.currentCompanyId) {
          return logo.logoUrl;
        }
      }
      return null;
    },
    userPhotoSrc() {
      if (this.userInfo.userPhoto) {
        return `${apiBaseUrl()}/common/public/user-photo/default/${
          this.userInfo.userPhoto
        }`;
      }
      return unknownImage;
    },
    programs() {
      return this.$store.getters["user/programs"];
    },
    systemName() {
      return process.env.SYSTEM_NAME;
    },
    currentTab() {
      if (this.$route.meta) return this.$route.meta.code;
      return "";
    },
    filteredChannelList() {
      return this.channelList.filter(({ id }) => {
        return (
          !(id === 4 && !this.userInfo.isFinanceAdmin) &&
          !(id === 5 && !this.cloudInfo.useKanban) &&
          !(id === 6 && !this.cloudInfo.usePerformance) &&
          !(id === 7 && !this.cloudInfo.usePayroll) &&
          !(
            id === 8 &&
            (!this.userInfo.isGaPrivileged || !this.cloudInfo.useGa)
          )
        );
      });
    },
  },
  watch: {
    "$store.state.user.userOn"(isOn) {
      if (isOn) {
        this.$store.dispatch("notification/init"); // notification 폴링 start
      } else {
        this.$store.dispatch("notification/stopPolling"); // notification 폴링 stop
      }
    },
  },
  methods: {
    ...mapActions("common", ["pushTab"]),
    ...mapActions("settings", ["updateLocale"]),
    changeChannel(event, channel) {
      const TARGET = {
        SELF: "_self",
        BLANK: "_blank",
        OPEN: undefined,
      };
      const { metaKey, ctrlKey, shiftKey } = event;
      const useBlank = metaKey || ctrlKey;
      const useNewWindow = shiftKey;
      let target = TARGET.SELF;

      if (useBlank) {
        target = TARGET.BLANK;
      } else if (useNewWindow) {
        target = TARGET.OPEN;
      }

      if (channel.type === "channel") {
        if (channel.id === 1) {
          // GW
          this.isPeople = false;
          this.isCommunity = true;
          window.open(hubBaseHost() + "/community?desktopMode=true", target);
        }
      } else if (channel.type === "system") {
        if (channel.id === 2) {
          // WF
          this.isPeople = false;
          this.isWorkspace = true;
          window.open(eaBaseHost(), target);
        } else if (channel.id === 5) {
          this.isKanban = true;
          window.open(`${channel.link}`, target);
        } else {
          this.isFinance = true;
          window.open(`${channel.link}?desktopMode=true`, target);
        }
      }
    },
    toHub(to) {
      return `${hubBaseHost()}${to}?desktopMode=true`;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
$logout-button-height: 36px;

.navbar {
  display: flex;
  height: $gpro-header-height;
  align-items: center;
  padding: 0 30px;

  &__section {
    height: inherit;

    &--logo {
      .navbar-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 100%;
        width: 162px;
        cursor: pointer;

        &__img {
          width: 162px;
          height: 60px;
        }
      }
    }

    &--header {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
    }
  }

  .header-channel {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 7px;
    word-break: keep-all;

    &__title {
      color: $gpro-gnb-text-color;
      cursor: pointer;
      font-size: $--font-size-medium;
      font-weight: $gpro-font-weight-500;
      padding: 8px 16px;
      border-radius: 10px;
      margin: 0 7px;
      @include default-transition(background-color);

      &.active,
      &:hover {
        background-color: $gpro-gnb-active-color;
      }
    }
  }

  .header-controls {
    display: flex;
    margin-left: auto;
    align-items: center;
    justify-content: flex-end;

    &__item {
      position: relative;
      cursor: pointer;
      margin: 7px;

      ::v-deep svg {
        color: $gpro-gnb-icon-color;
      }

      .header-link {
        display: flex;
        width: 40px;
        height: 40px;
        border-radius: 10px;
        justify-content: center;
        align-items: center;

        &.a-active,
        &.active,
        &:hover {
          background-color: $gpro-gnb-active-color;
        }
      }

      &.notification {
        .new {
          width: 5px;
          height: 5px;
          position: absolute;
          top: 19%;
          right: 30%;
          border-radius: 50%;
          background-color: $--color-danger;
        }
      }

      &.profile {
        .profile-photo {
          @include thumbnail(40px, 40px, 50%);

          &:hover::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
</style>
