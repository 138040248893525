<template>
  <div class="top-banner">
    <div class="row">
      <div class="col">
        <span class="top-banner__text">
          {{ $tc("클라우드.사용기간", null, { freeTrialExpiredAt }) }}
        </span>
      </div>

      <div class="col">
        <span class="top-banner__text">
          <span class="top-banner__text">{{ $tc("클라우드.안내문구_1") }}</span
          ><a
            class="top-banner__text top-banner__link"
            :href="requestURL"
            target="_blank"
            >{{ $tc("클라우드.안내문구_2") }}</a
          ><span class="top-banner__text"
            >{{ $tc("클라우드.안내문구_3") }}
          </span>
        </span>
      </div>

      <div class="col col--flex-none">
        <button class="top-banner__button" @click="$emit('close')">
          <i class="el-dialog__close el-icon el-icon-close"></i>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TopBanner",

  props: {
    cloudInfo: {
      type: Object,
      default: () => ({}),
    },
    requestURL: {
      type: String,
      default: "https://forms.gle/8Rind8PPaZQuJXQU9",
    },
  },

  computed: {
    freeTrialExpiredAt() {
      return this.cloudInfo.freeTrialExpiredAt || "2020년 07월 21일";
    },
  },
};
</script>

<style lang="scss" scoped>
.top-banner {
  position: fixed;
  top: 0;
  z-index: 2000;
  width: 100%;
  height: $gpro-top-banner-height;
  background-color: $--color-text-primary;
  display: flex;
  align-items: center;
  min-height: 0;
  min-width: 0;

  .row {
    flex: 1;
    display: flex;
    padding: 10px;
    height: 100%;
  }

  .col {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    padding: 10px;
    height: 100%;

    &--flex-none {
      flex: none;
    }
  }

  &__text {
    font-size: 20px;
    color: $--color-white;
  }

  &__link {
    color: $--link-hover-color;
    text-decoration: underline;
  }

  &__button {
    background: transparent;
    border: none;
  }

  .el-dialog__close {
    color: $--color-white;
    font-size: $--font-size-medium;
    font-weight: $gpro-font-weight-700;
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    .top-banner__text {
      font-size: $--font-size-medium;
    }
  }

  @media (max-width: 768px) {
    .top-banner__text {
      font-size: $--font-size-base;
    }
  }
}
</style>
