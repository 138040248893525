<template>
  <a
    v-if="targetHost"
    :href="href"
    role="link"
    class="command-k-more-item-link"
    @click.prevent.stop.once="searchToTarget"
  >
    <span>{{ $t("커맨드케이.리스트.아이템.텍스트.모든결과보기") }}</span>
  </a>

  <router-link
    v-else
    :to="{
      name: COMMAND_K.ITEM_TYPE_SEARCH,
      query: { keyword: search },
    }"
    class="command-k-more-item-link"
    role="link"
    @click.native.stop="closeCommandK"
  >
    <span>{{ $t("커맨드케이.리스트.아이템.텍스트.모든결과보기") }}</span>
  </router-link>
</template>

<script>
import { COMMAND_K } from "@/components/command-k/utils";

export default {
  name: "CommandKItemMoreItems",

  props: {
    targetHost: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      COMMAND_K,
    };
  },

  computed: {
    search() {
      return this.$store.state.commandK.search;
    },
    href() {
      return `${this.targetHost}/search?keyword=${encodeURIComponent(
        this.search,
      )}`;
    },
  },

  methods: {
    searchToTarget() {
      window.location.href = this.href;
    },
    closeCommandK() {
      this.$store.commit("commandK/setVisible", false);
    },
  },
};
</script>
