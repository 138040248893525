export default function appVersionPinter() {
  const webVersion = process.env.VUE_APP_WEB_VERSION;
  if (!webVersion) {
    return;
  }
  const brandText = `%cGroupware Pro`;
  const slogan = `%c프로가 일하는 방식, 그룹웨어프로 %c${webVersion}`;

  const commonStyles = [
    "font-family: -apple-system, BlinkMacSystemFont, 'Malgun Gothic', '맑은 고딕', helvetica, 'Apple SD Gothic Neo', sans-serif",
    "display: inline-block",
    "letter-space:-1px",
  ];
  const brandStyles = [
    ...commonStyles,
    "font-family: 'Apple SD Gothic ExtraBold', 'Arial Black', 'Malgun Gothic', '맑은 고딕', helvetica, sans-serif",
    "font-size: 3rem",
    "font-weight: 900",
    "padding: 1rem 0 0",
  ].join(";");
  const sloganStyles = [
    ...commonStyles,
    "font-size: 1rem",
    "font-weight: 500",
    "padding: 0.5rem 0 2rem 2rem",
  ].join(";");
  const versionStyles = [
    ...commonStyles,
    "font-size: .75rem",
    "font-weight: 500",
    "padding: 0.5rem 1rem 2rem 1rem",
  ].join(";");

  setTimeout(() => {
    console?.log?.(brandText, brandStyles);
    console?.log?.(slogan, sloganStyles, versionStyles);
  }, 5000);
}
