<template>
  <!--  <el-scrollbar
    wrap-class="scrollbar__wrap scrollbar__wrap&#45;&#45;file-list"
    view-class="scrollbar__list">-->
  <div class="file-list-view">
    <ul v-if="fileList.length">
      <li
        v-for="(file, index) in fileList"
        :key="file.name + index"
        class="file-list-view__item"
      >
        <a v-if="downloadable" :href="file.url" class="button--download">
          <i class="el-icon-document" style="margin-right: 5px"></i>
          <span>{{ file.name }}</span>
        </a>
        <a
          v-else
          :href="void 0"
          class="button--download button--download-disabled"
          >{{ file.name }}</a
        >
        <a
          v-if="removable"
          class="button--remove"
          href="javascript: void(0)"
          @click.prevent="beforeRemove(file, fileList)"
          >&nbsp;</a
        >
      </li>
    </ul>
    <div v-else class="no-files">{{ lang("msg.noFile") }}</div>
  </div>
  <!--  </el-scrollbar>-->
</template>

<script>
import { i18nTranslator } from "@/mixins/i18n";

export default {
  name: "FileListView",
  mixins: [i18nTranslator(null)],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    beforeRemove: Function,
    // eslint-disable-next-line vue/require-default-prop
    groupId: [String, Number],
    // eslint-disable-next-line vue/require-default-prop
    fileList: Array,
    removable: {
      type: Boolean,
      default: true,
    },
    downloadable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.file-list-view {
  padding: 10px 5px;
  min-height: 100px;
  max-height: 400px;
  overflow: auto;

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px;

    &:hover {
      background: $--color-primary-light-9;
    }

    .button--download {
      text-decoration: none;
      color: $--color-text-secondary;

      &-disabled {
        color: $--color-text-placeholder;
        cursor: text;
      }
    }

    .button--remove {
      display: block;
      flex-shrink: 0;
      width: 12px;
      height: 12px;
      background: url(../../assets/icon/img_iconpack.png) no-repeat -313px -122px;
      text-decoration: none;
    }
  }

  .no-files {
    color: $--color-text-secondary;
  }
}
</style>
