<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="vue-tree">
    <ul>
      <tree-item
        :node.sync="optionData.root"
        :organization-id="organizationId"
        :org-select="orgSelectCustom"
        :last-organization-step="lastOrganizationStep"
        :is-create-org="isCreateOrg"
      />
    </ul>
  </div>
</template>

<script>
import Vue from "vue";
import debounce from "lodash/debounce";
import TreeItem from "./TreeItem";

export default {
  components: {
    TreeItem,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    option: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    orgSelect: {
      type: Function,
    },
    // eslint-disable-next-line vue/require-default-prop
    filterText: {
      type: String,
    },
    lastOrganizationStep: {
      required: true,
      type: Number,
    },
    isCreateOrg: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      organizationId: null,
      optionData: {},
    };
  },
  watch: {
    filterText: debounce(function (val) {
      this.filterMethod(val || "", this.option.root);
      Vue.set(this.option.root, "isOpen", true);
    }, 50),
  },
  created() {
    this.optionData = this.option;
  },
  methods: {
    orgSelectCustom(node) {
      this.organizationId = node.organizationId;
      this.currentNode = node;
      this.orgSelect(node);
    },
    setNodeById(id) {
      const list = this.option.root;
      let matchedNodes = [];
      const findNode = function (id, node) {
        let result;
        if (id === node.organizationId) {
          matchedNodes.push(node);
          return node;
        } else {
          const childrenNode = node.children;
          if (childrenNode) {
            for (let i = 0; i < childrenNode.length; i++) {
              Vue.set(childrenNode[i], "parent", node);
              result = findNode(id, childrenNode[i]);
              if (result !== false) {
                return result;
              }
            }
          }
          return false;
        }
      };
      const matchedNode = findNode(id, list);
      if (matchedNode) {
        // select matchedNode
        this.orgSelectCustom(matchedNode);
        // open nodes that matched
        matchedNodes.forEach((node) => this.handleNode(node, true));
        // clear
        this.clearNodeParent(list);
        return matchedNode;
      }
      return null;
    },
    filterMethod(value, tree) {
      const showNodes = [];
      const closeNodes = [];
      const traverse = function (node) {
        if (node && node.organizationName) {
          if (
            value &&
            node.organizationName.toLowerCase().indexOf(value.toLowerCase()) !==
              -1
          ) {
            Vue.set(node, "isSearched", true);
            showNodes.push(node);
          } else {
            Vue.set(node, "isSearched", false);
            closeNodes.push(node);
          }
          const childNodes = node.children || [];
          childNodes.forEach((child) => {
            Vue.set(child, "parent", node);
            traverse(child);
          });
        }
      };
      traverse(tree);
      closeNodes.forEach((node) => this.handleNode(node, false));
      showNodes.forEach((node) => this.handleNode(node, true));
      this.clearNodeParent(tree);
    },
    clearNodeParent(node) {
      const childNodes = node.children || [];
      childNodes.forEach((child) => {
        Vue.set(child, "parent", undefined);
        this.clearNodeParent(child);
      });
    },
    handleNode(node, bool) {
      // eslint-disable-next-line no-prototype-builtins
      if (node.hasOwnProperty("parent")) {
        Vue.set(node.parent, "isOpen", bool);
        this.handleNode(node.parent, bool);
      }
    },
  },
};
</script>
