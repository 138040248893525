import auth from "./auth";
import settings from "./settings";
import * as config from "@/config";

// Sync with local storage.
if (localStorage.getItem(config.STORAGE_KEY)) {
  const syncedState = JSON.parse(localStorage.getItem(config.STORAGE_KEY));
  auth.state = Object.assign(auth.state, syncedState.auth);
  settings.state = Object.assign(settings.state, syncedState.settings);
}

// LocalStorage plugin.
const localStoragePlugin = (store) => {
  store.subscribe((mutation, state) => {
    const syncedData = { auth: state.auth, settings: state.settings };
    localStorage.setItem(config.STORAGE_KEY, JSON.stringify(syncedData));
    if (mutation.type === "common/clear") {
      localStorage.removeItem(config.STORAGE_KEY);
    }
  });
};

export { localStoragePlugin };
