<template>
  <div
    v-show="isRender"
    ref="itemRef"
    :key="itemId"
    class="command-k-item"
    role="option"
    :aria-selected="selectedNode === item.itemId"
    :aria-disabled="!isRender"
    command-k-item=""
    :command-k-item-key="item.itemId"
    :command-k-data-label="item.label"
    :command-k-data-keywords="item.keywords"
    @click="handleSelect"
  >
    <slot />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { COMMAND_K } from "@/components/command-k/utils";
import { throttle } from "lodash";

export default {
  name: "CommandKItem",

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      itemRef: null,
    };
  },

  computed: {
    ...mapState("commandK", ["filtered", "selectedNode"]),
    isSearching() {
      return this.$store.getters["commandK/isSearching"];
    },
    isRender() {
      const itemKey = this.filtered.items.get(this.item.itemId);

      return this.isSearching ? itemKey !== undefined || this.item.fixed : true;
    },
  },

  mounted() {
    this.itemRef = this.$refs.itemRef;
    // 키보드 컨트롤 시 이벤트 호출할 수 있도록 커스텀 이벤트 등록
    this.itemRef.addEventListener(
      COMMAND_K.EVENT_SELECT_ITEM,
      this.handleSelect,
    );
  },
  beforeDestroy() {
    this.itemRef?.removeEventListener(
      COMMAND_K.EVENT_SELECT_ITEM,
      this.handleSelect,
    );
  },

  methods: {
    // 액션 콜백을 여러 번 실행하지 않도록 쓰로틀링
    handleSelect: throttle(function () {
      this.$emit(COMMAND_K.EVENT_SELECT_ITEM, {
        ...this.item,
        itemId: this.itemId,
      });
    }, 1000),
  },
};
</script>
