// import mime from 'mime-types';

/**
 * @desc FILE ATTACHMENT RULES
 */
const byte = 1;
const kiloByte = byte * 1024;
const megaByte = kiloByte * 1024;

export const FILE = {
  common: {
    mimeTypes: [
      "text/plain",
      "application/msword",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/zip",
    ],
    extensions: [
      "txt",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "pdf",
      "hwp",
      "zip",
      "ppt",
      "pptx",
    ].concat(["jpg", "jpeg", "png", "gif"]),
    size: megaByte * 100,
  },
  image: {
    mimeTypes: [
      "image/gif",
      "image/png",
      "image/jpeg",
      "image/bmp",
      "image/webp",
    ],
    extensions: ["jpg", "jpeg", "png", "gif"],
    size: megaByte * 1,
  },
  excel: {
    mimeTypes: [
      "text/plain",
      "application/msword",
      "application/pdf",
      "application/vnd.ms-excel",
      "application/zip",
    ],
    extensions: ["xls", "xlsx"],
  },
};

/**
 * @desc DATE FORMATTING RULES
 */
const DIGITS = "-";
const COLONS = ":";
export const DATE_FORMAT = {
  longDate: `YYYY${DIGITS}MM${DIGITS}DD`,
  longDate2: `yyyy${DIGITS}MM${DIGITS}dd`,
  longDateValue: "YYYYMMDD",
  longDateValue2: `yyyyMMdd`,
  longMonth: `YYYY${DIGITS}MM`,
  longMonth2: `yyyy${DIGITS}MM`,
  longMonthValue: "YYYYMM",
  longMonthValue2: "yyyyMM",
  longYear: "YYYY",
  longYear2: "yyyy",
  longYearValue: "YYYY",
  longYearValue2: "yyyy",
  longDateTimeMinute: "YYYYMMDDHHmm",
  hourMinute: `HH${COLONS}mm`,
};

export default {
  FILE,
  DATE_FORMAT,
};
