<template>
  <div
    ref="listRef"
    role="listbox"
    aria-label="Suggestions"
    class="command-k-list"
  >
    <div ref="heightRef" class="command-k-sizer">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { COMMAND_K, eventBus } from "@/components/command-k/utils";

export default {
  name: "CommandKList",

  data() {
    return {
      heightRef: null,
      listRef: null,

      observer: null,
      sizer: null,
    };
  },

  computed: {
    ...mapState("commandK", ["loading"]),
  },

  watch: {
    heightRef: {
      handler: "handleObserver",
    },
    listRef: {
      handler: "handleObserver",
    },
  },

  mounted() {
    this.heightRef = this.$refs.heightRef;
    this.listRef = this.$refs.listRef;
  },

  beforeDestroy() {
    if (this.observer !== null && this.sizer) {
      this.observer.unobserve(this.sizer);
    }
  },

  methods: {
    // 리스트 높이가 바뀌는 경우 트랜지션을 위해 옵져버 생성
    handleObserver() {
      this.sizer = this.heightRef;
      const wrapper = this.listRef;

      // 모든 조건이 만족되면 옵져버 생성
      if (this.sizer && this.listRef && this.observer === null) {
        this.observer = new window.ResizeObserver(() => {
          this.$nextTick(() => {
            const height = this.sizer?.offsetHeight;

            wrapper?.style.setProperty(
              "--command-list-height",
              `${height?.toFixed(1)}px`,
            );

            if (this.loading) return;

            // 사이즈 변경 후 리스트 초기화 및 첫 번째 아이템 선택 되도록 emit
            eventBus.$emit(COMMAND_K.EVENT_RERENDER_LIST, true);
          });
        });
        this.observer.observe(this.sizer);
      }
    },
  },
};
</script>
