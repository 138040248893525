import Vue from "vue";
import store from "@/store";

function flatten(userMenu) {
  const result = [];
  const pop = (menuList) => {
    menuList.forEach((v) => {
      if (v.children) {
        pop(v.children);
      }
      result.push(v);
    });
  };
  pop(userMenu);
  return result;
}
function findByProgramCode(programCode) {
  const userMenu = store.getters["user/userMenu"];
  return flatten(userMenu).find((v) => v.programCode === programCode);
}
function isArray(value) {
  return Object.prototype.toString.call(value) === "[object Array]";
}

function log(msg) {
  if (process.env.NODE_ENV === "development") console.log(msg);
}
function _parse(value) {
  return JSON.parse(value);
}
function _tostring(value) {
  return JSON.stringify(value);
}
function _clone(value) {
  return _parse(_tostring(value));
}
function diff(uid, state) {
  if (state.originalContexts[uid]) {
    const valueKeys = Object.keys(state.originalContexts[uid]);
    for (let key of valueKeys) {
      // # 아래 맵핑, diff 코드는, [{key: 'value'}, {key: 'value'}, ...] 형태의 리스트데이터에 국한됨
      if (isArray(state.contexts[uid][key])) {
        const originalContextRowKeys = Object.keys(
          state.originalContexts[uid][key][0] || [],
        ); // 오리지널 Array object의 임의(첫번째)의 Row Keys를 추출 (비교할 원시 Row 데이터의 키들을 추출)
        if (originalContextRowKeys && originalContextRowKeys.length) {
          const unionContext = state.contexts[uid][key].map((row) => {
            Object.entries(row).forEach(([path]) => {
              if (!originalContextRowKeys.includes(path)) {
                delete row[path];
              }
            });
            return row;
          });
          if (
            _tostring(state.originalContexts[uid][key]) !==
            _tostring(unionContext)
          ) {
            return {
              programCode: uid,
              menuName: findByProgramCode(uid).menuName,
              changed: true,
            };
          }
        }
        // # 그외 타입
      } else if (
        _tostring(state.originalContexts[uid][key]) !==
        _tostring(state.contexts[uid][key])
      ) {
        return {
          programCode: uid,
          menuName: findByProgramCode(uid).menuName,
          changed: true,
        };
      }
      if (
        state.originalContexts[uid][key].length !==
        state.contexts[uid][key].length
      ) {
        return {
          programCode: uid,
          menuName: findByProgramCode(uid).menuName,
          changed: true,
        };
      }
    }
  }
  return {
    programCode: null,
    menuName: null,
    changed: false,
  };
}

export default {
  namespaced: true,
  state: {
    contexts: {
      // CAD0001: {
      //    '__SAFE__gridData': []
      // }
    },
    originalContexts: {},
  },
  getters: {
    getContextsAndOriginal(state) {
      return state;
    },
  },
  mutations: {
    setContext(state, { uid, valueKey, value }) {
      // eslint-disable-next-line no-prototype-builtins
      if (!state.originalContexts.hasOwnProperty(uid)) {
        state.originalContexts[uid] = {};
        state.contexts[uid] = {};
      }
      state.originalContexts[uid][valueKey] = _clone(value);
      state.contexts[uid][valueKey] = value;
    },
    resetContext(state, uid) {
      if (Array.isArray(uid)) {
        for (let index in uid) {
          delete state.originalContexts[uid[index]];
          delete state.contexts[uid[index]];
        }
        return;
      }
      delete state.originalContexts[uid];
      delete state.contexts[uid];
    },
  },
  actions: {
    set: function ({ commit }, { uid, valueKey, value }) {
      commit("setContext", { uid, valueKey, value });
    },
    reset: function ({ commit }, uid) {
      commit("resetContext", uid);
    },
    detect: function ({ state, dispatch, rootGetters }, { uid, instance }) {
      const programs = rootGetters["user/programs"];
      const traverse = function (id) {
        const childs = programs[id] && programs[id].children;
        if (childs) {
          for (let child of childs) {
            const result = diff(child.programCode, state);
            if (result.changed) {
              return result;
            }
            traverse(child.programCode);
          }
        }
        const result = diff(id, state);
        return result;
      };

      return new Promise((resolve, reject) => {
        const diffResult = traverse(uid);
        if (diffResult.changed) {
          Vue.confirm(
            instance.$t("msg.unsavedWarningMessage"),
            diffResult.menuName,
            {
              type: "warning",
              confirmButtonText: instance.$t("msg.continueButtonText"),
              cancelButtonText: instance.$t("msg.cancelButtonText"),
            },
          )
            .then(() => {
              dispatch("reset", uid);
              log("Continue.");
              resolve(true);
              // @TODO error가 cancel로 잡히는지 확인해서 mixins/safe.js 에 return value 체크
              // 작성된 상태의 탭이 포커스가 안된경우 (다른 탭에서 작성된것이 있는 탭을 닫을 경우) cancel 시 focus 이동하는 기능 추가
            })
            .catch((error) => reject(error));
        } else {
          log("변경된 내용이 없습니다.");
          resolve(true);
        }
      });
    },
  },
};
