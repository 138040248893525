import Vue from "vue";
import deepmerge from "deepmerge";
import VueI18n from "vue-i18n";
import store from "@/store";
const ElementUILocales = {
  ko: require("element-ui/lib/locale/lang/ko").default,
  en: require("element-ui/lib/locale/lang/en").default,
  ja: require("element-ui/lib/locale/lang/ja").default,
  zh: require("element-ui/lib/locale/lang/zh-CN").default,
};

Vue.use(VueI18n);
// 브라우저 언어 체크.. 기본 language 설정
const userLang = navigator.language || navigator.userLanguage;

// Element UI locale 설정
function loadLocaleMessages() {
  const locales = require.context("@/lang", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = deepmerge(ElementUILocales[locale], locales(key));
    }
  });
  return messages;
}

function getLocaleCode(lang) {
  if (lang.toLowerCase().includes("ko")) return "ko";
  if (lang.toLowerCase().includes("en")) return "en";
  if (lang.toLowerCase().includes("ja")) return "ja";
  return "ko"; // fallback
}

export default new VueI18n({
  locale: store.state.settings.currentLocale || getLocaleCode(userLang),
  fallbackLocale: "ko",
  messages: loadLocaleMessages(),
  silentTranslationWarn: true, // 일단 console 경고메시지 출력안하게 설정
  // silentTranslationWarn: process.env.NODE_ENV === 'production'
});
