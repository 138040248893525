<template>
  <div class="gnb-menu-wrapper">
    <nav id="gnb-menu" class="gnb-menu">
      <!--
       # LOOP depth 1 (HIT WORK, ESS, Community)
      -->
      <div class="gnb-menu__inner">
        <div
          v-for="menu in headerMenu"
          :key="menu.menuId"
          class="gnb-menu__section"
        >
          <p class="menu-link depth-1">{{ menu.menuName }}</p>
          <!--
          # LOOP depth 2 (공유하는..., 함께하는...)
        -->
          <ul class="container-1">
            <li
              v-for="menu_b in menu.children"
              :key="menu_b.menuId"
              class="gnb-menu__column"
            >
              <p class="menu-link depth-2">{{ menu_b.menuName }}</p>
              <!--
              # LOOP depth 3 (OO게시판...)
            -->
              <ul>
                <li
                  v-for="menu_c in menu_b.children"
                  :key="menu_c.menuId"
                  class="gnb-menu__list"
                >
                  <component
                    :is="component(menu_c).name"
                    v-bind="component(menu_c).attributes"
                    class="menu-link depth-3"
                    v-on="component(menu_c).listeners"
                    >{{ menu_c.menuName }}</component
                  >
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { hrBaseHost, hubBaseHost } from "../../../config/cloudEnv";

export default {
  name: "GnbMenu",
  computed: {
    ...mapGetters("user", {
      userInfo: "getUserInfo",
      headerMenu: "getHeaderMenu",
    }),
  },
  methods: {
    mapMenus(children = []) {
      const newArr = [];
      while (children.length) {
        newArr.push(children.splice(0, 3));
      }
      return newArr;
    },
    component(linkInfo) {
      const { linkType, linkUrl, menuTypeCode } = linkInfo;

      let linkObj = {
        name: "a",
        attributes: {
          href: linkUrl,
          to: linkUrl,
          target: "_self",
        },
      };

      // 1.EA
      if (linkType === "ea") {
        return {
          ...linkObj,
          name: "router-link",
        };
      }

      // 2. HR
      if (linkType === "people") {
        linkObj.attributes.href = `${hrBaseHost()}${linkUrl}`;
        return linkObj;
      }

      // 3. HUB
      if (linkType === "internal" || menuTypeCode === "board") {
        linkObj.attributes.href = `${hubBaseHost()}${linkUrl}`;
        return linkObj;
      }

      // 4. 외부링크
      if (linkType === "external") {
        linkObj.attributes.target = "_blank";
        return linkObj;
      }

      // 5. 그 외 혹시 모르는 case
      return linkObj;
    },
  },
};
</script>

<style lang="scss" scoped>
$gnb-meu-width: 1552px;
$gnb-menu-padding: 16px;
$gnb-depth1-width: 388px;

.gnb-menu-wrapper {
  position: fixed;
  z-index: 2001;
  top: $gpro-header-height;
  left: 0;
  right: 0;
  margin: 0;
  height: 420px;
  border-bottom: 1px solid $--border-color-base;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.05);
}

.gnb-menu {
  height: 100%;
  padding-top: 18px;
  font-size: 15px;
  background-color: $--background-color-base;
  height: inherit;
  overflow: auto;
  &__inner {
    max-width: $gnb-meu-width + $gnb-menu-padding * 2;
    margin: 0 auto;
    padding: 0 $gnb-menu-padding;
    height: 100%;
  }
  /* Depth: 1 */
  &__section {
    display: inline-block;
    position: relative;
    width: $gnb-depth1-width;
    vertical-align: top;
    height: inherit;
    padding: 0 24px;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 1px;
      pointer-events: none;
    }
    .container-1 {
      margin: 16px 0;
      height: calc(100% - 72px);
      overflow-y: auto;
      @include custom-scroll("vertical", #dadada, 6px);
    }

    &.fix {
      width: 200px;
      min-width: 200px;

      .gnb-menu__column {
        width: 100%;
        flex-basis: 100%;
      }
    }

    & > .menu-link.depth-1 {
      font-size: $--font-size-medium;
      color: $gpro-warm-gray-900;
      font-weight: $gpro-font-weight-700;
      border-bottom: 2px solid $gpro-warm-gray-800;
      padding: 8px 0;

      &::after {
        content: "";
        border-right: 1px solid $--color-white;
        right: -1px;
        position: absolute;
        height: 20px;
      }
    }

    /* Depth: 2 wrapper */
    .gnb-menu__column {
      display: inline-block;
      vertical-align: top;
      width: 50%;
      min-height: 50%;

      /* Depth: 2 */
      & > .menu-link.depth-2 {
        font-size: 15px;
        font-weight: $gpro-font-weight-500;
        color: $gpro-warm-gray-800;
        margin-bottom: 2px;
      }

      .gnb-menu__list {
        height: 30px;
        line-height: 30px;
        &:first-child {
          padding-top: 0;
        }

        &-item {
          line-height: 1.25;
          padding-bottom: 35px;
          padding: 5px 0;
        }

        & > .menu-link.depth-3 {
          font-size: $--font-size-small;
          color: $--color-text-regular;
          font-weight: $gpro-font-weight-300;
        }
      }
    }
  }
}
</style>
