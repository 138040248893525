// customs and element-ui customs
import PictureUpload from "@/components/file/PictureUpload";
import FileUpload from "@/components/file/FileUpload";
import FileDownload from "@/components/file/FileDownload.vue";
import Table from "@/components/table";
import TableColumn from "@/components/table-column";
import Pagination from "@/components/pagination";
import Input from "@/components/input";
import Anchor from "@/components/styled-tag/Anchor";
import ExcelUpload from "@/components/file/ExcelUpload";
import Loading from "@/components/loading";
import DatePicker from "@/components/date-picker";

// element-ui message wrapper
import { messageWrap, confirmWrap, alertWrap } from "./element-message-wrap";

// element-ui libs
import locale from "element-ui/src/locale";

// element-ui cores
import Avatar from "element-ui/packages/avatar";
import Scrollbar from "element-ui/packages/scrollbar";
import Drawer from "element-ui/packages/drawer";
import Dialog from "element-ui/packages/dialog";
import Menu from "element-ui/packages/menu";
import Submenu from "element-ui/packages/submenu";
import MenuItem from "element-ui/packages/menu-item";
import MenuItemGroup from "element-ui/packages/menu-item-group";
import InputNumber from "element-ui/packages/input-number";
import Radio from "element-ui/packages/radio";
import RadioGroup from "element-ui/packages/radio-group";
import Checkbox from "element-ui/packages/checkbox";
import CheckboxGroup from "element-ui/packages/checkbox-group";
// import Input from "element-ui/packages/input";
import Select from "element-ui/packages/select";
import Option from "element-ui/packages/option";
import OptionGroup from "element-ui/packages/option-group";
import Button from "element-ui/packages/button";
// import DatePicker from 'element-ui/packages/date-picker';
import Tooltip from "element-ui/packages/tooltip";
import Form from "element-ui/packages/form";
import FormItem from "element-ui/packages/form-item";
import Tabs from "element-ui/packages/tabs";
import TabPane from "element-ui/packages/tab-pane";
import Tree from "element-ui/packages/tree";
import Row from "element-ui/packages/row";
import Col from "element-ui/packages/col";
// import Upload from 'element-ui/packages/upload';
import Upload from "@/components/upload";
import Badge from "element-ui/packages/badge";
import Card from "element-ui/packages/card";
import Container from "element-ui/packages/container";
import Header from "element-ui/packages/header";
import Aside from "element-ui/packages/aside";
import Main from "element-ui/packages/main";
// import Loading from 'element-ui/packages/loading';
// import MessageBox from 'element-ui/packages/message-box';

// import Pagination from 'element-ui/packages/pagination';
import Autocomplete from "element-ui/packages/autocomplete";
import Dropdown from "element-ui/packages/dropdown";
import DropdownMenu from "element-ui/packages/dropdown-menu";
import DropdownItem from "element-ui/packages/dropdown-item";
// import Input from 'element-ui/packages/input';
// import RadioButton from 'element-ui/packages/radio-button';
// import CheckboxButton from 'element-ui/packages/checkbox-button';
import Switch from "element-ui/packages/switch";
// import ButtonGroup from 'element-ui/packages/button-group';
// import Table from 'element-ui/packages/table';
// import TableColumn from 'element-ui/packages/table-column';
import TimeSelect from "element-ui/packages/time-select";
import TimePicker from "element-ui/packages/time-picker";
// import Popover from 'element-ui/packages/popover';
// import Breadcrumb from 'element-ui/packages/breadcrumb';
// import BreadcrumbItem from 'element-ui/packages/breadcrumb-item';
import Tag from "element-ui/packages/tag";
// import Alert from 'element-ui/packages/alert';
// import Slider from 'element-ui/packages/slider';
// import Icon from 'element-ui/packages/icon';
// import Progress from 'element-ui/packages/progress';
// import Rate from 'element-ui/packages/rate';
// import Steps from 'element-ui/packages/steps';
// import Step from 'element-ui/packages/step';
// import Carousel from 'element-ui/packages/carousel';
// import CarouselItem from 'element-ui/packages/carousel-item';
// import Collapse from 'element-ui/packages/collapse';
// import CollapseItem from 'element-ui/packages/collapse-item';
// import Cascader from 'element-ui/packages/cascader';
// import ColorPicker from 'element-ui/packages/color-picker';
import Transfer from "element-ui/packages/transfer";
// import Footer from 'element-ui/packages/footer';
// import Message from 'element-ui/packages/// message';
// import Notification from 'element-ui/packages/// notification';
import TabLink from "@/components/link/TabLink";
import PhotoIcon from "@/components/icon/PhotoIcon";
import BaseSvg from "@/components/icon/BaseSvg";

// Iconify
import { Icon } from "@iconify/vue2";

const components = [
  Avatar,
  Pagination,
  Autocomplete,
  Dialog,
  Scrollbar,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  Select,
  Option,
  OptionGroup,
  Button,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Tree,
  DatePicker,
  Tooltip,
  Form,
  FormItem,
  Row,
  Col,
  Upload,
  Badge,
  Card,
  Container,
  Header,
  Aside,
  Main,
  TimePicker,
  TimeSelect,
  Switch,
  Tag,
  // CUSTOMS
  PictureUpload,
  FileUpload,
  FileDownload,
  Anchor,
  ExcelUpload,
  TabLink,
  PhotoIcon,
  BaseSvg,
  Drawer,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Transfer,
];

const install = function (Vue, options = {}) {
  locale.use(options.locale);
  locale.i18n(options.i18n);
  components.forEach((component) => {
    if (component.install) {
      Vue.use(component);
      return;
    }
    Vue.component(component.name, component);
  });
  Vue.use(Loading.directive);
  Vue.component("IconifyIcon", Icon);

  Vue.prototype.$loading = Vue.loading = Loading.service;
  /**
   * @desc 얼럿 및 모달팝업 컴포넌트:
   * 1. 모달팝업
      <el-dialog/> 사용
   * 2. alert
      $alert 사용 (ex; this.$alert('title', option) )
   * 3. notification message
      $message 사용 (ex; this.$message({type: 'success', title: '', message: ''}))
   * 4. prompt/confirm
      $confirm 사용 (ex; this.$confirm({message: '...',}).then)
  */
  Vue.prototype.$alert = Vue.alert = alertWrap;
  Vue.prototype.$confirm = Vue.confirm = confirmWrap;
  Vue.prototype.$message = Vue.message = messageWrap;
};

export default {
  install,
};
