<template>
  <panel
    v-loading="isLoadingAll"
    :toggl-btn="false"
    :add-header="false"
    element-loading-background="transparent"
    style="margin-top: 0; overflow: auto"
  >
    <tree
      v-for="(treeData, index) in treeList"
      :ref="`new-tree-${index}`"
      :key="`tree_${index}`"
      :org-select="orgSelect"
      :filter-text="filterText"
      :option="{ root: treeData }"
      :last-organization-step="lastOrganizationStep"
      :is-create-org="isCreateOrg"
    />
  </panel>
</template>
<script>
import Panel from "@/components/portlets/Panel";
import { CommonAPI } from "@/services/api";
import Tree from "./Tree";

export default {
  name: "NewTree",
  components: {
    Panel,
    Tree,
  },
  // eslint-disable-next-line
  props: [
    // eslint-disable-next-line vue/require-prop-types
    "placeholder",
    // eslint-disable-next-line vue/require-prop-types
    "pending",
    // eslint-disable-next-line vue/require-prop-types
    "treeList",
    // eslint-disable-next-line vue/require-prop-types
    "defaultProps",
    // eslint-disable-next-line vue/require-prop-types
    "defaultExpandedTreeNodes",
    // eslint-disable-next-line vue/require-prop-types
    "filterNode",
    // eslint-disable-next-line vue/require-prop-types
    "orgSelect",
    // eslint-disable-next-line vue/require-prop-types
    "resetTreeList",
    // eslint-disable-next-line vue/require-prop-types
    "expandOnClickNode",
    // eslint-disable-next-line vue/require-prop-types
    "filterText",
    // eslint-disable-next-line vue/require-prop-types
    "isCreateOrg",
  ],
  data() {
    return {
      isLoading: false,
      organizationStepList: [],
    };
  },
  computed: {
    lastOrganizationStep() {
      return Math.max.apply(Math, this.organizationStepList);
    },
    isLoadingAll() {
      return this.pending || this.isLoading;
    },
  },
  watch: {
    treeList(val, oldVal) {
      if (val !== oldVal) {
        // eslint-disable-next-line vue/no-mutating-props
        this.filterText = "";
      }
    },
  },
  mounted() {
    this.getOrgType();
  },
  methods: {
    setNodeById(id) {
      if (this.treeList.length === 1) {
        const ref = this.$refs["new-tree-0"];
        return ref[0].setNodeById(id);
      }
      return console.warn("Exception Case Detected : Fix it follow @todo");
    },
    async getOrgType() {
      try {
        this.isLoading = true;
        const {
          data: { payload },
        } = await CommonAPI.getCodes({
          lookupType: "ORM_ORG_TYPE_CD",
        });
        this.organizationStepList = payload.map((e) => Number(e.attribute01));
      } catch (e) {
        console.warn("Loading CommonAPI has failed");
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
