<template>
  <div :class="classes" :style="wrapStyle">
    <a
      :title="title || ''"
      href="javascript:void(0)"
      class="operation-button"
      @click.prevent="toggle"
    >
      <slot name="label" />
    </a>
    <div v-if="visible" class="template">
      <div :style="style" class="template-layer">
        <slot />
      </div>
      <div class="background-layer" @click.stop.prevent="toggle" />
    </div>
  </div>
</template>

<script>
export default {
  name: "AppHeaderDropdown",
  // eslint-disable-next-line vue/require-prop-types
  props: [
    // eslint-disable-next-line vue/require-prop-types
    "dropped",
    // eslint-disable-next-line vue/require-prop-types
    "align",
    // eslint-disable-next-line vue/require-prop-types
    "zIndex",
    // eslint-disable-next-line vue/require-prop-types
    "staticPosition",
    // eslint-disable-next-line vue/require-prop-types
    "top",
    // eslint-disable-next-line vue/require-prop-types
    "right",
    // eslint-disable-next-line vue/require-prop-types
    "border",
    // eslint-disable-next-line vue/require-prop-types
    "title",
  ],
  data() {
    return {
      visible: !!this.dropped,
    };
  },
  computed: {
    classes() {
      return [
        "dropdown-wrapper",
        `align-${this.hAlign}`,
        { border: this.border },
        { "static-position": this.staticPosition },
      ];
    },
    hAlign() {
      return this.align === "right" ? "right" : "left";
    },
    wrapStyle() {
      const style = {};
      if (this.zIndex) {
        style.zIndex = this.zIndex;
      }
      return style;
    },
    style() {
      const style = {};
      if (this.top) {
        style.top = this.top;
      }
      if (this.right) {
        style.right = this.right;
      }
      return style;
    },
  },
  watch: {
    dropped(v) {
      this.visible = v;
    },
  },
  methods: {
    toggle() {
      this.visible = !this.visible;
      this.$emit("update:dropped", this.visible);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-wrapper {
  position: relative;

  &.static-position {
    position: static;
    .template-layer {
      position: static;
      border: none;
      min-width: 0;
    }
  }

  &.border {
    .template-layer {
      border: 1px solid $--border-color-base;
      border-top-width: 0;
      box-shadow: none;
    }
  }

  .dropdown-menu__item {
    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  .operation-button {
    display: inline-block;
  }

  .template-layer {
    box-shadow: 0 18px 28px rgba(0, 0, 0, 0.15);
    min-width: 160px;
    position: absolute;
    top: 110%;
    text-align: left;
    background-color: $--color-white;
    z-index: 21;
  }

  .background-layer {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    content: "";
    z-index: 20;
  }

  &.align-left {
    text-align: left;

    .template-layer {
      right: -10px;
    }
  }

  &.align-center {
    text-align: center;

    .template-layer {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &.align-right {
    text-align: right;

    .template-layer {
      right: 0;
    }
  }
}
</style>
