const defaults = {
  isLoggedIn: false,
  accessToken: null,
  refreshToken: null,
  lastLogedIn: null,
};

const auth = {
  namespaced: true,
  // state
  state: Object.assign({}, defaults),
  // mutations
  // mutations are synchronous
  mutations: {
    update(state, data) {
      state = Object.assign({}, defaults, data);
    },
    updateLastLoggedIn(state, data) {
      state.lastLogedIn = data;
    },
    clear(state) {
      // eslint-disable-next-line no-unused-vars
      state = Object.assign(state, defaults);
    },
  },
  getters: {
    accessToken: (state) => {
      return state.accessToken;
    },
  },

  // actions
  // actions are asynchronous
  // actions can dispatch more than 1 mutaion at a time!!!
  actions: {
    clear({ commit }) {
      // 클리어
      commit("clear");
    },
    update({ commit }, data) {
      commit("update", data);
    },
    updateLastLoggedIn({ commit }, data) {
      commit("updateLastLoggedIn", data);
    },
  },
};

export default auth;
