import moment from "moment";

const state = {
  detailParam: {},
};

export default {
  namespaced: true,
  state,
  actions: {
    clearDetailParam({ commit }) {
      commit("SET_DETAIL_PARAM", {});
    },
    openDetailUserCommute(
      { commit },
      { standardMonth, userId, userName, employeeNumber, organizationId },
    ) {
      const ym = moment(standardMonth + "-01");
      const param = {
        startYmd: ym.format("YYYY-MM-DD"),
        endYmd: ym.add(1, "Month").add(-1, "Day").format("YYYY-MM-DD"),
        userId,
        userName,
        employeeNumber,
        organizationId,
      };

      commit("SET_DETAIL_PARAM", param);
    },
  },
  getters: {
    getDetailParam: (state) => state.detailParam,
  },
  mutations: {
    SET_DETAIL_PARAM(state, param) {
      state.detailParam = param;
    },
  },
};
