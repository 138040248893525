import { getValueByPath } from "element-ui/src/utils/util";

export const getCell = function (event) {
  let cell = event.target;

  while (cell && cell.tagName.toUpperCase() !== "HTML") {
    if (cell.tagName.toUpperCase() === "TD") {
      return cell;
    }
    cell = cell.parentNode;
  }

  return null;
};

const isObject = function (obj) {
  return obj !== null && typeof obj === "object";
};

export const orderBy = function (array, sortKey, reverse, sortMethod, sortBy) {
  if (
    !sortKey &&
    !sortMethod &&
    (!sortBy || (Array.isArray(sortBy) && !sortBy.length))
  ) {
    return array;
  }
  if (typeof reverse === "string") {
    reverse = reverse === "descending" ? -1 : 1;
  } else {
    reverse = reverse && reverse < 0 ? -1 : 1;
  }
  const getKey = sortMethod
    ? null
    : function (value, index) {
        if (sortBy) {
          if (!Array.isArray(sortBy)) {
            sortBy = [sortBy];
          }
          return sortBy.map(function (by) {
            if (typeof by === "string") {
              return getValueByPath(value, by);
            } else {
              return by(value, index, array);
            }
          });
        }
        if (sortKey !== "$key") {
          if (isObject(value) && "$value" in value) value = value.$value;
        }
        return [isObject(value) ? getValueByPath(value, sortKey) : value];
      };
  const compare = function (a, b) {
    if (sortMethod) {
      return sortMethod(a.value, b.value);
    }
    for (let i = 0, len = a.key.length; i < len; i++) {
      if ((a.key[i] || "") < (b.key[i] || "")) {
        return -1;
      }
      if ((a.key[i] || "") > (b.key[i] || "")) {
        return 1;
      }
    }
    return 0;
  };
  return array
    .map(function (value, index) {
      return {
        value: value,
        index: index,
        key: getKey ? getKey(value, index) : null,
      };
    })
    .sort(function (a, b) {
      let order = compare(a, b);
      if (!order) {
        // make stable https://en.wikipedia.org/wiki/Sorting_algorithm#Stability
        order = a.index - b.index;
      }
      return order * reverse;
    })
    .map((item) => item.value);
};

export const getColumnById = function (table, columnId) {
  let columns = [...table.columns];
  let column = columns.find((item) => item.id === columnId);
  return column;
};

export const getColumnByCell = function (table, cell) {
  const matches = (cell.className || "").match(/el-table_[^\s]+/gm);
  if (matches) {
    return getColumnById(table, matches[0]);
  }
  return null;
};

export const getRowIdentity = (row, rowKey) => {
  if (!row) throw new Error("row is required when get row identity");
  if (typeof rowKey === "string") {
    if (rowKey.indexOf(".") < 0) {
      return row[rowKey];
    }
    let key = rowKey.split(".");
    let current = row;
    for (let i = 0; i < key.length; i++) {
      current = current[key[i]];
    }
    return current;
  } else if (typeof rowKey === "function") {
    return rowKey.call(null, row);
  }
};
