<template>
  <div ref="commandKRef" class="command-k" @keydown="handleKeyDown">
    <div class="command-k-root">
      <slot />
    </div>
  </div>
</template>

<script>
import Fuse from "fuse.js";
import { mapState } from "vuex";
import { debounce } from "lodash";

import {
  findNextSibling,
  findPreviousSibling,
  eventBus,
  COMMAND_K,
} from "./utils";

const {
  GROUP_HEADING_SELECTOR,
  GROUP_KEY_SELECTOR,
  GROUP_SELECTOR,
  ITEM_KEY_SELECTOR,
  ITEM_SELECTOR,
  ATTR_LABEL,
  ATTR_KEYWORDS,
  ATTR_DISABLED,
  EVENT_SELECT_ITEM,
  EVENT_RERENDER_LIST,
  SELECTED_ITEM_SELECTOR,
  VALID_ITEM_SELECTOR,
} = COMMAND_K;

export default {
  name: "CommandK",

  data() {
    return {
      commandKRef: null,

      /* 검색 기능을 위한 로컬 데이터 컬렉션 */
      allItems: new Map(),
      allItemIds: new Set(),
      allGroupIds: new Map(),
      allValidGroupIds: new Map(),

      /* Refs: https://fusejs.io/api/options.html */
      fuseOptions: {
        threshold: 0.2,
        keys: ["label", "keywords"],
      },
    };
  },

  computed: {
    ...mapState("commandK", ["filtered", "search", "asyncData", "visible"]),
    loading: {
      get() {
        return this.$store.state.commandK.loading;
      },
      set(boolean) {
        this.$store.commit("commandK/setLoading", boolean);
      },
    },
    /* 검색 시 결과 저장용 객체 */
    filtered: {
      get() {
        return this.$store.state.commandK.filtered;
      },
      set(value) {
        this.$store.commit("commandK/setFiltered", value);
      },
    },

    /* 현재 선택된 아이템 DOM node */
    selectedNode: {
      get() {
        return this.$store.state.commandK.selectedNode;
      },
      set(value) {
        this.$store.commit("commandK/setSelectedNode", value);
      },
    },

    /* fuse.js 검색 대상 리스트 생성 */
    fuseList() {
      const fuseList = [];
      for (const [key, { label, keywords }] of this.allItems.entries()) {
        fuseList.push({
          key,
          label,
          keywords,
        });
      }
      return fuseList;
    },

    /* 검색시 사용할 fuse 객체 */
    fuse() {
      const fuseIndex = Fuse.createIndex(this.fuseOptions.keys, this.fuseList);
      return new Fuse(this.fuseList, this.fuseOptions, fuseIndex);
    },
    isSearching() {
      return this.$store.getters["commandK/isSearching"];
    },
    focusableElements() {
      return this.$store.state.commandK.focusableElements;
    },
  },

  watch: {
    selectedNode: {
      handler(newValue) {
        if (newValue) {
          this.$nextTick(this.scrollSelectedIntoView);
        }
      },
    },
    search: {
      handler(newValue) {
        if (newValue) {
          this.loading = true;
        }
        this.filterItems();
      },
    },
  },

  created() {
    eventBus.$on(EVENT_RERENDER_LIST, this.debouncedEmit);
  },

  destroyed() {
    eventBus.$off(EVENT_RERENDER_LIST, this.debouncedEmit);
  },

  mounted() {
    this.$nextTick(() => {
      this.commandKRef = this.$refs.commandKRef;
      this.initStore();
      this.$nextTick(this.selectedFirstItem);
    });
  },

  methods: {
    // 연속적으로 초기화 되지 않도록 디바운스 처리
    debouncedEmit: debounce(function (isRerender) {
      if (isRerender && this.visible) {
        this.initStore();
        this.$nextTick(this.selectedFirstItem);
      }
    }, 100),
    // 키보드 이동시 스크롤 이동
    scrollSelectedIntoView() {
      const item = this.getSelectedItem();

      if (item) {
        // 그룹 첫 번째 아이템일 경우 그룹 헤딩을 먼저 스크롤
        if (item.parentElement?.firstElementChild === item) {
          item
            .closest(GROUP_SELECTOR)
            ?.querySelector(GROUP_HEADING_SELECTOR)
            ?.scrollIntoView({ block: "nearest" });
        }
        item.scrollIntoView({ block: "nearest" });
      }
    },

    // fuse.js 검색 결과를 기반으로 필터링
    filterItems: debounce(function () {
      if (!this.search) {
        this.filtered = { ...this.filtered, count: this.allItemIds.size };
        return;
      }

      // 전체 Group 초기화
      this.filtered = { ...this.filtered, groups: new Set("") };
      const items = new Map();
      const list = this.fuse.search(this.search).map((r) => r.item);

      // 검색된 아이템 데이터 맵 생성
      for (const { key, label, keywords } of list) {
        items.set(key, { label, keywords });
      }

      // 검색된 아이템의 그룹을 찾은 후 맵 생성
      for (const [groupId, itemIdsInGroup] of this.allGroupIds) {
        for (const itemId of itemIdsInGroup) {
          if (items.get(itemId)) {
            this.filtered = {
              ...this.filtered,
              groups: this.filtered.groups.add(groupId),
            };
          }
        }
      }

      this.$nextTick(() => {
        this.filtered = {
          ...this.filtered,
          count: items.size,
          items,
        };
      });
      this.$nextTick(this.selectedFirstItem);
    }, 50),

    // 리스트 데이터 초기화 세팅
    initStore() {
      const allItems = this.getAllItems();
      const groups = this.getValidGroups();
      this.allItemIds = new Set("");
      this.allItems = new Map();

      for (const item of allItems) {
        const itemKey = item.getAttribute(ITEM_KEY_SELECTOR) ?? "";
        const itemLabel = item.getAttribute(ATTR_LABEL) ?? "";
        const itemKeywords = item.getAttribute(ATTR_KEYWORDS) ?? "";

        this.allItemIds.add(itemKey);
        this.allItems.set(itemKey, {
          label: itemLabel,
          keywords: itemKeywords,
        });
      }

      for (const group of groups) {
        const allItemsInGroup = this.getItems(group);
        const itemsInGroup = this.getValidItems(group);
        const groupId = group.getAttribute(GROUP_KEY_SELECTOR) ?? "";
        const allItemIds = new Set("");
        const itemIds = new Set("");

        for (const item of allItemsInGroup) {
          const itemKey = item.getAttribute(ITEM_KEY_SELECTOR) ?? "";
          allItemIds.add(itemKey);
        }
        for (const item of itemsInGroup) {
          const itemKey = item.getAttribute(ITEM_KEY_SELECTOR) ?? "";
          itemIds.add(itemKey);
        }

        this.allGroupIds.set(groupId, allItemIds);
        this.allValidGroupIds.set(groupId, itemIds);
      }
    },

    /** Setters */
    // index 번 째 아이템 선택
    updateSelectedToIndex(index) {
      const items = this.getValidItems();
      const item = items[index];

      if (item) {
        this.selectedNode = item.getAttribute(ITEM_KEY_SELECTOR);
      }
    },
    // 키보드를 통해 아이템 이동 하거나 change 만큼 이동
    updateSelectedByChange(change) {
      const selected = this.getSelectedItem();
      const items = this.getValidItems();
      const index = items.findIndex((item) => item === selected);

      const newSelected = items[index + change];

      if (newSelected) {
        this.selectedNode = newSelected.getAttribute(ITEM_KEY_SELECTOR);
        // 리스트 시작과 끝인 경우 처리
      } else {
        change > 0
          ? this.updateSelectedToIndex(0)
          : this.updateSelectedToIndex(items.length - 1);
      }
    },
    // alt 키를 통한 그룹 이동
    updateSelectedToGroup(change) {
      const selected = this.getSelectedItem();
      let group = selected?.closest(GROUP_SELECTOR);
      let item = null;

      // 인접한 그룹을 찾을 때까지 순회
      while (group && !item) {
        group =
          change > 0
            ? findNextSibling(group, GROUP_SELECTOR)
            : findPreviousSibling(group, GROUP_SELECTOR);
        item = group?.querySelector(VALID_ITEM_SELECTOR);
      }

      if (item) {
        this.selectedNode = item.getAttribute(ITEM_KEY_SELECTOR) || "";
      } else {
        // 더이상 그룹이 없는 경우 한 칸씩 순회
        this.updateSelectedByChange(change);
      }
    },
    // 아래 아이템으로 이동
    next(event) {
      event.preventDefault();
      if (event.metaKey) {
        this.last();
      } else if (event.altKey) {
        this.updateSelectedToGroup(1);
      } else {
        this.updateSelectedByChange(1);
      }
    },
    // 위 아이템으로 이동
    prev(event) {
      event.preventDefault();

      if (event.metaKey) {
        this.first();
      } else if (event.altKey) {
        this.updateSelectedToGroup(-1);
      } else {
        this.updateSelectedByChange(-1);
      }
    },
    // 리스트의 첫번째 아이템 선택
    first() {
      this.updateSelectedToIndex(0);
    },
    // 리스트의 마지막 아이템 선택
    last() {
      this.updateSelectedToIndex(this.getValidItems().length - 1);
    },
    focusInput() {
      this.focusableElements[0]?.focus();
    },
    // 키보드 입력 핸들러
    handleKeyDown(e) {
      if (e.isComposing) {
        return;
      }

      switch (e.key) {
        // for VIM
        case "n":
        case "j": {
          if (e.ctrlKey) {
            this.focusInput();
            this.next(e);
          }
          break;
        }
        case "ArrowDown": {
          this.focusInput();
          this.next(e);
          break;
        }
        case "p":
        case "k": {
          // for VIM
          if (e.ctrlKey) {
            this.focusInput();
            this.prev(e);
          }
          break;
        }
        case "ArrowUp": {
          this.focusInput();
          this.prev(e);
          break;
        }
        case "Home": {
          this.focusInput();
          this.first();
          break;
        }
        case "End": {
          this.focusInput();
          this.last();
          break;
        }
        case "Enter": {
          // 인풋 외 엘리먼트에 포커스가 있는 경우 실행하지 않음
          if (
            document.activeElement !==
            this.$store.state.commandK.focusableElements[0]
          ) {
            return;
          }

          const item = this.getSelectedItem();
          if (item && item.getAttribute(ATTR_DISABLED) !== "true") {
            // custom event 호출하여 Item component 내부 메서드 실행
            const event = new Event(EVENT_SELECT_ITEM);
            item.dispatchEvent(event);
          }
        }
      }
    },

    /** Getters */
    // 현재 선택된 Item DOM Node 반환
    getSelectedItem() {
      return this.commandKRef?.querySelector(SELECTED_ITEM_SELECTOR);
    },
    // 검색 시 모든 아이템 반환
    getAllItems(rootNode = this.commandKRef) {
      const allItemEl = rootNode?.querySelectorAll(ITEM_SELECTOR);
      return allItemEl ? Array.from(allItemEl) : [];
    },
    // 검색 시 숨겨지지 않은 Item DOM Node 반환
    getValidItems(rootNode = this.commandKRef) {
      const allItemEl = rootNode?.querySelectorAll(VALID_ITEM_SELECTOR);
      return allItemEl ? Array.from(allItemEl) : [];
    },
    // 검색 시 숨겨지지 않은 Group DOM Node 반환
    getValidGroups() {
      const allGroupEl = this.commandKRef.querySelectorAll(GROUP_SELECTOR);
      return allGroupEl ? Array.from(allGroupEl) : [];
    },
    /**
     * rootNode 내부의 모든 Item DOM Node 반환
     * @param rootNode
     * @returns {*[]|[]}
     */
    getItems(rootNode = this.commandKRef) {
      const allItemEl = rootNode?.querySelectorAll(ITEM_SELECTOR);
      return allItemEl ? Array.from(allItemEl) : [];
    },
    // 숨겨지지 않은 모든 아이템 중, 첫 번째 아이템을 자동 선택
    selectedFirstItem() {
      const [firstItem] = this.getValidItems();
      if (firstItem?.getAttribute(ITEM_KEY_SELECTOR)) {
        this.selectedNode = firstItem.getAttribute(ITEM_KEY_SELECTOR);
      }
    },
  },
};
</script>
