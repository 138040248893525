<template>
  <a
    :href="href"
    class="anchor"
    @keyup.enter="(ev) => $emit('click', ev)"
    @keydown.enter.stop.prevent
    @click="(ev) => $emit('click', ev)"
  >
    <slot />
  </a>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Anchor",
  inheritAttrs: true,
  props: {
    href: {
      type: String,
      default: "javascript: void(0)",
    },
  },
};
</script>

<style lang="scss" scoped>
.anchor {
  color: $--link-color;
}
</style>
