<template>
  <el-dialog
    :title="lang('재입사자_조회')"
    :width="width"
    :modal-append-to-body="false"
    visible
    @close="$emit('close')"
  >
    <div class="reentry-find">
      <el-table-extend
        :loading="pending"
        :data.sync="gridData"
        :show-top-bar="true"
        :show-pagination="false"
        row-key="employeeId"
        highlight-current-row
        @page-change="handlePageChange"
      >
        <el-table-column-extend
          v-for="column in columns"
          :key="column.prop"
          :prop="
            column.displayProp !== undefined ? column.displayProp : column.prop
          "
          :label="column.label"
          :type="column.type"
          :width="column.width"
          :code="column.code"
          :align="column.align"
          :formatter="column.formatter"
          :date-format="column.dateFormat"
          :true-label="column.trueLabel"
          :false-label="column.falseLabel"
          :required="column.required"
          :validator="column.validator"
          :on-invalid="column.onInvalid"
          :use-template="column.useTemplate"
          header-align="left"
        >
          <template v-if="column.prop === 'employeeName'" slot-scope="{ row }">
            <anchor @click="handleRowClick(row)">
              {{ row.employeeName }}
            </anchor>
          </template>
        </el-table-column-extend>
      </el-table-extend>
    </div>
  </el-dialog>
</template>

<script>
import helpers from "@/auth/helpers";
import moment from "moment";
// import validator from '@/lib/validator';
import { i18nTranslator } from "@/mixins/i18n";
import { mapListToFormat } from "@/lib/util";
import { DATE_FORMAT } from "@/config/rule";

export default {
  name: "HAS1060",
  mixins: [i18nTranslator("HAS1060")],
  props: {
    width: {
      type: String,
      default: "1000px",
    },
    // eslint-disable-next-line
    searchRegNum: {
      type: String,
    },
    // eslint-disable-next-line
    searchHireYmd: {
      type: Date,
    },
  },
  data() {
    const lang = this.lang;

    return {
      pending: false,
      DATE_FORMAT: DATE_FORMAT,
      gridData: [],
      columns: [
        {
          label: lang("그리드.회사"),
          prop: "companyOriginalIdName",
        },
        {
          label: lang("그리드.사번"),
          prop: "employeeNumber",
        },
        {
          label: lang("그리드.성명"),
          prop: "employeeName",
          useTemplate: true,
        },
        {
          label: lang("그리드.입사일"),
          prop: "hireYmd",
          dateFormat: DATE_FORMAT["longDate2"],
          formatter: (row) => {
            return this.$util.dateFormat(row.hireYmd);
          },
        },
        {
          label: lang("그리드.퇴사일"),
          prop: "retireYmd",
          dateFormat: DATE_FORMAT["longDate2"],
          formatter: (row) => {
            return this.$util.dateFormat(row.retireYmd);
          },
        },
        {
          label: lang("그리드.퇴직사유"),
          prop: "retireCodeName",
        },
        {
          label: lang("그리드.그룹웨어ID"),
          prop: "groupwareId",
          width: 180,
        },
      ],
      // pagination: {
      //   page: 1,
      //   count: 0,
      //   limit: 10,
      // },
    };
  },
  computed: {},
  watch: {
    // option
  },
  /**
   * document.ready()
   */
  mounted() {
    this.fetch();
  },
  methods: {
    async fetch() {
      if (!this.searchRegNum || this.pending) {
        this.gridData = [];
        return;
      }

      this.pending = true;

      try {
        const {
          data: { payload },
        } = await helpers.get(`/assignments/entrada`, {
          registrationNumber: this.searchRegNum,
        });
        this.gridData = mapListToFormat(payload, ["hireYmd", "retireYmd"]);
      } finally {
        this.pending = false;
      }
    },
    handleRowClick(row) {
      if (!row.retireYmd) {
        // 해당 데이터가 재직중인 데이터일 경우 선택 불가.
        this.$message({
          type: "error",
          message: this.lang("메세지.재직자선택불가"),
        });
        return;
      }

      if (!this.searchHireYmd) {
        this.$message({
          type: "error",
          message: this.lang("메세지.입사일_입력필요"),
        });
        return;
      }

      if (moment(row.retireYmd).isSameOrAfter(this.searchHireYmd)) {
        this.$message({
          type: "error",
          message: this.lang("메세지.퇴직자선택불가_입사일오류"),
        });
        return;
      }

      this.$emit("close", row);
    },
    // handlePageChange(paginationObj) {
    //   Object.assign(this.pagination, paginationObj);
    //   this.fetch();
    // },
  },
};
</script>

<style lang="scss" scoped>
.reentry-find {
  padding: 24px;
}
</style>
