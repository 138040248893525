import * as hangul from "hangul-js";
import { hubBaseHost, eaBaseHost, kanbanBaseHost } from "@/config/cloudEnv";
import i18n from "@/i18n";
import Vue from "vue";

export const eventBus = new Vue();

/**
 * Helpers For Command K.
 */
export function findNextSibling(el, selector) {
  let sibling = el.nextElementSibling;

  while (sibling) {
    if (sibling.matches(selector)) {
      return sibling;
    }

    sibling = sibling.nextElementSibling;
  }
}

export function findPreviousSibling(el, selector) {
  let sibling = el.previousElementSibling;

  while (sibling) {
    if (sibling.matches(selector)) {
      return sibling;
    }

    sibling = sibling.previousElementSibling;
  }
}

export function makeHostPath(system, path) {
  let host = "";
  switch (system) {
    case COMMAND_K.ITEM_TYPE_GW: {
      host = hubBaseHost();
      break;
    }
    case COMMAND_K.ITEM_TYPE_EA:
    case COMMAND_K.ITEM_TYPE_FN: {
      host = eaBaseHost();
      break;
    }
    case COMMAND_K.ITEM_TYPE_HR: {
      break;
    }
    case COMMAND_K.ITEM_TYPE_KANBAN: {
      host = kanbanBaseHost();
      break;
    }
  }
  return `${host}${path}`;
}

export function makeItemIcon(type) {
  switch (type) {
    case ITEM_TYPE_DRAFT: {
      return "mdi:file-sign";
    }
    case ITEM_TYPE_GW: {
      return "material-symbols:menu-open-rounded";
    }
    case ITEM_TYPE_COMMUNITY: {
      return "icon-park-twotone:paragraph-alphabet";
    }
    case ITEM_TYPE_HR: {
      return "material-symbols:menu-open-rounded";
    }
    case ITEM_TYPE_EA: {
      return "material-symbols:menu-open-rounded";
    }
    case ITEM_TYPE_FN: {
      return "material-symbols:menu-open-rounded";
    }
    case ITEM_TYPE_KANBAN: {
      return "material-symbols:menu-open-rounded";
    }
  }
}
// 초성 검색 키워드 생성
export function makeKeywords(item, target) {
  const firstWord = hangul
    .d(item[target], true)
    .map((item) => item[0])
    .join("");

  return [firstWord, ...(item.keywords ?? [])];
}

// 메뉴 타입 파인더
export function makeItemType(item) {
  if (item.system !== ITEM_TYPE_EA) {
    return item.system;
  }

  const draftSeparator = "/draft/";
  if (item.urlPath.includes(draftSeparator)) {
    return ITEM_TYPE_DRAFT;
  }
  return ITEM_TYPE_EA;
}

export function handleFocusTrap(event, focusableElements) {
  const firstElement = focusableElements[0];
  const lastElement = focusableElements[focusableElements.length - 1];

  if (!firstElement || !lastElement) {
    return;
  }

  if (!focusableElements.includes(document.activeElement)) {
    event.preventDefault();
    firstElement.focus();
  }

  if (event.shiftKey) {
    if (document.activeElement === firstElement) {
      event.preventDefault();
      lastElement.focus();
    }
  } else {
    if (document.activeElement === lastElement) {
      event.preventDefault();
      firstElement.focus();
    }
  }
}

export function makeGroupTitle(item) {
  switch (item.system) {
    case ITEM_TYPE_HR: {
      return i18n.t("커맨드케이.리스트.해딩.인사");
    }
    case ITEM_TYPE_GW: {
      return i18n.t("커맨드케이.리스트.해딩.소통");
    }
    case ITEM_TYPE_EA: {
      if (item.urlPath.includes("/draft/")) {
        return i18n.t("커맨드케이.리스트.해딩.업무양식");
      } else {
        return i18n.t("커맨드케이.리스트.해딩.결재");
      }
    }
    case ITEM_TYPE_FN: {
      return i18n.t("커맨드케이.리스트.해딩.재무");
    }
    case ITEM_TYPE_KANBAN: {
      return i18n.t("커맨드케이.리스트.해딩.협업");
    }
  }
}

/**
 * Constants for Command K.
 */
const PREFIX = "command-k";
const ATTR_LABEL = `${PREFIX}-data-label`;
const ATTR_KEYWORDS = `${PREFIX}-data-keywords`;
const ATTR_DISABLED = `aria-disabled`;
const ITEM_KEY_SELECTOR = `${PREFIX}-item-key`;
const GROUP_KEY_SELECTOR = `${PREFIX}-group-key`;
const GROUP_SELECTOR = `[${PREFIX}-group=""]`;
const GROUP_HEADING_SELECTOR = `[${PREFIX}-group-heading=""]`;
const ITEM_SELECTOR = `[${PREFIX}-item=""]`;
const VALID_ITEM_SELECTOR = `${ITEM_SELECTOR}:not([aria-disabled="true"])`;
const SELECTED_ITEM_SELECTOR = `${ITEM_SELECTOR}[aria-selected="true"]`;

const EVENT_SELECT_ITEM = `${PREFIX}-item-select`;
const EVENT_RERENDER_LIST = `${PREFIX}-rerender-list`;
const EVENT_SELECT_LIST_ITEM = `${PREFIX}-select-list-item`;

const ITEM_TYPE_USER = "USER";
const ITEM_TYPE_GW = "GW";
const ITEM_TYPE_EA = "EA";
const ITEM_TYPE_HR = "HR";
const ITEM_TYPE_FN = "FN";
const ITEM_TYPE_COMMUNITY = "COMMUNITY";
const ITEM_TYPE_KANBAN = "KANBAN";
const ITEM_TYPE_MENU = "MENU";
const ITEM_TYPE_DRAFT = "DRAFT";
const ITEM_MENU_TYPE_CODE_EXTERNAL = "EXTERNAL";
const ITEM_TYPE_SEARCH = "search";

const FOCUS_TRAP_SELECTOR = `input, a[role='link']`;

export const COMMAND_K = {
  ATTR_LABEL,
  ATTR_KEYWORDS,
  ATTR_DISABLED,
  ITEM_KEY_SELECTOR,
  GROUP_KEY_SELECTOR,
  ITEM_SELECTOR,
  GROUP_SELECTOR,
  GROUP_HEADING_SELECTOR,
  VALID_ITEM_SELECTOR,
  SELECTED_ITEM_SELECTOR,
  EVENT_SELECT_ITEM,
  EVENT_RERENDER_LIST,
  EVENT_SELECT_LIST_ITEM,
  ITEM_TYPE_USER,
  ITEM_TYPE_GW,
  ITEM_TYPE_EA,
  ITEM_TYPE_HR,
  ITEM_TYPE_FN,
  ITEM_TYPE_KANBAN,
  ITEM_TYPE_MENU,
  ITEM_TYPE_DRAFT,
  ITEM_TYPE_SEARCH,
  ITEM_TYPE_COMMUNITY,
  ITEM_MENU_TYPE_CODE_EXTERNAL,
  FOCUS_TRAP_SELECTOR,
};
