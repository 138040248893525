<template>
  <el-select
    ref="select"
    v-model="model"
    size="small"
    :disabled="disabled"
    :clearable="clearable"
    :placeholder="placeholder"
    @change="change"
  >
    <el-option
      v-for="(item, itemIndex) in items"
      :key="itemIndex"
      :value="typeof item === 'object' ? item[valueKey] : item"
      :label="typeof item === 'object' ? item[labelKey] : item"
    />
  </el-select>
</template>

<script>
import { CommonAPI } from "@/services/api";
import { i18nTranslator } from "@/mixins/i18n";
// eslint-disable-next-line no-unused-vars
function noop() {}

export default {
  name: "CodeSelect",
  mixins: [i18nTranslator(null)],
  props: {
    // eslint-disable-next-line  vue/require-default-prop
    code: String,
    // eslint-disable-next-line vue/require-default-prop
    placeholder: String,
    disabled: Boolean,
    // eslint-disable-next-line vue/require-default-prop

    // eslint-disable-next-line vue/require-prop-type-constructor,vue/require-default-prop
    autofocus: false,
    clearable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    inputItems: {
      type: Array,
      default() {
        return [];
      },
    },
    refresh: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // eslint-disable-next-line vue/require-default-prop
    value: {
      type: [String, Number],
    },
    labelKey: {
      type: String,
      default: "meaning",
    },
    valueKey: {
      type: String,
      default: "lookupValueId",
    },
    // 추가 전체
    all: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    // eslint-disable-next-line no-unused-vars
    const model = this.value;
    return {
      model: this.value,
      items: this.inputItems,
    };
  },
  watch: {
    inputItems: {
      handler(v) {
        if (this.refresh) {
          this.items = v;
        }
      },
      deep: true,
    },
    value(val) {
      this.model = val;
    },
  },
  /**
   * @desc LOOKUP CODE (공통코드 조회)
   */
  async mounted() {
    if (this.code) {
      const response = await CommonAPI.getCodes({
        lookupType: this.code,
      });
      this.items = this.items.concat(response.data.payload || []);

      // 전체가 필요할 경우 추가
      if (this.all) {
        let jsonObj = {};
        jsonObj[this.labelKey] = this.lang("common.폼.전체");
        jsonObj[this.valueKey] = null;

        this.items.unshift(jsonObj);
      }
    }

    if (this.autofocus) {
      this.$refs.select.focus();
    }
  },
  methods: {
    focus() {
      this.$refs.select.focus();
    },
    change(val) {
      this.$emit(
        "change",
        val,
        this.items.find((item) => item[this.valueKey] === val),
      );
    },
  },
};
</script>
