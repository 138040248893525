import helpers from "@/auth/helpers";

export default {
  getCodes(param) {
    return helpers.get("/common/lookup-values/codes", param);
  },
  getSequence(param) {
    return helpers.get("/com/sequence", param);
  },
  /**
   * 클라우드 결재 정보 가져오기
   * @param param
   */
  getCloudInfo() {
    return helpers.get("/cloud/info/");
  },
};
