const eaSystemDomain = "wf";
const hrSystemDomain = "hr";
const mobileFrontDomain = "m";
const apiServerDomain = "api";
const enableCloudConfig =
  typeof process.env.VUE_APP_ENABLE_CLOUD_CONFIG === "string"
    ? "true".toLowerCase() ===
      process.env.VUE_APP_ENABLE_CLOUD_CONFIG.toLowerCase()
    : !!process.env.VUE_APP_ENABLE_CLOUD_CONFIG;

function getCompanyName() {
  return window.location.host.split(".")[0];
}

function getBaseDomain(host) {
  const splitted = host.split(".");
  if (splitted.length === 3) {
    return host.split(".").slice(1).join(".");
  }
  return host.split(".").slice(2).join(".");
}

function eaBaseHost() {
  if (!enableCloudConfig) {
    return process.env.VUE_APP_EA_BASE_HOST;
  }
  const { protocol, host } = location;
  return `${protocol}//${getCompanyName(
    host,
  )}.${eaSystemDomain}.${getBaseDomain(host)}`;
}

function hubBaseHost() {
  if (!enableCloudConfig) {
    return process.env.VUE_APP_HUB_BASE_HOST;
  }
  const { protocol, host } = location;
  return `${protocol}//${getCompanyName(host)}.${getBaseDomain(host)}`;
}

function hrBaseHost() {
  if (!enableCloudConfig) {
    return process.env.VUE_APP_HR_BASE_HOST;
  }
  const { protocol, host } = location;
  return `${protocol}//${getCompanyName(
    host,
  )}.${hrSystemDomain}.${getBaseDomain(host)}`;
}

function kanbanBaseHost() {
  if (!enableCloudConfig) {
    return process.env.VUE_APP_KANBAN_BASE_HOST;
  }
  const { protocol, host } = location;
  return `${protocol}//${getCompanyName(host)}.${getBaseDomain(host)}`;
}

function peBaseHost() {
  if (!enableCloudConfig) {
    return process.env.VUE_APP_PE_BASE_HOST;
  }
  const { protocol, host } = location;
  return `${protocol}//${getCompanyName(host)}.${getBaseDomain(host)}`;
}

function payrollBaseHost() {
  if (!enableCloudConfig) {
    return process.env.VUE_APP_PAYROLL_BASE_HOST;
  }
  const { protocol, host } = location;
  return `${protocol}//${getCompanyName(host)}.${getBaseDomain(host)}`;
}

function apiBaseUrl() {
  if (!enableCloudConfig) {
    return process.env.VUE_APP_API_BASE_URL;
  }
  const { protocol, host } = location;
  return `${protocol}//${getCompanyName(
    host,
  )}.${hrSystemDomain}.${apiServerDomain}.${getBaseDomain(host)}/v1`;
}

function redirectUrl() {
  if (!enableCloudConfig) {
    return process.env.VUE_APP_REDIRECT_URI;
  }
  return `${hrBaseHost()}`;
}

function mobileUrl() {
  if (!enableCloudConfig) {
    return process.env.VUE_APP_MOBILE_URL;
  }
  const { protocol, host } = location;
  return `${protocol}//${getCompanyName(
    host,
  )}.${mobileFrontDomain}.${getBaseDomain(host)}`;
}

function photoUrl(employeeId) {
  return employeeId
    ? `${apiBaseUrl()}/common/public/user-photo/default/${employeeId}`
    : "";
}

function draftUrl(draftId) {
  return `${eaBaseHost()}/draft/view/${draftId}`;
}

export {
  eaBaseHost,
  hubBaseHost,
  hrBaseHost,
  redirectUrl,
  mobileUrl,
  apiBaseUrl,
  photoUrl,
  draftUrl,
  kanbanBaseHost,
  peBaseHost,
  payrollBaseHost,
};
