<template>
  <footer class="command-k-footer">
    <ul class="command-k-footer__commands">
      <li>
        <kbd class="command-k-footer__command-key">
          <span class="command-k-footer__icon-wrapper">
            <iconify-icon
              class="command-key__icon"
              icon="mdi:keyboard-return"
            />
          </span>
          <span>{{ $t("커맨드케이.푸터.텍스트.선택") }}</span>
        </kbd>
      </li>

      <li>
        <kbd class="command-k-footer__command-key">
          <span class="command-k-footer__icon-wrapper">
            <iconify-icon class="command-key__icon" icon="mdi:arrow-up" />
          </span>

          <span class="command-k-footer__icon-wrapper">
            <iconify-icon class="command-key__icon" icon="mdi:arrow-down" />
          </span>

          <span>{{ $t("커맨드케이.푸터.텍스트.이동") }}</span>
        </kbd>
      </li>
      <li>
        <kbd class="command-k-footer__command-key">
          <span class="command-k-footer__icon-wrapper">
            <iconify-icon class="command-key__icon" icon="mdi:keyboard-esc" />
          </span>

          <span>{{ $t("커맨드케이.푸터.텍스트.닫기") }}</span>
        </kbd>
      </li>
    </ul>

    <a
      :href="'/'"
      class="command-k-footer__logo"
      @click.stop.prevent="returnMain('/')"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="17"
        viewBox="0 0 512 356"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M178.455 86.6717C127.892 86.6717 86.8973 127.56 86.8973 177.992C86.8973 228.424 127.892 269.313 178.455 269.313H287.965V221.704H190.008C186.116 221.704 182.947 218.559 182.947 214.662V142.059C182.947 138.178 186.101 135.017 190.008 135.017H328.884C354.281 135.017 374.878 155.561 374.878 180.892V348.942C374.878 352.824 371.725 355.985 367.817 355.985H178.455C79.8981 356 0 276.309 0 177.992C0 79.6754 79.8981 0 178.455 0H350.004C440.239 0 511.985 72.9706 511.985 162.972V214.201C511.985 218.083 508.831 221.244 504.924 221.244H432.133C428.241 221.244 425.072 218.099 425.072 214.201V162.972C425.072 120.84 392.245 86.6871 350.004 86.6871H178.455V86.6717Z"
          fill="black"
        />
      </svg>
    </a>
  </footer>
</template>

<script>
export default {
  name: "CommandKFooter",
  methods: {
    returnMain(linkUrl) {
      this.$store.commit("commandK/setVisible", false);
      this.$router.push({ path: linkUrl });
    },
  },
};
</script>
