<template>
  <el-dialog
    :modal-append-to-body="false"
    :title="title"
    :width="width"
    visible
    @close="$emit('close')"
  >
    <div class="modal-find template template-flex">
      <panel :add-header="false" class="portlet-first">
        <el-form :model="searchParams" class="search-form">
          <el-row :gutter="8">
            <el-col :span="8">
              <el-form-item :required="true" prop="effectiveDate">
                <span slot="label">{{ lang("폼.기준일") }}</span>

                <el-date-picker
                  v-if="assignmentTime"
                  v-model="
                    /* eslint-disable vue/no-mutating-props */ assignmentTime
                  "
                  :placeholder="lang('폼.기준일')"
                  :default-value="$util.dateFormat(new Date(), 'longDate')"
                  :clearable="false"
                  v-bind="datePicker"
                />

                <el-date-picker
                  v-else
                  v-model="searchParams.effectiveDate"
                  :placeholder="lang('폼.기준일')"
                  :default-value="$util.dateFormat(new Date(), 'longDate')"
                  :clearable="false"
                  v-bind="datePicker"
                />
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item>
                <span slot="label">{{ lang("폼.조직코드/명") }}</span>

                <el-input
                  v-model="searchParams.keyword"
                  v-focus
                  size="small"
                  @keypress.enter.native="enterFilter"
                />
              </el-form-item>
            </el-col>

            <el-col :span="8">
              <el-form-item>
                <div class="form-list-tab">
                  <el-button
                    slot="button"
                    :disabled="pending"
                    :class="{ active: treeOrListTab === 'TREE' }"
                    size="small"
                    @click="changeType('TREE')"
                  >
                    <iconify-icon
                      icon="material-symbols:account-tree-outline-rounded"
                    />
                  </el-button>

                  <el-button
                    slot="button"
                    :disabled="pending"
                    :class="{ active: treeOrListTab === 'LIST' }"
                    size="small"
                    @click="changeType('LIST')"
                  >
                    <iconify-icon
                      icon="material-symbols:format-list-bulleted-rounded"
                    />
                  </el-button>
                </div>

                <el-button
                  type="primary"
                  size="small"
                  style="margin-left: 8px"
                  @click="handleSearch"
                  >{{ lang("버튼.조회") }}
                </el-button>
                <router-link
                  class="el-button el-button--default el-button--small"
                  :to="'/HOG1000'"
                  >{{ lang("버튼.신규조직추가") }}</router-link
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </panel>

      <div class="treeOrListDiv">
        <new-tree
          v-if="treeOrListTab === 'TREE'"
          ref="tree"
          :key="treeKey"
          :pending="pending"
          :tree-list="treeList"
          :org-select="orgSelect"
          :filter-text="filterText"
          :reset-tree-list="resetTreeList"
        />
        <div v-if="treeOrListTab === 'LIST'">
          <panel :inner-padding="false" :add-header="false">
            <el-table-extend
              :max-height="getHeight"
              :data.sync="listForView"
              :pending="pending"
              :show-pagination="false"
              :labels="columns"
              :editable="false"
              :show-top-bar="false"
              :height="630"
              highlight-current-row
              program-code="HOG1060"
              row-key="rnum"
            >
              <el-table-column-extend
                v-for="column in columns"
                :key="column.prop"
                :prop="column.prop"
                :label="column.label"
                :type="column.type"
                :width="column.width"
                :align="column.align"
                :required="column.required"
                :use-template="column.useTemplate"
                :show-overflow-tooltip="true"
                header-align="left"
              >
                <template
                  slot-scope="/* eslint-disable vue/no-unused-vars */ {
                    row,
                    update,
                    current,
                  }"
                >
                  <template v-if="column.prop === 'checkboxSelect'">
                    <el-checkbox
                      v-focus
                      :value="row[column.prop]"
                      @change="(val) => selectedRow(val, row, update)"
                    />
                  </template>
                  <template v-if="column.prop === 'organizationName'">
                    <anchor @click="orgSelect(row)"
                      >{{ row[column.prop] }}
                    </anchor>
                  </template>
                </template>
              </el-table-column-extend>
            </el-table-extend>
          </panel>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import helpers from "@/auth/helpers";
import { i18nTranslator } from "@/mixins/i18n";
import { authEventUtil } from "@/mixins/auth";
import Panel from "@/components/portlets/Panel";
import NewTree from "@/components/new-tree/NewTree";
import mapToTreeModel from "@/lib/map-tree";
import { datePicker } from "@/lib/element-base-options";
import debounce from "lodash/debounce";

export default {
  name: "HOG1060",
  components: {
    Panel,
    NewTree,
  },
  mixins: [i18nTranslator("HOG1060"), authEventUtil("HOG1060")],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    assignmentTime: {
      type: String,
    },
    title: {
      type: String,
      default() {
        return this.$tc("HOG1060.조직검색");
      },
    },
    withoutCurrentCompanyOriginalId: {
      type: String,
      default: null,
    },
    width: {
      type: String,
      default: "1000px",
    },
    value: {
      type: String,
      default: null,
    },
    // eslint-disable-next-line vue/require-default-prop
    adminAuth: {
      type: String,
    },
    // eslint-disable-next-line vue/require-default-prop
    date: {
      type: String,
    },
    companyOriginalId: {
      default: 0,
      type: Number,
    },
    dispatchedChangeYn: {
      type: String,
      default: "N",
    },
  },
  data() {
    const lang = this.lang;
    return {
      treeKey: 0,
      previousElement: null,
      pending: false,
      datePicker,
      treeOrListTab: "TREE",
      searchParams: {
        effectiveDate:
          this.date || this.$util.dateFormat(new Date(), "longDate"),
        keyword: this.value,
        adminAuth: this.admin,
        companyOriginalId: this.companyOriginalId,
      },
      treeList: [],
      list: [],
      filteredList: [],
      filterText: "",
      optionCompanyArr: [],
      columns: [
        {
          label: lang("그리드.No"),
          prop: "rnum",
          width: 40,
        },
        {
          label: lang("그리드.조직명"),
          prop: "organizationName",
          showOverflowTooltip: true,
          useTemplate: true,
          width: 150,
        },
        {
          label: lang("그리드.법인"),
          prop: "companyOriginalIdName",
          fixed: "left",
          showOverflowTooltip: true,
        },
        {
          label: lang("그리드.시작일"),
          prop: "startYmd",
          align: "center",
          showOverflowTooltip: true,
        },
        {
          label: lang("그리드.종료일"),
          prop: "endYmd",
          align: "center",
          showOverflowTooltip: true,
        },
        {
          label: lang("그리드.조직장"),
          prop: "supervisorName",
          align: "center",
          width: 60,
        },
      ],
    };
  },
  computed: {
    listForView() {
      if (
        this.searchParams.keyword !== null &&
        this.searchParams.keyword !== ""
      ) {
        return this.filteredList.map((el, index) => ({
          ...el,
          rnum: index + 1,
        }));
      }
      return this.list
        .filter(this.listFilter)
        .map((el, index) => ({ ...el, rnum: index + 1 }));
    },
    getHeight() {
      return this.$el.querySelector(".el-dialog").offsetHeight - 180;
    },
    companyCode() {
      if (this.companyOriginalId === 0) {
        return this.$store.getters["common/searchParams"].lookupValueId;
      }
      return this.companyOriginalId;
    },
    companyOriginalListOptions() {
      return this.$store.getters["common/searchParams"].companyListOptions;
    },
    noPermissionCompanyOriginal() {
      return this.$store.getters["user/headerAuth"][
        this.$store.getters["settings/currentTab"]
      ]
        ? this.$store.getters["user/headerAuth"][
            this.$store.getters["settings/currentTab"]
          ].corporationComboYn === "N"
        : "";
    },
  },
  watch: {
    treeList(val, oldVal) {
      if (val !== oldVal) {
        this.filterText = null;
      }
    },
    filterText: debounce(function (val) {
      if (!val) {
        this.resetTreeList();
        this.filteredList = [];
        // eslint-disable-next-line
        return;
      } else {
        if (this.treeOrListTab === "TREE") {
          // this.$refs.tree.$refs.tree.filter(val);
        } else {
          // 비교를 위해 소문자로 변경 후 비교
          this.filteredList = this.list.filter((el) => {
            if (el.organizationName) {
              return el.organizationName
                .toLowerCase()
                .includes(val.toLowerCase());
            }
          });
        }
      }
    }, 50),
  },

  created() {
    this.previousElement = document.activeElement;
  },

  destroyed() {
    this.previousElement && this.previousElement.focus();
  },

  async mounted() {
    // 팝업 오픈시 오른쪽 상단에 있는 법인 상태에 따라서 리스트 filter 변경
    if (this.noPermissionCompanyOriginal || this.companyCode === null) {
      // 메뉴에 법인 권한이 없거나 전체가 선택되어 있을 때
      this.optionCompanyArr = this.companyOriginalListOptions
        .filter((option) => option.companyId)
        .map((option) => option.disabled === false && option.companyId);
      this.listFilter = this.allSelectFilterListCompanyOriginal;
    } else {
      // 법인이 선택되어 있을 때
      this.listFilter = this.oneSelectFilterListCompanyOriginal;
    }
    if (this.searchParams.keyword) {
      this.searchParams.keyword = this.searchParams.keyword.trim();
    }
    this.handleSearch();
  },
  methods: {
    async handleSearch() {
      if (!this.searchParams.effectiveDate) {
        return this.$message({
          type: "warning",
          message: this.lang("메세지.기준일필수"),
        });
      }

      this.pending = true;

      const searchParams = this.searchParams;
      let effectiveDate;

      if (this.assignmentTime) {
        effectiveDate = this.assignmentTime.split("-").join("");
      } else if (typeof searchParams.effectiveDate === "string") {
        effectiveDate = searchParams.effectiveDate.split("-").join("");
      } else if (typeof searchParams.effectiveDate === "object") {
        effectiveDate = this.$util.dateFormat(
          searchParams.effectiveDate,
          "longDateValue",
        );
      }
      try {
        const {
          data: { payload },
        } = await helpers.get("/organization/history", {
          effectiveDate,
        });
        this.filterText = "";
        if (!payload) {
          this.treeList = [];
          this.list = [];
          this.pending = false;
          document.querySelector(".focus-target input");
          return;
        }

        this.list = [...payload];
        const rootId = payload.filter(({ superOrganizationId }) => {
          return superOrganizationId === null;
        })[0].superOrganizationId;
        this.treeList = await mapToTreeModel({
          arrayList: payload || [],
          rootId,
          parentIdName: "superOrganizationId",
          idName: "organizationId",
        });

        this.treeList[0] = { ...this.treeList[0], isOpen: true };

        if (this.searchParams.organizationId) {
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey(this.searchParams.organizationId);
          });
        }
        this.changeType(this.treeOrListTab);
      } finally {
        this.pending = false;
      }
    },
    // 현재 treeOrListTab, TREE 인지 LIST 인지
    changeType(value) {
      this.treeOrListTab = value;
      this.filterText = "";

      let height = "auto";
      if (value === "TREE") {
        this.treeKey++;
        height = `${this.getHeight + 30}px`;
      } else {
        // 스크롤이 맨아래로 이동해서 올리는 로직 추가
        setTimeout(() => {
          this.$el.querySelector(".el-table__body-wrapper").scrollTop = 0;
        }, 500);
      }
      this.$el.querySelector(".treeOrListDiv").style.height = height;

      // 조회 목록 필터. tree와 list를 change 할 때 조회 text를 반영하도록 추가.
      setTimeout(() => {
        this.filterText = this.searchParams.keyword;
      }, 350);
    },
    filterNode(value, treeList) {
      if (!value) return true;
      return treeList.organizationName.includes(value);
    },
    resetTreeList() {
      this.treeList = this.treeList.slice(0);
    },
    async orgSelect(data) {
      const arr = this.list
        .filter(this.listFilter)
        .filter(({ organizationId }) => organizationId === data.organizationId);
      if (arr.length) {
        if (
          data.organizationTypeCodeName === "ROOT" &&
          this.dispatchedChangeYn === "Y"
        ) {
          this.$message({
            type: "info",
            message: this.lang("메세지.선택불가"),
          });

          return;
        }
        this.selectOrganization(data);
      } else {
        this.$message({
          type: "info",
          message: this.lang("메세지.선택불가"),
        });
      }
    },
    selectedRow(value, row, update) {
      update(value);
      this.selectOrganization(row);
    },
    selectOrganization(row) {
      this.$emit("change", row);
    },
    enterFilter(event) {
      this.filterText = event.target.value.trim();
      this.searchParams.keyword = event.target.value.trim();
    },
    listSelectRow(row) {
      this.selectOrganization(row);
    },
    oneSelectFilterListCompanyOriginal(row) {
      if (
        ["304", "501", "502"].includes(this.withoutCurrentCompanyOriginalId)
      ) {
        if (
          this.withoutCurrentCompanyOriginalId === "501" ||
          this.withoutCurrentCompanyOriginalId === "502"
        ) {
          return row;
        } else if (this.withoutCurrentCompanyOriginalId === "304") {
          if (
            row.organizationTypeCodeName !== "ROOT" &&
            row.companyOriginalId !== this.companyCode
          ) {
            return row;
          }
        }
      } else {
        if (
          row.organizationTypeCodeName === "ROOT" ||
          row.companyOriginalId === this.companyCode
        ) {
          return row;
        }
      }
    },
    allSelectFilterListCompanyOriginal(row) {
      if (
        row.organizationTypeCodeName === "ROOT" ||
        this.optionCompanyArr.indexOf(row.companyOriginalId) > -1
      ) {
        return row;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-find {
  padding: 16px;
}

.treeOrListDiv {
  overflow: hidden;
  height: 472px;

  & ::v-deep article.portlet {
    height: 100%;

    & div.portlet-body {
      height: 100%;
      overflow: auto;
    }
  }

  & ::v-deep .vue-tree-focus {
    background: rgba(175, 175, 175, 0.5);
  }
}
</style>
