import Vue from "vue";
import store from "./store";
import { sync } from "vuex-router-sync";
import { http, router } from "./http";
import auth from "./auth";
import App from "./App.vue";
import i18n from "./i18n";
import plugins from "./plugins";
import directives from "./directives";
import lib from "./lib";
import element from "./plugins/element";
import * as VueGoogleMaps from "vue2-google-maps";
// import * as Sentry from "@sentry/vue";
// import { Integrations } from "@sentry/tracing";

if (!window.innerHeight) {
  const body = document.getElementsByTagName("body")[0];
  window.innerHeight =
    document.documentElement.clientHeight || body.clientHeight;
}

Vue.config.productionTip = false;
Vue.config.devTools = false;
Vue.config.silent = true;

Vue.use(http);
Vue.use(auth);
Vue.use(plugins);
Vue.use(directives);
Vue.use(lib);
sync(store, router);

Vue.use(element, {
  i18n: (key, value) => i18n.t(key, value),
});

// if (process.env.NODE_ENV === "production") {
//   Sentry.init({
//     Vue,
//     dsn: process.env.VUE_APP_SENTRY_DSN,
//     integrations: [new Integrations.BrowserTracing()],
//     tracesSampleRate: 1,
//     logErrors: true,
//     environment: window.location.hostname
//       .replace(".hr.groupware.pro", "")
//       .replace(".groupware.pro", "")
//       .replace("hr.", ""),
//   });
// }
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCweruIyW3iGcMLgpgJfuK88e_4uZNtWTA", // 여기서 YOUR_API_KEY를 실제 API 키로 교체하세요.
    libraries: "places", // 필요한 추가 라이브러리 설정
    async: true, // 비동기적으로 로드하도록 설정
    region: "KR",
  },
});

new Vue({
  el: "#app",
  router,
  store,
  i18n,
  render: (h) => h(App),
});

// if (process.env.NODE_ENV === "production") {
//   Vue.config.errorHandler = (err, vm, info) => {
//     console.error(vm, info);
//     Sentry.captureException(err);
//   };
//
//   window.addEventListener("error", (event) => {
//     Sentry.captureException(event);
//     throw event;
//   });
//   window.addEventListener("unhandledrejection", (event) => {
//     Sentry.captureException(event);
//   });
// }
