/**
 * @desc i18n function fallback
 * @usage
    data() {
      return { prefix: 'HPE1010' }
    }
    <span>{lang('사진등록')}</span>
    this.lang('사진등록')
    @return {string}
 */
export const i18nTranslator = function (programID) {
  return {
    methods: {
      lang() {
        let args = Array.from(arguments);
        if (args[0].substring(0, 4) === "msg.") {
          return this.$tc.apply(this, args);
        }
        if (programID) {
          args[0] = programID + "." + args[0];
        }
        return this.$tc.apply(this, args);
      },
    },
  };
};

export default {
  i18nTranslator,
};
