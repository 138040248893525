import store from "@/store";

/**
 * @desc 페이지 이탈시 변경된 데이터 체크 유틸리티
 * @param {string | number} uid page unique Id
 * @useage
   mixins: [ safe.mixin('UniquePageID', ['var1', 'var2', 'var3']) ]
 */

export default function (uid, dataVariableNames) {
  let watch = {};
  for (let k of dataVariableNames) {
    watch[k] = {
      deep: false,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          store.dispatch("safe/set", {
            uid,
            valueKey: k,
            value: newVal,
          });
        }
      },
    };
  }

  return {
    watch: watch,
    beforeDestroy() {
      store.dispatch("safe/reset", uid);
    },
    beforePageLeave(tab, type) {
      if (type === "close") {
        const programCode = tab.to.replace("/", "");
        return store
          .dispatch("safe/detect", { uid: programCode, instance: this })
          .then((_) => {
            console.log(_);
          });
      }
    },
  };
}
