import helpers from "@/auth/helpers";
export default {
  /**
   * 직무정보관리(JDM1000) 왼쪽 상단 연도 SELECT 데이터 가져오기
   */
  getJobHistoryYear() {
    return helpers.get("/job/jobHistory/year");
  },

  /**
   * 직무정보관리(JDM1000) 왼쪽 상단 변경일 SELECT 데이터 가져오기
   */
  getJobHistoryDate(year) {
    return helpers.get(`/job/jobHistory/${year}/date`);
  },

  /**
   * 직무정보관리(JDM1000) 왼쪽 직무 트리 데이터 가져오기
   */
  getHistory(param) {
    return helpers.get("/job/history", param);
  },

  /**
   * 직무관리 > 직무정의 > 변경이력 데이터 조회
   * @param jobId
   */
  getChangeHistory(jobId, param) {
    return helpers.get(`/job/${jobId}/changeHistory`, param);
  },

  /**
   * 직무관리 > 직무정의 > 변경이력 데이터 수정
   * @param jobId
   */
  putChangeHistory(jobId, data) {
    return helpers.put(`/job/${jobId}/changeHistory`, data);
  },

  /**
   * 직무관리 > 직무기술서 > 직무기술서 데이터 조회
   * @param jobId
   */
  getJobDescription(jobId, localeCode) {
    return helpers.get(`/jobSkill/${jobId}/summaryInfo`, { localeCode });
  },

  /**
   * 직무관리 > 직무기술서 > 직무기술서 데이터 수정
   *
   */
  putJobDescription(jobId, data) {
    return helpers.put(`/jobSkill/${jobId}/summaryInfo`, data);
  },

  /**
   * 직무기술서 언어별 전체반영
   * @param dataputJobDescAllLanguage
   * @returns {AxiosPromise<any> | * | IDBRequest<IDBValidKey> | Promise<void>}
   */

  putJobDescAllLanguage(jobId, summary) {
    return helpers.put(`/jobSkill/${jobId}/summaryInfo/allLanguages`, {
      jobId,
      summary,
    });
  },

  /**
   * 직무관리 > 직무기술서 > 주요업무책임 데이터 조회
   * @param jobId
   */
  getJobDescAssignedTasks(jobId, localeCode) {
    return helpers.get(`/jobSkill/${jobId}/assignedInfo`, { localeCode });
  },

  /**
   * 직무관리 > 직무기술서 > 주요업무책임 데이터 수정
   * {
   *   "assignedTask": "string",
   *   "displayOrder": 0,
   *   "importance": 0,
   *   "jobAssignedTaskId": 0,
   *   "jobId": 0,
   *   "localeCode": "string",
   *   "taskImportance": 0,
   *   "manipulationType": "C|U|D"
   * }
   *
   * @param jobId,
   */
  putJobDescAssignTasks(jobId, arrayParam) {
    return helpers.put(`/jobSkill/${jobId}/assignedInfo`, arrayParam);
  },

  /**
   * 주요업무책임 언어별 전체반영(등록, 수정 ,삭제)
   */
  putJobDescAssignTaskAllLanguage(jobId, data) {
    return helpers.put(`/jobSkill/${jobId}/assignedInfo/allLanguages`, data);
  },

  /**
   * 직무관리 > 직무기술서 > 주요업무환경 데이터 조회
   * @param jobId
   */
  getJobDescEnv(jobId, localeCode) {
    return helpers.get(`/jobSkill/${jobId}/environmentInfo`, { localeCode });
  },

  /**
   * 직무관리 > 직무기술서 > 주요업무환경 데이터 수정
   * @param jobId,
   */
  putJobDescEnv(jobId, arrayParam) {
    return helpers.put(`/jobSkill/${jobId}/environmentInfo`, arrayParam);
  },

  /**
   * 주요업무환경 언어별 전체반영(등록, 수정 ,삭제)
   */
  putJobDescEnvAllLanguage(jobId, data) {
    return helpers.put(`/jobSkill/${jobId}/environmentInfo/allLanguages`, data);
  },

  /**
   * 직무관리 > 직무기술서 > 필수자격요건 데이터 조회
   * @param jobId
   */
  getJobDescRequiredSkills(jobId, localeCode) {
    return helpers.get(`/jobSkill/${jobId}/required`, { localeCode });
  },

  /**
   * 직무관리 > 직무기술서 > 필수자격요건 데이터 수정
   * @param jobId,
   */
  putJobDescRequiredSkill(jobId, arrayParam) {
    return helpers.put(`/jobSkill/${jobId}/required`, arrayParam);
  },

  /**
   * 필수자격요건 언어별 전체반영(등록, 수정 ,삭제)
   */
  putJobDescRequiredSkillAllLanguage(jobId, data) {
    return helpers.put(`/jobSkill/${jobId}/required/allLanguages`, data);
  },

  /**
   * 직무관리 > 직무기술서 > 선호자격요건 데이터 조회
   * @param jobId
   */
  getJobDescPreferSkills(jobId, localeCode) {
    return helpers.get(`/jobSkill/${jobId}/prefer`, { localeCode });
  },

  /**
   * 직무관리 > 직무기술서 > 선호자격요건 데이터 수정
   * @param jobId,
   */
  putJobDescPreferSkill(jobId, arrayParam) {
    return helpers.put(`/jobSkill/${jobId}/prefer`, arrayParam);
  },

  /**
   * 선호자격요건 언어별 전체반영(등록, 수정 ,삭제)
   */
  putJobDescPreferSkillAllLanguage(jobId, data) {
    return helpers.put(`/jobSkill/${jobId}/prefer/allLanguages`, data);
  },

  /**
   * 직무관리 > 직무기술서 > 필요역량 데이터 조회
   * @param jobId
   */
  getJobDescAbillties(jobId, localeCode) {
    return helpers.get(`/jobSkill/${jobId}/ability`, { localeCode });
  },

  /**
   * 직무관리 > 직무기술서 > 필요역량 데이터 수정
   * @param jobId,
   */
  putJobDescAbillty(jobId, arrayParam) {
    return helpers.put(`/jobSkill/${jobId}/ability`, arrayParam);
  },

  /**
   * 필요역량 언어별 전체반영(등록, 수정 ,삭제)
   */
  putJobDescAbilityAllLanguage(jobId, data) {
    return helpers.put(`/jobSkill/${jobId}/ability/allLanguages`, data);
  },
};
