<template>
  <div
    :class="[
      type === 'textarea' ? 'el-textarea' : 'el-input',
      inputSize ? 'el-input--' + inputSize : '',
      {
        'is-disabled': inputDisabled,
        'el-input-group': $slots.prepend || $slots.append,
        'el-input-group--append': $slots.append,
        'el-input-group--prepend': $slots.prepend,
        'el-input--prefix': $slots.prefix || prefixIcon,
        'el-input--suffix': $slots.suffix || suffixIcon || clearable,
      },
    ]"
    @mouseenter="hovering = true"
    @mouseleave="hovering = false"
  >
    <template v-if="type !== 'textarea'">
      <!-- 前置元素 -->
      <div v-if="$slots.prepend" class="el-input-group__prepend">
        <slot name="prepend" />
      </div>
      <input
        v-if="type !== 'textarea'"
        ref="input"
        :tabindex="tabindex"
        v-bind="$attrs"
        :type="type"
        :disabled="inputDisabled"
        :readonly="readonly"
        :autocomplete="autoComplete || autocomplete"
        :value="nativeInputValue"
        :aria-label="label"
        class="el-input__inner"
        @compositionstart="handleComposition"
        @compositionupdate="handleComposition"
        @compositionend="handleComposition"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
      />
      <!-- 前置内容 -->
      <span v-if="$slots.prefix || prefixIcon" class="el-input__prefix">
        <slot name="prefix" />
        <i v-if="prefixIcon" :class="prefixIcon" class="el-input__icon" />
      </span>
      <!-- 后置内容 -->
      <span
        v-if="
          $slots.suffix ||
          suffixIcon ||
          showClear ||
          (validateState && needStatusIcon)
        "
        class="el-input__suffix"
      >
        <span class="el-input__suffix-inner">
          <template v-if="!showClear">
            <slot name="suffix" />
            <i v-if="suffixIcon" :class="suffixIcon" class="el-input__icon" />
          </template>
          <i
            v-else
            class="el-input__icon el-icon-circle-close el-input__clear"
            @click="clear"
          />
        </span>
        <i
          v-if="validateState"
          :class="['el-input__validateIcon', validateIcon]"
          class="el-input__icon"
        />
      </span>
      <!-- 后置元素 -->
      <div v-if="$slots.append" class="el-input-group__append">
        <slot name="append" />
      </div>
    </template>
    <textarea
      v-else
      ref="textarea"
      :tabindex="tabindex"
      :value="nativeInputValue"
      v-bind="$attrs"
      :disabled="inputDisabled"
      :readonly="readonly"
      :autocomplete="autoComplete || autocomplete"
      :style="textareaStyle"
      :aria-label="label"
      class="el-textarea__inner"
      @compositionstart="handleComposition"
      @compositionupdate="handleComposition"
      @compositionend="handleComposition"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
      @change="handleChange"
    />
  </div>
</template>
<script>
import emitter from "element-ui/src/mixins/emitter";
import Migrating from "element-ui/src/mixins/migrating";
import calcTextareaHeight from "./calcTextareaHeight";
import merge from "element-ui/src/utils/merge";

export default {
  name: "ElInput",

  componentName: "ElInput",

  mixins: [emitter, Migrating],

  inject: {
    elForm: {
      default: "",
    },
    elFormItem: {
      default: "",
    },
  },

  inheritAttrs: false,

  props: {
    // eslint-disable-next-line vue/require-default-prop
    value: [String, Number],
    // eslint-disable-next-line vue/require-default-prop
    size: String,
    // eslint-disable-next-line vue/require-default-prop
    resize: String,
    // eslint-disable-next-line vue/require-default-prop
    form: String,
    // eslint-disable-next-line vue/require-default-prop
    disabled: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    readonly: Boolean,
    type: {
      type: String,
      default: "text",
    },
    autosize: {
      type: [Boolean, Object],
      default: false,
    },
    autocomplete: {
      type: String,
      default: "off",
    },
    /** @Deprecated in next major version */
    // eslint-disable-next-line vue/require-default-prop
    autoComplete: {
      type: String,
      validator() {
        process.env.NODE_ENV !== "production" &&
          console.warn(
            "[Element Warn][Input]'auto-complete' property will be deprecated in next major version. please use 'autocomplete' instead.",
          );
        return true;
      },
    },
    validateEvent: {
      type: Boolean,
      default: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    suffixIcon: String,
    // eslint-disable-next-line vue/require-default-prop
    prefixIcon: String,
    // eslint-disable-next-line vue/require-default-prop
    label: String,
    clearable: {
      type: Boolean,
      default: false,
    },
    // eslint-disable-next-line vue/require-default-prop
    tabindex: String,
  },

  data() {
    return {
      textareaCalcStyle: {},
      hovering: false,
      focused: false,
      isOnComposition: false,
    };
  },

  computed: {
    _elFormItemSize() {
      return (this.elFormItem || {}).elFormItemSize;
    },
    validateState() {
      return this.elFormItem ? this.elFormItem.validateState : "";
    },
    needStatusIcon() {
      return this.elForm ? this.elForm.statusIcon : false;
    },
    validateIcon() {
      return {
        validating: "el-icon-loading",
        success: "el-icon-circle-check",
        error: "el-icon-circle-close",
      }[this.validateState];
    },
    textareaStyle() {
      return merge({}, this.textareaCalcStyle, { resize: this.resize });
    },
    inputSize() {
      return this.size || this._elFormItemSize || (this.$ELEMENT || {}).size;
    },
    inputDisabled() {
      return this.disabled || (this.elForm || {}).disabled;
    },
    nativeInputValue() {
      return this.value === null || this.value === undefined ? "" : this.value;
    },
    showClear() {
      return (
        this.clearable &&
        !this.inputDisabled &&
        !this.readonly &&
        this.nativeInputValue &&
        (this.focused || this.hovering)
      );
    },
  },

  watch: {
    value(val) {
      this.$nextTick(this.resizeTextarea);
      if (this.validateEvent) {
        this.dispatch("ElFormItem", "el.form.change", [val]);
      }
    },
  },

  created() {
    this.$on("inputSelect", this.select);
  },

  mounted() {
    this.resizeTextarea();
    this.updateIconOffset();
  },

  updated() {
    this.$nextTick(this.updateIconOffset);
  },

  methods: {
    focus() {
      this.getInput().focus();
    },
    blur() {
      this.getInput().blur();
    },
    getMigratingConfig() {
      return {
        props: {
          icon: "icon is removed, use suffix-icon / prefix-icon instead.",
          "on-icon-click": "on-icon-click is removed.",
        },
        events: {
          click: "click is removed.",
        },
      };
    },
    handleBlur(event) {
      this.focused = false;
      this.$emit("blur", event);
      if (this.validateEvent) {
        this.dispatch("ElFormItem", "el.form.blur", [this.value]);
      }
    },
    select() {
      this.getInput().select();
    },
    resizeTextarea() {
      if (this.$isServer) return;
      const { autosize, type } = this;
      if (type !== "textarea") return;
      if (!autosize) {
        this.textareaCalcStyle = {
          minHeight: calcTextareaHeight(this.$refs.textarea).minHeight,
        };
        return;
      }
      const minRows = autosize.minRows;
      const maxRows = autosize.maxRows;

      this.textareaCalcStyle = calcTextareaHeight(
        this.$refs.textarea,
        minRows,
        maxRows,
      );
    },
    handleFocus(event) {
      this.focused = true;
      this.$emit("focus", event);
    },
    handleComposition() {
      // IE버그
      // if (event.type === 'compositionstart') {
      //   this.isOnComposition = true;
      // }
      // if (event.type === 'compositionend') {
      //   this.isOnComposition = false;
      //   this.handleInput(event);
      // }
    },
    handleInput(event) {
      // IE버그
      // if (this.isOnComposition) return;

      // hack for https://github.com/ElemeFE/element/issues/8548
      // should remove the following line when we don't support IE
      if (event.target.value === this.nativeInputValue) return;

      this.$emit("input", event.target.value);

      // set input's value, in case parent refuses the change
      // see: https://github.com/ElemeFE/element/issues/12850
      // IE버그
      // this.$nextTick(() => {
      //   let input = this.getInput();
      //   input.value = this.value;
      // });
    },
    handleChange(event) {
      this.$emit("change", event.target.value);
    },
    calcIconOffset(place) {
      let elList = [].slice.call(
        this.$el.querySelectorAll(`.el-input__${place}`) || [],
      );
      if (!elList.length) return;
      let el = null;
      for (let i = 0; i < elList.length; i++) {
        if (elList[i].parentNode === this.$el) {
          el = elList[i];
          break;
        }
      }
      if (!el) return;
      const pendantMap = {
        suffix: "append",
        prefix: "prepend",
      };

      const pendant = pendantMap[place];
      if (this.$slots[pendant]) {
        el.style.transform = `translateX(${place === "suffix" ? "-" : ""}${
          this.$el.querySelector(`.el-input-group__${pendant}`).offsetWidth
        }px)`;
      } else {
        el.removeAttribute("style");
      }
    },
    updateIconOffset() {
      this.calcIconOffset("prefix");
      this.calcIconOffset("suffix");
    },
    clear() {
      this.$emit("input", "");
      this.$emit("change", "");
      this.$emit("clear");
    },
    getInput() {
      return this.$refs.input || this.$refs.textarea;
    },
  },
};
</script>
