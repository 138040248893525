<template>
  <el-dialog
    :modal-append-to-body="false"
    :before-close="handleBeforeClose"
    :title="lang(title)"
    :width="width"
    visible
    @close="$emit('close')"
  >
    <div
      v-loading="pending"
      class="attach-file"
      element-loading-background="transparent"
    >
      <div v-if="$slots.label" class="attach-file__head">
        <slot name="label" />
      </div>
      <div class="attach-file__file">
        <el-input
          :placeholder="lang('common.placeholder.찾아보기')"
          :value="fileName"
          disabled
          size="small"
          class="value-input"
        >
          <template slot="suffix">
            <el-upload
              ref="upload"
              :auto-upload="false"
              :multiple="false"
              :show-file-list="false"
              :before-upload="handleBeforeUpload"
              :on-success="handleSuccess"
              :on-error="() => (pending = false)"
              :on-change="handleChange"
              :on-progress="handleProgress"
              :http-request="handleUpload"
              :action="uploadUrl"
              v-bind="bindUploadAttributes"
              name="uploadFile"
              list-type="text"
            >
              <el-button size="small">{{
                lang("common.버튼.찾아보기")
              }}</el-button>
            </el-upload>
          </template>
        </el-input>
      </div>
      <span class="attach-file__warning-tip">
        <template v-if="showTip">{{ tip }}</template>
        <div v-if="pending">{{ percentToFix }}</div>
      </span>
      <div class="attach-file__button">
        <el-button
          :disabled="saveDisabled"
          size="small"
          @click="submitUpload"
          >{{ lang("common.버튼.엑셀업로드") }}</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import helpers from "@/auth/helpers";
import { fileValidator } from "@/lib/validator";
import { i18nTranslator } from "@/mixins/i18n";
import { FILE as FILE_RULE } from "@/config/rule";
import { apiBaseUrl } from "@/config/cloudEnv";

export default {
  name: "ExcelUpload",
  mixins: [i18nTranslator(null)],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    visible: Boolean,
    showTip: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default() {
        return "common.제목2";
      },
    },
    width: {
      type: String,
      default: "600px",
    },
    allowMimeTypes: {
      type: Array,
      default() {
        return FILE_RULE.excel.mimeTypes;
      },
    },
    allowExtensions: {
      type: Array,
      default() {
        return FILE_RULE.excel.extensions;
      },
    },
    limitWithRemote: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      uploadFile: null,
      pending: false,
      percent: -1,
      fileList: [],
    };
  },
  computed: {
    percentToFix() {
      if (this.percent > -1) {
        return this.percent.toFixed(1) + "%";
      }
      return null;
    },
    bindUploadAttributes() {
      return {};
    },
    saveDisabled() {
      return !this.uploadFile;
    },
    fileName() {
      return this.uploadFile ? this.uploadFile.name : null;
    },
    tip() {
      return this.$tc("msg.fileWarning4", null, {
        type: this.allowExtensions.join(", "),
      });
    },
    uploadUrl() {
      return `${apiBaseUrl()}/excel/upload`;
    },
  },
  methods: {
    handleBeforeClose(done) {
      // 업로드 중 닫기 불가능하도록
      if (!this.pending) {
        done();
      }
      return null;
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleUpload(option) {
      // eslint-disable-next-line no-unused-vars
      const { action, file, filename } = option;
      const formData = new FormData();
      formData.append(filename, file, file.name);
      return helpers.post(option.action, formData, {
        headers: {
          "Content-type": "multipart/form-data",
        },
      });
    },
    handleBeforeUpload(file) {
      return new Promise((resolve, reject) => {
        const validate = fileValidator(file, {
          allowExtensions: this.allowExtensions,
        });

        if (!validate.valid) {
          this.$message({
            type: "warning",
            message: validate.errors[0],
          });
          this.uploadFile = null;
          // eslint-disable-next-line prefer-promise-reject-errors
          return reject();
        }

        this.pending = true;
        return resolve();
      });
    },
    handleSuccess(response) {
      this.percent = -1;
      this.pending = false;
      // 190109 response 형태 변경에 따른 수정
      // this.$emit('success', response);
      response.payload = response.data.payload;
      delete response.data.payload;
      this.$emit("success", response);
    },
    handleChange(files, fileList) {
      fileList.splice(0, fileList.length, files);
      // check maximum file length with remote files
      if (this.fileList.length + fileList.length > this.limitWithRemote) {
        this.$message({
          type: "warning",
          message: this.$tc("msg.fileWarning5", null, {
            total: this.fileList.length,
            current: this.fileList.length,
          }),
        });
        fileList.splice(0, fileList.length);
        this.uploadFile = null;
        return;
      }
      this.uploadFile = files;
    },
    handleProgress(event) {
      this.percent = event.percent;
    },
  },
};
</script>
