const routes = [
  {
    name: "home",
    path: "/",
    component: () =>
      import(/* webpackChunkName: "main" */ "@/views/main/MainDefault.vue"),
    title: "HR HOME",
    layout: "DefaultLayout",
    children: [],
    isPublic: false,
  },
  {
    name: "logout",
    path: "/logout",
    component: () =>
      import(/* webpackChunkName: "logout" */ "@/views/auth/logout.vue"),
    title: "로그아웃",
    layout: "PublicLayout",
    isPublic: true,
  },
  {
    name: "logout-success",
    path: "/logout-success",
    component: () =>
      import(
        /* webpackChunkName: "logout" */ "@/views/auth/logout-success.vue"
      ),
    title: "로그아웃 완료",
    layout: "PublicLayout",
    isPublic: true,
  },
  // {
  //   // others
  //   path: '*',
  //   redirect: '/'
  // }
  // {
  //   name: 'error',
  //   path: '*',
  //   component: () =>
  //     import(/* webpackChunkName: "" */ '@/views/error/error.vue'),
  //   title: 'NOT FOUND',
  //   layout: 'PublicLayout',
  //   isPublic: true
  // }
];

export default routes;
