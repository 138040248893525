<template>
  <div class="app-header-search">
    <button class="search-button" role="button" @click="openCommandK">
      <iconify-icon width="20px" class="search-button__icon" icon="mi:search" />

      <span class="search-button__text">
        {{ $tc("app-header.통합검색") }}
      </span>

      <span v-show="isReady" class="search-button__key-wrapper">
        <kbd class="search-button__key">{{ ctrlKey }}</kbd>
        <kbd class="search-button__key">K</kbd>
      </span>
    </button>
  </div>
</template>

<script>
export default {
  name: "AppHeaderSearch",

  data() {
    return {
      isReady: false,
      ctrlKey: "Ctrl",
    };
  },

  mounted() {
    this.$nextTick(() => {
      // Mac OS 에서는 Ctrl 대신 ⌘ 표시
      if (navigator.platform.toUpperCase().includes("MAC")) {
        this.ctrlKey = "⌘";
      }
      this.isReady = true;
    });
  },

  methods: {
    openCommandK() {
      this.$store.commit("commandK/setVisible", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.app-header-search {
  display: contents;

  .search-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 16px;
    width: 300px;
    height: $--input-height;
    border: none;
    border-radius: 6px;
    background: $gpro-warm-gray-100;
    color: $--input-placeholder-color;
    cursor: pointer;
    padding: 0 16px;
    transition: all 100ms ease;

    &:hover {
      background: $--color-white;
      box-shadow: $--box-shadow-base;
    }

    @at-root .navbar .header-controls__item &__icon {
      color: $gpro-warm-gray-400;
    }

    &__text {
      flex: 1;
      display: flex;
      align-items: flex-start;
    }

    &__key-wrapper {
      display: flex;
      align-items: center;
      column-gap: 2px;
    }

    &__key {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $--font-size-extra-small;
      background-color: $gpro-warm-gray-200;
      border-radius: $--border-radius-base;
      border: 1px solid $--border-color-light;
      padding: 2px 4px;
      font-weight: $gpro-font-weight-400;
      font-family: inherit;
    }
  }
}
</style>
