import Vue from "vue";
import i18n from "@/i18n";

/**
 * @desc JSON 1차원 데이터 -> 계층구조 트리모델로 매핑
 * @param {array} array
 * @param {string || number} rootId
 * @param {object} setting
 */
const mapToTreeModel = function mapToTreeModel(param) {
  let { arrayList, rootId, parentIdName, idName } = param;

  if (!parentIdName) parentIdName = "superOrganizationId";

  if (!idName) idName = "organizationId";

  return new Promise((resolve) => {
    let rootNodes = [];
    const traverse = function (nodes, item, index) {
      if (nodes instanceof Array) {
        return nodes.some(function (node) {
          if (node[idName] === item[parentIdName]) {
            node.children = node.children || [];
            return node.children.push(
              arrayList.splice(index, 1).map((el) => {
                el.calculatedStep = el.organizationStep - node.organizationStep;
                return el;
              })[0],
            );
          }

          return traverse(node.children, item, index);
        });
      }
    };

    const maximumLoopStack = 3000; // 조회된 조직 수 제한
    // 비정상적(?)으로 많은 조직이 조회된경우..
    if (arrayList.length > maximumLoopStack) {
      // reject(new Error('개발자에게 문의 바랍니다. (조직갯수' + arrayList.length + ')'))
      Vue.alert(
        i18n.t("msg.unexpectedError", "length", { length: arrayList.length }),
        "error",
      ); // 개발자에게 문의 바랍니다.
      return [];
    }

    while (arrayList.length > 0) {
      const somed = arrayList.some(function (item, index) {
        if (item[parentIdName] == rootId) {
          rootNodes.push(arrayList.splice(index, 1)[0]);
          // debugger;
          return true;
        }

        return traverse(rootNodes, item, index);
      });

      if (!somed) {
        break;
      }
    }

    resolve(rootNodes);
  });
};

export default mapToTreeModel;
