import Vue from "vue";
import Vuex from "vuex";
import auth from "@/store/auth";
import user from "@/store/user";
import common from "@/store/common";
import commute from "@/store/commute";
import settings from "@/store/settings";
import commandK from "@/store/commandK";
import favoriteMenus from "@/store/favoriteMenus";
import safe from "@/store/safe";
import notification from "@/store/notification";
import { localStoragePlugin } from "@/store/plugins";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    user,
    settings,
    common,
    commute,
    safe,
    notification,
    commandK,
    favoriteMenus,
  },
  plugins: [localStoragePlugin],
});
