<template>
  <div class="app-header-profile">
    <div class="dropdown-profile dropdown-profile--right">
      <div class="dropdown-profile__elem info">
        <div class="flex-item profile-name">{{ localizeName(user) }}</div>
        <!-- <span class="flex-item profile-company-name">{{ user.companyName }}</span> -->
      </div>
      <div class="dropdown-profile__elem link">
        <a :href="toHub('/ess')" class="dropdown-menu__link">
          {{ lang("마이페이지") }}
          <i class="icon el-icon-arrow-right" />
        </a>
      </div>
      <div class="dropdown-profile__elem link">
        <a
          :href="toHub('/my/settings')"
          class="dropdown-menu__link"
          target="_blank"
        >
          {{ lang("나의_설정") }}
          <i class="icon el-icon-arrow-right" />
        </a>
      </div>
      <div class="dropdown-profile__elem button">
        <a :href="logout()" class="dropdown-menu__button" type="primary"
          >{{ lang("로그아웃") }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import AppHeaderDropdown from "@/components/app/AppHeaderDropdown";
import { mapGetters } from "vuex";
import unknownImage from "@/assets/unknown.jpg";
import { hubBaseHost, apiBaseUrl } from "@/config/cloudEnv";
import { i18nTranslator } from "@/mixins/i18n";

export default {
  name: "AppHeaderProfile",
  // eslint-disable-next-line vue/no-unused-components
  components: { AppHeaderDropdown },
  mixins: [i18nTranslator("app-header")],
  // eslint-disable-next-line vue/require-prop-types
  props: ["top"],
  data() {
    return {
      propTop: this.top || "80px",
      dropped: false,
    };
  },
  computed: {
    ...mapGetters("user", { user: "getUserInfo" }),
    userPhotoSrc() {
      if (this.user.userPhoto) {
        return `${apiBaseUrl()}/common/public/user-photo/default/${
          this.user.userPhoto
        }`;
      }
      return unknownImage;
    },
    locale() {
      return this.$store.state.settings.currentLocale;
    },
  },
  watch: {
    "$route.path"() {
      this.dropped = false;
    },
  },
  methods: {
    toHub(to) {
      return `${hubBaseHost()}${to}`;
    },
    localizeName(user) {
      if (!user) return "";
      if (this.locale === "ko") {
        return user.userName || "";
      } else {
        return user.userNameEn || "";
      }
    },
    logout() {
      return `${hubBaseHost()}/logout`;
    },
  },
};
</script>
<style lang="scss" scoped>
$logout-button-height: 36px;

.app-header-profile {
  display: flex;
  line-height: normal;
  width: 240px;
  min-height: 120px;
  background-color: $--color-white;

  .dropdown-profile {
    &--left {
      position: relative;
      flex: 0 0 90px;
      max-width: 90px;
    }

    &--right {
      position: relative;
      padding: 16px;
      width: 100%;
      flex: 1;
    }

    &__elem {
      line-height: normal; // navbar에서의 lineheight가 내려옴..
      &.info {
        font-size: $--font-size-base;
        font-weight: $gpro-font-weight-500;
        color: $--color-black;
        padding-bottom: 8px;
      }

      &.link {
        font-size: $--font-size-base;
        font-weight: $gpro-font-weight-400;
        padding-bottom: 4px;
        color: $gpro-warm-gray-700;

        > a {
          color: inherit;
          &:hover {
            text-decoration: underline;
          }
        }
      }

      &.button {
        margin-top: 8px;

        a {
          text-align: center;
          transition: background-color 0.1s;
          display: inline-block;
          white-space: nowrap;
          cursor: pointer;
          margin: 0;
          height: 24px;
          line-height: 24px;
          padding: 0;
          width: 100%;
          font-size: $--font-size-small;
          font-weight: $gpro-font-weight-500;
          border: 0;
          border-radius: 2px;
          background-color: $gpro-warm-gray-100;
          color: $--color-black;

          &:hover {
            background-color: $gpro-warm-gray-700;
            color: $--color-white;
          }
        }
      }
    }

    &__img {
      width: 90px;
      height: 120px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}
</style>
