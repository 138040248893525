<template>
  <el-dialog
    :title="title"
    :width="width"
    :modal-append-to-body="false"
    visible
    @close="$emit('close')"
  >
    <div
      v-loading="pending"
      class="attach-file attach-file--download"
      element-loading-background="transparent"
    >
      <div v-if="$slots.label" class="attach-file__head">
        <slot name="label" />
      </div>

      <div class="attach-file__file">
        <!-- <el-upload
          ref="upload"
          name="uploadFile"
          list-type="text"
          :show-file-list="false"
          :file-list="fileList"
          :before-remove="handleBeforeRemove"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          action="">
        </el-upload> -->
        <file-list-view
          :group-id="groupId"
          :value-id="valueId"
          :file-list="fileList"
          :removable="false"
          downloadable
        />
      </div>

      <!-- <span class="attach-file__warning-tip">{{tip}}</span> -->

      <div class="attach-file__button">
        <el-button
          size="small"
          @keydown.enter.native.prevent
          @keyup.enter.native.prevent="$emit('close')"
          @click="$emit('close')"
          >{{ lang("common.버튼.닫기") }}</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import helpers from "@/auth/helpers";
import { i18nTranslator } from "@/mixins/i18n";
import { FILE as FILE_RULE } from "@/config/rule";
import FileListView from "./FileListView";
import { apiBaseUrl } from "@/config/cloudEnv";

export default {
  name: "FileDownload",
  components: {
    FileListView,
  },
  mixins: [i18nTranslator(null)],
  props: {
    // eslint-disable-next-line vue/require-default-prop
    visible: Boolean,
    title: {
      type: String,
      default() {
        return this.$tc("template.제목.파일목록");
      },
    },
    width: {
      type: String,
      default: "600px",
    },
    attachGroupId: {
      type: Number,
      default: null,
    },
    attachValueId: {
      type: Number,
      default: null,
    },
    allowTypes: {
      type: Array,
      default() {
        return FILE_RULE.image.mimeTypes;
      },
    },
    // 단위: KB
    maxSize: {
      type: Number,
      default: FILE_RULE.image.size,
    },
    limit: {
      type: Number,
      default: 10000,
    },
  },
  data() {
    return {
      previousElement: null,
      pending: false,
      uploadFile: null,
      fileList: [],
      groupId: this.attachGroupId,
      valueId: this.attachValueId,
    };
  },
  computed: {},
  watch: {
    attachGroupId() {
      this.groupId = this.attachGroupId;
    },
    attachValueId() {
      this.valueId = this.attachValueId;
    },
  },
  created() {
    this.previousElement = document.activeElement;
  },

  destroyed() {
    this.previousElement && this.previousElement.focus();
  },

  mounted() {
    this.refreshFileList();
  },
  methods: {
    handleRemove() {
      // 삭제 불가처리
      // this.$emit('on-remove', file, fileList)
    },
    async handleBeforeRemove() {
      // 삭제 불가처리
      // if (await this.$confirm(
      //   this.lang('msg.removeConfirm'),
      //   this.lang('msg.noticeText'), {
      //     confirmButtonText: '삭제',
      //     cancelButtonText: '취소'
      //   })
      // ) {
      //   if (file.attachValueId) {
      //     await helpers.delete(`/attachment/groups/${this.groupId}/files/${file.attachValueId}`);
      //     this.$message({message: this.lang('msg.removeSuccess')})
      //   }
      // }
    },
    handleChange(files, fileList) {
      fileList.splice(0, fileList.length, files);
      this.uploadFile = files;
    },
    handlePreview() {
      // window.location.href = process.env.VUE_APP_API_BASE_URL + f.url;
    },
    async refreshFileList() {
      let oneList = [];

      if (this.groupId) {
        const {
          data: { payload },
        } = await helpers.get(`/attachment/groups/${this.groupId}/files`);

        let totalList = payload;
        this.fileList = this.mapFileList(totalList);

        if (this.valueId !== null) {
          for (let i in payload) {
            if (this.valueId === payload[i].attachValueId) {
              oneList.push(payload[i]);
            }
          }
          this.fileList = this.mapFileList(oneList);
        }
      }
    },
    mapFileList(files = []) {
      return files.map((f) => {
        return {
          name: f.originalFileName,
          url: apiBaseUrl() + f.url,
          attachValueId: f.attachValueId,
        };
      });
    },
  },
};
</script>
