<template>
  <input
    ref="inputRef"
    class="command-k-input"
    auto-focus
    auto-complete="off"
    auto-correct="off"
    :spell-check="false"
    aria-autocomplete="list"
    role="combobox"
    :aria-expanded="true"
    :placeholder="placeholder"
    :value="localSearch"
    :maxlength="100"
    @input="handleInput"
  />
</template>

<script>
export default {
  name: "CommandKInput",

  props: {
    placeholder: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      inputRef: null,
    };
  },

  computed: {
    visible() {
      return this.$store.state.commandK.visible;
    },
    localSearch: {
      get() {
        return this.$store.state.commandK.search;
      },
      set(value) {
        this.$emit("input", value);
        this.$store.commit("commandK/setSearch", value);
      },
    },
  },

  watch: {
    visible: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.focusInput();
        }
      },
    },
    inputRef: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.focusInput();
        }
      },
    },
  },

  mounted() {
    this.inputRef = this.$refs.inputRef;
  },

  methods: {
    focusInput() {
      this.$nextTick(this.inputRef?.focus());
    },
    handleInput(e) {
      this.localSearch = e.target.value;
    },
  },
};
</script>
