import Vue from "vue";
import unknownImage from "@/assets/unknown.jpg";

const defaults = {
  userOn: false,
  userInfo: {
    accountName: null,
    authenticationType: null,
    callNameCode: null,
    companyCode: null,
    currentCompId: null,
    description: null,
    dutyCode: null,
    dutyName: null,
    email: null,
    employeeId: null,
    employeeNumber: null,
    englishNick: null,
    hireYmd: null,
    hrEmployeeId: null,
    language: "ko",
    mobile: null,
    orgName: null,
    personId: null,
    pictureAttachGroupId: null,
    pictureAttachValueId: null,
    retireTypeCode: null,
    retireYmd: null,
    telOffice: null,
    userId: null,
    userName: null,
    userNameEn: null,
    userPhoto: null,
    userState: null,
    userTypeCode: null,
  },
  programs: {},
  userMenu: [],
  headerMenu: [],
  userEvents: {},
  headerAuth: {},
  childProgramAuthList: [],
  childProgramAuthMap: {},
  bookMarkCodes: [],
};

const mapMenu = (arr) => {
  let obj = {};
  searchArr(obj, arr);
  return obj;
};

const searchArr = (obj, arr) => {
  arr.forEach((el) => {
    obj[el.programCode] = el;
    if (el.children) searchArr(obj, el.children);
  });
};

const newMenu = (arr) => {
  arr.forEach((v) => {
    if (v.menuTypeCode === "FOLDER") v.isOpen = true;
  });
  return arr;
};

const mapEvents = (arr) => {
  let obj = {};
  arr.forEach((el) => {
    let container = {};
    container.uiEventCode = el.uiEventCode;
    container.uiEventId = el.uiEventId;
    let oldData = obj[el.programCode];
    if (!oldData) {
      obj[el.programCode] = Object.assign({}, container);
    } else if (oldData.uiEventCode === "R" && container.uiEventCode !== "R") {
      obj[el.programCode] = Object.assign({}, container);
    }
  });
  return obj;
};

const toggleArr = (arr, id) => {
  arr.forEach((el) => {
    if (el.menuId === id) {
      el.isOpen = !el.isOpen;
    }
  });
  return arr;
};

const user = {
  namespaced: true,
  // state
  state: Object.assign({}, defaults),
  // mutations
  // mutations are synchronous
  mutations: {
    setBookMarkCodes(state, bookMarkCodes) {
      state.bookMarkCodes = [...bookMarkCodes];
    },
    update(state, data) {
      state = Object.assign({}, defaults, data);
    },
    updateUserInfo(state, data) {
      state.userInfo = Object.assign({}, defaults.userInfo, data);
    },
    updateUserPicture(state, { userPhoto }) {
      // url이 동일하기때문에 이미지 다시불러옴(refresh)
      state.userInfo.userPhoto = userPhoto + "?v=" + new Date().getTime();
    },
    setHeaderMenu(state, data) {
      state.headerMenu = data.slice(0).filter((menu) => {
        return menu.menuId !== 1000; // 1000 = 관리자
      }); // Array
    },
    setUserMenu(state, data) {
      for (const p of data) {
        for (const c of p.children) {
          let { standardYmdYn, visibleFlag, corporationComboYn, helpPopupYn } =
            c;
          state.headerAuth[c.programCode] = {
            standardYmdYn,
            visibleFlag,
            corporationComboYn,
            helpPopupYn,
          };
        }
      }
      state.userMenu = data.slice(0); // Array
    },
    setUserEvents(state, data) {
      state.userEvents = Object.assign({}, state.userEvents, data);
    },
    setPrograms(state, data) {
      state.programs = Object.assign({}, data);
    },
    setUserFlag(state, flag) {
      state.userOn = flag;
    },
    clear(state) {
      Object.assign(state, defaults);
    },
    updateUserEventReadOnly(state, data) {
      if (data.editYn === "Y") {
        for (let i = 0; i < data.programList.length; i++) {
          state.userEvents[data.programList[i].programCode].uiEventCode = "R";
        }
      }
    },
  },
  getters: {
    bookMarkCodes: (state) => {
      return state.bookMarkCodes;
    },
    headerAuth: (state) => {
      return state.headerAuth;
    },
    getUserInfo: (state) => {
      return state.userInfo;
    },
    getUserPictureSrc: (state) => {
      const { userPhoto } = state.userInfo;
      if (userPhoto) {
        return userPhoto;
      }
      return unknownImage;
    },
    getHeaderMenu: (state) => {
      return state.headerMenu;
    },
    userMenu: (state) => {
      return state.userMenu;
    },
    userEvents: (state) => {
      return state.userEvents;
    },
    programEvent: (state) => {
      return (programCode) => {
        return state.userEvents[programCode];
      };
    },
    programEventCode: (state) => {
      return (programCode) => {
        if (programCode in state.userEvents) {
          return state.userEvents[programCode].uiEventCode;
        }
        console.warn("이벤트권한 정보가 없습니다. " + programCode);
        return null;
      };
    },
    programs: (state) => {
      return state.programs;
    },
    getPrograms: (state) => {
      return state.programs;
    },
    childProgramAuthList: (state) => state.childProgramAuthList,
    childProgramAuthMap: (state) => state.childProgramAuthMap,
  },
  // actions
  // actions are asynchronous
  // actions can dispatch more than 1 mutaion at a time!!!
  actions: {
    clear({ commit }) {
      // 클리어
      commit("clear");
    },
    update({ commit }, data) {
      commit("update", data);
    },
    updateUserInfo({ commit, dispatch }, data) {
      dispatch("auth/updateLastLoggedIn", data.userId, { root: true });
      // commit('setUserFlag', true);
      commit("updateUserInfo", data);
    },
    updateUserPicture({ state, commit }, data) {
      if (data.employeeId === state.userInfo.employeeId) {
        Vue.auth.get("/users/me").then((response) => {
          const { userPhoto } = response.data.payload;
          commit("updateUserPicture", { userPhoto });
        });
      }
    },
    getUserInfo() {},
    getUserMenu() {},
    getUserEvents() {},
    setHeaderMenu({ commit }, data) {
      let newMenuList = newMenu(data);
      commit("setHeaderMenu", newMenuList);
    },
    setUserMenu({ commit }, data) {
      let newMenuList = newMenu(data);
      commit("setUserMenu", newMenuList);
      commit("setPrograms", mapMenu(data));
    },
    setUserEvents({ commit }, data) {
      commit("setUserEvents", mapEvents(data));
    },
    setUserFlag({ commit }, data) {
      commit("setUserFlag", data);
    },
    toggleFolder({ state, commit }, menuId) {
      let toggledMenu = toggleArr(state.userMenu, menuId);
      commit("setUserMenu", toggledMenu);
    },
    updateUserEvent({ commit }, data) {
      commit("updateUserEventReadOnly", data);
    },
    mapChildProgramsToAuth: ({ state }, param) => {
      let { programCode, childPrograms } = param;
      if (childPrograms === undefined) childPrograms = [];

      const programInfo = state.programs[programCode];
      if (!programInfo || !programInfo.children) return null;
      const result = programInfo.children.filter(
        (program) =>
          childPrograms.findIndex(
            (child) => child.programCode === program.programCode,
          ) !== -1,
      );

      state.childProgramAuthMap = result.reduce((acc, d) => {
        acc[d.programCode] = d;
        return acc;
      }, {});

      state.childProgramAuthList = result;
    },
    /*
    updatecurrentLocale({ commit }, { instance, locale }) {
      const getLanguage = locale => {
        instance
          .$http({
            url: 'http://localhost:3000/' + locale + '.json',
            method: 'get',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json'
            }
          })
          .then(response => {
            return response.data;
          });
      };
      getLanguage(locale).then(r => {
        instance.$nextTick(() => {
          instance.$i18n.locale = locale; // locale 정보 commit
        });
      });
    }
    */
  },
};

export default user;
