import Vue from "vue";
import store from "@/store";
import axios from "axios";
import { router } from "@/http";
import i18n from "@/i18n";
import Ga from "@/google-analytics";
import { hubBaseHost, apiBaseUrl } from "../config/cloudEnv";

/*
  HR 시스템은 특별히
  userInfo, userMenu, userEvents 이렇게 세가지를 동시에 받아와야되므로
  Promise All 처리 했다.
  Refresh token은 현재 logic에서 사용되지 않으므로 deprecate 시켰다.
  EA의 경우 2019-02-05 현재 권한 처리가 되지 않아 후에 변경해야된다.
  token 시간이 만료되었을 경우도 고려되어야한다.
*/

let sessionLoading = false;
const TEMP_SUB_ROUTES_MAP = {
  HPE1000: ["employeeId?"], // ? : optional 여부
  HOG1000: ["organizationId?", "effectiveDate?"],
  JDM1000: ["jobId?", "effectiveDate?"],
  HPE3000: ["employeeId?"], // ? : optional 여부
};

export default {
  install(Vue) {
    Vue.prototype.$auth = Vue.auth = axios.create();
    this.addInterceptors();
    this.setDefaults();
    this.getUser().then(
      (res) => {
        const rootRoute = router.options.routes.find((el) => el.path === "/");
        const {
          data: { payload: menu },
        } = res;
        let dynamicRoutes = [];
        for (let i = 0; i < menu.length; i++) {
          for (let j = 0; j < menu[i].children.length; j++) {
            const elem = menu[i].children[j];
            if (elem.menuTypeCode === "MENU") {
              const regEx = /([a-zA-Z]{3,4})(\d{4})/g; // match 0 : full 1 : 3-4 matched letters 2 : 4digits
              const prefix = regEx.exec(elem.programCode)[1];
              const subURL = TEMP_SUB_ROUTES_MAP[elem.programCode]
                ? "/:" + TEMP_SUB_ROUTES_MAP[elem.programCode].join("/:")
                : "";
              dynamicRoutes.push({
                name: elem.programCode,
                path: `/${elem.programCode}` + subURL,
                component: () =>
                  import(
                    /* webpackChunkName: "component" */ "@/pages/" +
                      prefix +
                      "/" +
                      elem.programCode +
                      ".vue"
                  ),
                meta: {
                  title: elem.menuName,
                  key: (route) => {
                    return route.matched;
                  },
                  menuId: elem.menuId,
                  code: elem.programCode,
                },
              });
            }
          }
        }
        rootRoute.children = dynamicRoutes;

        router.addRoutes([rootRoute]);
        store.dispatch("user/setUserFlag", true);
      },
      // eslint-disable-next-line no-unused-vars
      (_) => {
        // store.dispatch('common/clear'); // logout;
        // this.getSession();
        window.location.href = hubBaseHost();
      },
    );
  },
  setDefaults() {
    Vue.auth.defaults.baseURL = apiBaseUrl();
    Vue.auth.defaults.crossDomain = true;
    Vue.auth.defaults.withCredentials = true;
    Vue.auth.defaults.headers.common["Lang"] =
      store.state.settings.currentLocale;
  },
  setDynamicRoutes() {},
  getSession() {
    if (sessionLoading) {
      return;
    }
    sessionLoading = true;
    window.location.href = `${apiBaseUrl()}/common/login-redirect?retUrl=${encodeURIComponent(
      window.location.href,
    )}`;
  },
  async ajaxErrorProc(error) {
    const parsedError = this.ajaxErrorParser(error);
    switch (parsedError.status) {
      case 307:
        window.location.reload();
        break;
      case 401:
        store.dispatch("common/clear"); // logout;
        this.getSession();
        break;
      case 403:
        await Vue.alert(parsedError.message);
        window.location.href = hubBaseHost();
        break;
      case 410:
        await Vue.alert(i18n.t("msg.410"));
        window.location.href = hubBaseHost();
        break;
      default:
        await Vue.alert(parsedError.message);
        return error.response;
    }
  },
  ajaxErrorParser(error) {
    let message = i18n.t("msg.error");
    let type = "";
    let status = null;
    let code = "";
    let data = null;
    if (error.response) {
      status = error.response.status;
      if (error.response.data) {
        if (error.response.data["_meta"]) {
          code = error.response.data["_meta"].code;
          message = error.response.data["_meta"].message;
          type = error.response.data["_meta"]["errorType"];
          if (type === "validation") {
            data = error.response.data["_meta"]["data"];
            message = error.response.data["_meta"]["data"][0].message;
          }
        }
      }
    } else if (error.request) {
      // 다국어 처리 또는 영어.
      message = i18n.t("msg.noResponding");
    } else {
      // requset나 response이외의 현재 언제 발생할지 모르는 오류들 -> 발견시 적절히 처리할것.
      message = i18n.t("msg.unknownError");
    }
    return {
      message,
      type,
      status,
      code,
      data,
      error,
    };
  },
  addInterceptors() {
    Vue.auth.interceptors.response.use(
      (response) => {
        return Promise.resolve(response);
      },
      (error) => {
        if (
          error.config.errorHandler &&
          typeof error.config.errorHandler === "function"
        ) {
          return Promise.reject(error.config.errorHandler(error));
        }
        return Promise.reject(this.ajaxErrorProc(error));
      },
    );
  },
  storeUserInfo(response, isFinanceAdmin, isGaPrivileged) {
    const auth = store.state.auth;
    if (auth.lastLogedIn !== response.data.payload.userId) {
      // 마지막 로그인과 다르면 storage 클리어
      store.dispatch("common/clear");
    }
    let userInfo = response.data.payload;
    userInfo.isFinanceAdmin = isFinanceAdmin;
    userInfo.isGaPrivileged = isGaPrivileged;
    store.dispatch("user/updateUserInfo", userInfo);
  },
  storeUserMenu(resUserMenu, resHeaderMenu) {
    store.dispatch("user/setUserMenu", resUserMenu.data.payload);
    store.dispatch("user/setHeaderMenu", resHeaderMenu.data.payload);
  },
  storeUserEvents(response) {
    // Auth represents Authorization here(not Authentication).
    store.dispatch("user/setUserEvents", response.data.payload);
  },
  getUser() {
    return new Promise((resolve, reject) => {
      Promise.all([
        Vue.auth.get("/users/me"), // user info
        Vue.auth.get(`${apiBaseUrl()}/common/users/me/menu-trees/hr`), // 메뉴
        Vue.auth.get(`${apiBaseUrl()}/common/users/me/program-ui-events`), // user events
        Vue.auth.get("/gw/common/top/menus"), // site menu (headerMenu)
        Vue.auth.get("/ea/common/check/finance"),
        store.dispatch("commandK/fetchAllMenus"),
      ])
        .then(async (responses) => {
          let res;
          try {
            res = await Vue.auth.get("/ga/privileged");
          } catch {
            res = null;
          }
          this.storeUserInfo(
            responses[0],
            responses[4].data.payload,
            res?.data?.payload ?? false,
          );
          Ga.setUserId(responses[0].data.payload.userId);
          this.storeUserMenu(responses[1], responses[3]);
          this.storeUserEvents(responses[2]);
          if (responses[1].data.payload.length < 1) {
            reject(new Error("no menu"));
          } else {
            resolve(responses[1]);
          }
        })
        .catch((err) => {
          // error시 plan 적절히 추가해줘야됨..
          console.warn("getUser::", err);
          reject(err);
        });
    });
  },
};
