<template>
  <div v-show="isRender" role="presentation" class="command-k-empty">
    <slot />
  </div>
</template>

<script>
import { mapState } from "vuex";
import { debounce } from "lodash";

export default {
  name: "CommandKEmpty",

  data() {
    return {
      localReady: false,
    };
  },

  computed: {
    ...mapState("commandK", ["filtered", "loading", "asyncData", "search"]),

    isReady() {
      return this.$store.state.commandK.ready;
    },

    isRender() {
      return (
        this.isEmpty && !this.loading && this.isSearching && this.localReady
      );
    },
    isEmpty() {
      const emptyFiltered = this.filtered.count === 0;
      const emptyAsyncData = this.asyncData.count === 0;

      return emptyFiltered && emptyAsyncData;
    },
    isSearching() {
      return this.$store.getters["commandK/isSearching"];
    },
  },

  watch: {
    loading: {
      handler: function (newValue) {
        if (newValue) {
          this.localReady = false;
        }
      },
    },
    search: {
      handler: {
        handler(newValue) {
          if (newValue) {
            this.localReady = false;
          }
          if (!this.localReady && newValue) {
            this.debouncedReady();
          }
        },
      },
    },
  },

  methods: {
    debouncedReady: debounce(function () {
      this.localReady = true;
    }, 500),
  },
};
</script>
