import helpers from "@/auth/helpers";

export default {
  getAssdocument(param) {
    return helpers.get(`/assdocument/document`, param);
  },
  getPreviousAssdocument(param) {
    return helpers.get(`/assdocument/${param.documentId}/prievious`, param);
  },
  putPreviousAssdocument(param) {
    return helpers.put(`/assdocument/${param.documentId}/prievious`, param);
  },
  putAssignmentCorrect(param) {
    return helpers.put("/assdocument/correction", param);
  },
};
