import Vue from "vue";

export default {
  namespaced: true,

  state: {
    allMenus: null,
    ready: false,
  },

  mutations: {
    setAllMenus(state, allMenus) {
      state.allMenus = allMenus;
    },
  },

  actions: {
    updateAllMenus({ commit }, allMenus) {
      const hashMappedMenus = allMenus.reduce((acc, menu) => {
        return { ...acc, [menu.urlPath]: menu };
      }, {});
      commit("setAllMenus", hashMappedMenus);
    },
    createFavoriteMenu({ state }, to) {
      try {
        const key = to.path;
        const matched = state.allMenus?.[key];

        if (!matched) {
          return;
        }

        const { system, urlPath } = matched;
        const payload = {
          system,
          menuPath: urlPath,
        };

        Vue.auth.post(`/common/menus/access`, payload);
      } catch {
        // do nothing
      }
    },
  },
};
