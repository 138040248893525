/* eslint-disable  */
import _ from "lodash";
import moment from "moment";
import rule from "../config/rule";
import validator from "./validator";
import store from "@/store";
import i18n from "@/i18n";
import Vue from "vue";

const { DATE_FORMAT } = rule;

export const isNextDay = (date) => {
  const target = moment(date).format("HH:mm");
  const midnight = moment().set({
    hour: 0,
    minute: 0,
    second: 0,
  });
  const endOfNight = moment().set({
    hour: 5,
    minute: 59,
    second: 59,
  });

  return moment(target, "HH:mm").isBetween(
    midnight,
    endOfNight,
    "minutes",
    "[]",
  );
};

/**
 * @param {string} value
 * @return {number} 숫자를 제외한 값을 제거한 뒤 리턴.
 * @example
 * pixelToNumber("10px");
 * // => 10
 */
export const pixelToNumber = (value = "") => {
  return Number(value.replaceAll(/\D/g, ""));
};

/**
 * @desc LIST MAPPING: Array Object의 date관련된 필드를 포멧팅하여 매핑
 * @param {array} datas  array data
 * @param {string} keyArray key`s of array object
 * @param {string} formatTo 변환할 format rule
 * @return {array}
 */
export const mapListToFormat = (
  datas,
  keyArray,
  formatTo = "longDateValue",
) => {
  if (!Array.isArray(datas) || !keyArray) return datas;
  if (typeof keyArray === "string") {
    keyArray = [keyArray];
  }
  return datas.map((data) => {
    for (let key of keyArray) {
      if (data) {
        if (moment(data[key]).isValid()) {
          data[key] = moment(data[key]).format(DATE_FORMAT[formatTo]);
        }
      }
    }
    return data;
  });
};

/**
 * @desc DATE FORMATTING
 * @param {instance of moment() OR string of Date } date
 * @param {string} formatTo
 * @return {string} formatted date (ex; 2018-01-01)
 */
export const dateFormat = (date, formatTo = "longDate") => {
  if (!date) return null;
  if (!moment.isMoment(date)) date = moment(date);
  // if (!formatTo) formatTo = DATE_FORMAT['longDate'];
  return date.format(DATE_FORMAT[formatTo]);
};

/**
 * @desc DATE FORMATTING
 * @param {instance of moment() OR string of Date } str
 * @return {string} formatted date (ex; 01-01)
 */
export const dateFormatMd = (str) => {
  if (!str) return null;
  return str.replace(/([\d]{2})([\d]{2})/, "$1-$2");
};

/**
 * @desc DATE FORMATTING
 * @param {instance of moment() OR string of Date } date
 * @param {string} formatTo
 * @return {string} formatted date (ex; 2018-01)
 */
export const dateFormatYm = (date, formatTo = "longMonth") => {
  if (!date) return null;
  if (!moment.isMoment(date)) date = moment(date);
  // if (!formatTo) formatTo = DATE_FORMAT['longMonth'];
  return date.format(DATE_FORMAT[formatTo]);
};

/**
 * @desc 3자리수 콤마표시
 * @param {string || number} value
 * @return {string}
 */
export const formatToComma = (value) => {
  if (value === null || value === undefined) {
    return value;
  }
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * @desc 3자리수 콤마표시
 * @param {string || number} value
 * @return {string}
 */
export const formatToPhoneNumber = (value) => {
  if (validator.isMobile(value)) {
    let num;
    let numbers;
    const regx = /(01[016789])([1-9]{1}[0-9]{2,3})([0-9]{4})$/;
    if (regx.test(value)) {
      numbers = regx.exec(value);
      num = numbers[1] + "-" + numbers[2] + "-" + numbers[3];
      return num;
    }
  }
};

export const formatToTellNumber = (value) => {
  if (validator.isTell(value)) {
    let numbers = value.replace(
      /(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,
      "$1-$2-$3",
    );
    return numbers;
  }
};

/**
 * @desc 파일사이즈 포멧팅
 * @param {number} byte
 * @return {string} ex; '100MB'
 */
export const formatBytesToSize = (byte) => {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (byte === 0) {
    return "n/a";
  }
  const i = parseInt(Math.floor(Math.log(byte) / Math.log(1024)), 10);
  if (i === 0) {
    return `${byte} ${sizes[i]})`;
  }
  return `${(byte / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const replaceArray = (reference, callback) => {
  let array = [];
  for (let i in reference) {
    let result = callback(reference[i]);
    if (result === null || result === undefined || result === false) {
      continue;
    }
    array.push(result);
  }
  [].splice.apply(reference, [0, reference.length].concat(array));
  return reference;
};

export const isBlank = (value) => {
  if (_.isString(value) && value.trim() === "") return true;
  return (_.isEmpty(value) && !_.isNumber(value)) || _.isNaN(value);
};

export const clone = (value) => {
  if (!value) {
    return value;
  }
  const isObject = typeof value === "object";
  const isArray = Array.isArray(value);
  if (!isObject && !isArray) {
    return value;
  }
  // Removing reference of Array of values
  if (isArray) {
    return _.isEmpty(value) ? value : [...value.map((val) => clone(val))];
  }
  if (isObject) {
    return _.isEmpty(value) ? value : { ...value };
  }
  return value;
};

export const merge = (parent, values) => ({ ...parent, ...values });

export const getExcelColumns = (columns) => {
  const cellList = {};

  for (const idx in columns) {
    const cell = columns[idx];
    if (!isBlank(cell.prop)) {
      cellList[cell.prop] = cell.label;
    }
  }

  return cellList;
};
export const excelDownload = (response, fileName) => {
  if (!window.navigator.msSaveOrOpenBlob) {
    const blob = new Blob([response.data], { type: response.data.type });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = url;
    const contentDisposition = response.headers["content-disposition"];

    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch.length === 2) {
        fileName = fileNameMatch[1];
      }
    }
    link.setAttribute("download", fileName + ".xlsx");
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } else {
    // BLOB FOR EXPLORER 11
    window.navigator.msSaveOrOpenBlob(
      new Blob([response.data]),
      fileName + ".xlsx",
    );
  }
};

export const excelDownloadXls = (response, fileName) => {
  if (!window.navigator.msSaveOrOpenBlob) {
    const blob = new Blob([response.data], { type: response.data.type });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = url;
    const contentDisposition = response.headers["content-disposition"];

    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
      if (fileNameMatch.length === 2) {
        fileName = fileNameMatch[1];
      }
    }
    link.setAttribute("download", fileName + ".xls");
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  } else {
    // BLOB FOR EXPLORER 11
    window.navigator.msSaveOrOpenBlob(
      new Blob([response.data]),
      fileName + ".xls",
    );
  }
};

/**
 * @desc 프로그램 권한에따른 필터 매핑
 * @param {*} programs
 */
export const mapChildProgramsToAuth = (programCode, childPrograms = []) => {
  const programInfo = store.state.user.programs[programCode];
  if (!programInfo || !programInfo.children) return null;
  const result = programInfo.children.filter(
    (program) =>
      childPrograms.findIndex(
        (child) => child.programCode === program.programCode,
      ) !== -1,
  );
  return result;
};

/**
 * @desc CRUD 이벤트권한 반환로직
 * @return {boolean}
 */
export const permission = {
  C: function (eventType) {
    switch (eventType) {
      case "W":
        return true;
      case "R":
        return false;
      default:
        return false;
    }
  },
  R: function (eventType) {
    switch (eventType) {
      case "W":
        return true;
      case "R":
        return true;
      default:
        return false;
    }
  },
  U: function (eventType) {
    switch (eventType) {
      case "W":
        return true;
      case "R":
        return false;
      default:
        return false;
    }
  },
  D: function (eventType) {
    switch (eventType) {
      case "W":
        return true;
      case "R":
        return false;
      default:
        return false;
    }
  },
};

export const eventPermissionCheck = (crudType, programCode) => {
  const uiEventCode = store.getters["user/programEventCode"](programCode);
  switch (crudType) {
    case "C":
      return permission.C(uiEventCode);
    case "R":
      return permission.R(uiEventCode);
    case "U":
      return permission.U(uiEventCode);
    case "D":
      return permission.D(uiEventCode);
    default:
      return null;
  }
};
/**
 * @desc jquery position() polyfil
 */
export const getPosition = (el) => {
  var xPos = 0;
  var yPos = 0;

  while (el) {
    if (el.tagName === "BODY") {
      // deal with browser quirks with body/window/document and page scroll
      var xScroll = el.scrollLeft || document.documentElement.scrollLeft;
      var yScroll = el.scrollTop || document.documentElement.scrollTop;

      xPos += el.offsetLeft - xScroll + el.clientLeft;
      yPos += el.offsetTop - yScroll + el.clientTop;
    } else {
      // for all other non-BODY elements
      xPos += el.offsetLeft - el.scrollLeft + el.clientLeft;
      yPos += el.offsetTop - el.scrollTop + el.clientTop;
    }

    el = el.offsetParent;
  }
  return {
    left: xPos,
    top: yPos,
  };
};
export function jsUcfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function makeSetKeyName(key) {
  return `set${jsUcfirst(key)}`;
}

export function makeSetters(object) {
  let setters = {};

  for (let [key, value] of Object.entries(object)) {
    setters[makeSetKeyName(key)] = function (val) {
      this[key] = val;
    };
  }
  return setters;
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  if (str == null && str == undefined) {
    return false;
  }

  return true;
}

export class Toast {
  constructor() {}

  static success(...args) {
    Toast.message("success", args);
  }

  static error(...args) {
    Toast.message("error", args);
  }

  static warn(...args) {
    Toast.message("warning", args);
  }

  static warning(...args) {
    Toast.message("warning", args);
  }

  static info(...args) {
    Toast.message("info", args);
  }

  static message(type, [path, msgParam]) {
    Vue.message({
      type: type,
      message: i18n.t.apply(i18n, [path, msgParam]),
    });
  }
}

/**
 * 하위 컴포넌트 메서드를 실행합니다
 * @param option
 */
export async function executeSubComponentMethod(option, refs, inst) {
  try {
    //refName, 실행할 메서드 이름, 메서드 실행시 입력할 매개변수
    let { refName, methodName, parameter } = option;
    /**
     * 프로그램 코드와 같은 ref를 찾습니다.
     */
    let currentJDMRefKey = Object.keys(refs).find((key) => key == refName);

    let currentJDMRef = refs[currentJDMRefKey];

    if (Array.isArray(refs[currentJDMRefKey])) {
      currentJDMRef = currentJDMRef[0];
    }

    /**
     * 메서드가 있는지 검사하고 있으면 실행합니다.
     */
    let method = currentJDMRef[methodName];
    if (_.isFunction(method)) {
      return await method.apply(null, parameter);
    }
  } catch (e) {}
}

/**
 ALL – 모든 로깅.
 DEBUG – 디버깅.
 INFO – 강조 정보.
 WARN – 경고.
 ERROR –오류.
 OFF – 로깅 해제.
 **/
export class Logger {
  constructor(name = "", level = "off") {
    this.name = name;
    this.level = level;

    let levels = (this.levels = {
      off: [],
      info: ["info"],
    });

    levels.error = [...levels.info, "error"];
    levels.warn = [...levels.error, "warn"];
    levels.debug = [...levels.warn, "debug"];
    levels.all = [...levels.debug, "all"];

    this.labelCounterStore = {};
  }

  log(level, args) {
    if (this.levels[this.level].includes(level)) {
      console.log.apply(null, [`${this.name} ::`, ...args]);
    }
  }

  group() {
    console.group();
  }

  groupEnd() {
    console.groupEnd();
  }

  count(label) {
    console.count(label);
  }

  getLabelCountNumber(label) {
    if (!this.labelCounterStore[label]) {
      this.labelCounterStore[label] = 0;
    }
    this.labelCounterStore[label] += 1;
    return this.labelCounterStore[label];
  }

  getLastLabelCountNumber(label) {
    return this.labelCounterStore[label];
  }

  time(label) {
    let wholeLabel = `${this.name} :: ${label}`;
    console.time(wholeLabel);
  }

  timeEnd(label) {
    let wholeLabel = `${this.name} :: ${label}`;
    console.timeEnd(wholeLabel);
  }

  all(...args) {
    this.log("all", args);
  }

  debug(...args) {
    this.log("debug", args);
  }

  error(...args) {
    this.log("error", args);
  }

  warn(...args) {
    this.log("warn", args);
  }

  info(...args) {
    this.log("info", args);
  }
}

export function RRNFormatter(registrationNumber) {
  if (registrationNumber) {
    registrationNumber = registrationNumber.trim();
    if (registrationNumber.length === 13) {
      const regStr = registrationNumber.substring(0, 6);
      const reg = registrationNumber.substring(6);
      const regNum = regStr + "-" + reg;
      return regNum;
    }
  }

  return registrationNumber;
}

/**
 * 두 datePicker를 비교하여 뒤에 날짜가 빠르면 빠른 날짜를 앞으로 보내주는 함수 입니다.
 * @param {string} start 앞에 있는 datePicker v-model의 이름을 넘겨줍니다. (searchParams.startYmd 라면 target에 searchParams까지 주고  startYmd만 줍니다.)
 * @param {string} end 뒤에 있는 datePicker v-model의 이름을 넘겨줍니다. (searchParams.endYmd 라면 target에 searchParams까지 주고  endYmd만 줍니다.)
 * @param {object} target this가 넘어옵니다.
 */
export function dateRangeCalibrator(start, end, target) {
  if (_.get(target, start) && _.get(target, end)) {
    const sDate = Number(_.get(target, start));
    const eDate = Number(_.get(target, end));
    if (sDate !== eDate && sDate > eDate) {
      _.set(target, start, `${eDate}`);
      _.set(target, end, `${sDate}`);
    }
  }
}

/**
 *
 * @param {Array} arr
 * @param {String} targetDate
 * @return {String} nearestDate
 */

export function findNearestDate(arr, targetDate, dateFormat = "YYYYMMDD") {
  const pivotDate = moment(targetDate, dateFormat);
  if (!Array.isArray(arr)) throw "#1 argument must be an array";
  if (!pivotDate.isValid()) throw "Invalid targetDate";
  let nearest = Infinity;
  let idx = 0;
  arr.some((v, i) => {
    const diff = Math.abs(pivotDate.diff(moment(v, dateFormat)));
    if (diff === 0 || nearest === diff) {
      idx = i;
      return true;
    }
    if (diff < nearest) {
      idx = i;
      nearest = diff;
    }
  });
  return arr[idx];
}

export default {
  mapListToFormat,
  dateFormat,
  dateFormatMd,
  dateFormatYm,
  formatToPhoneNumber,
  formatToTellNumber,
  formatToComma,
  formatBytesToSize,
  replaceArray,
  isBlank,
  clone,
  merge,
  getExcelColumns,
  excelDownload,
  excelDownloadXls,
  mapChildProgramsToAuth,
  eventPermissionCheck,
  permission,
  getPosition,
  makeSetKeyName,
  isJsonString,
  makeSetters,
  Logger,
  dateRangeCalibrator,
  findNearestDate,
  isNextDay,
};
