import store from "@/store";
import { permission } from "@/lib/util";

/**
 * @description 프로그램 store정보 조회
 * @param {string} programCode
 * @return {object}
 */
export const authEventUtil = function (programCode) {
  function getEventCode(code) {
    return store.getters["user/programEventCode"](code);
  }
  return {
    methods: {
      searchEventCode(code = programCode) {
        return getEventCode(code);
      },
    },
    computed: {
      eventCode() {
        return getEventCode(programCode);
      },
      permissionC() {
        return permission.C(
          store.getters["user/programEventCode"](programCode),
        );
      },
      permissionR() {
        return permission.R(
          store.getters["user/programEventCode"](programCode),
        );
      },
      permissionU() {
        return permission.U(
          store.getters["user/programEventCode"](programCode),
        );
      },
      permissionD() {
        return permission.D(
          store.getters["user/programEventCode"](programCode),
        );
      },
    },
  };
};

export default {
  authEventUtil,
};
