<template>
  <div class="command-k-item__inner command-k-item__inner--search">
    <iconify-icon class="command-k-item__icon" icon="mi:search" />

    <div class="comment-k-item__label">
      <div class="comment-k-item__text">
        <strong>{{ search }}</strong>
      </div>
    </div>

    <div class="command-k-item__text--search">
      {{ $t("커맨드케이.리스트.아이템.텍스트.통합검색") }}
    </div>
  </div>
</template>

<script>
/**
 * cmdK 아이템 중 최상단 통합검색 아이템 컴포넌트.
 */
export default {
  name: "CommandKItemSearch",

  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  computed: {
    search() {
      return this.$store.state.commandK.search;
    },
    isSearching() {
      return this.$store.getters["commandK/isSearching"];
    },
  },
};
</script>
