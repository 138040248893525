import Message from "element-ui/packages/message";
import MessageBox from "element-ui/packages/message-box";

/**
 * @desc element-ui message custom wrapper
 */
export const messageWrap = function messageWrap(options) {
  const defaultTitles = {
    success: "SUCCESS",
    info: "INFO",
    warning: "WARNING",
    error: "ERROR",
  };
  let html = `
    <div class="message">${options.message}</div>
  `;
  if (options.title || options.type) {
    html =
      `<div class="title">${
        options.title || defaultTitles[options.type]
      }</div>` + html;
  }
  const defaults = {
    customClass: "is-custom",
    dangerouslyUseHTMLString: true,
    showClose: true,
    // ...other default options
    // duration: 1000000
  };
  return Message(
    Object.assign({}, defaults, options, {
      message: html,
    }),
  );
};

/**
 * @desc element-ui confirm custom wrapper
 */
export const confirmWrap = function confirmWrap(message, title, options) {
  if (typeof title === "object") {
    options = title;
    title = "";
  } else if (title === undefined) {
    title = "";
  }
  const defaults = {
    confirmButtonClass: "el-button--sm el-button--fillblue",
    cancelButtonClass: "el-button--sm",
    // confirmButtonText: '확인',
    // cancelButtonText: '취소'
  };
  return MessageBox.confirm.call(
    this,
    message,
    title,
    Object.assign({}, defaults, options),
  );
  // return MessageBox.confirm(message, title, Object.assign({}, defaults, options));
};

/**
 * @desc element-ui confirm custom wrapper
 */
export const alertWrap = function alertWrap(message, title, options) {
  if (typeof title === "object") {
    options = title;
    title = "";
  } else if (title === undefined) {
    title = "";
  }
  const defaults = {
    confirmButtonClass: "el-button--sm el-button--fillblue",
    cancelButtonClass: "el-button--sm",
    // confirmButtonText: '확인',
    // cancelButtonText: '취소'
  };
  return MessageBox.alert.call(
    this,
    message,
    title,
    Object.assign({}, defaults, options),
  );
};
