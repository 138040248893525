import helpers from "@/auth/helpers";
import Qs from "qs";

export default {
  getEmployeesBasic(param) {
    return helpers.get("/employees/basic", param);
  },
  putEmployeesBasic(param) {
    return helpers.put("/employees/basic", param);
  },
  getPersonMaster(params) {
    let para = {
      params: params,
      paramsSerializer: function (params) {
        return Qs.stringify(params, { arrayFormat: "repeat" });
      },
    };

    return helpers.get("/per/master", para);
  },
};
