<script>
import FileUpload from "./FileUpload";
import { FILE as FILE_RULE } from "@/config/rule";
import helpers from "@/auth/helpers";

/**
 * @desc FileUpload.vue 파일 확장형태
 */
export default {
  name: "PictureUpload",
  extends: FileUpload,
  props: {
    // eslint-disable-next-line vue/require-prop-type-constructor,vue/require-default-prop
    showFileList: false,
    employeeId: {
      type: [String, Number],
      required: true,
      default: null,
    },
    title: {
      type: String,
      default() {
        return "HPE1200.사진등록";
      },
    },
    allowMimeTypes: {
      type: Array,
      default() {
        return FILE_RULE.image.mimeTypes;
      },
    },
    allowExtensions: {
      type: Array,
      default() {
        return FILE_RULE.image.extensions;
      },
    },
    // 단위: KB
    maxSize: {
      type: Number,
      default: FILE_RULE.image.size,
    },
  },
  computed: {
    bindUploadAttributes() {
      return {};
    },
  },
  methods: {
    async handleSuccess(response, file, fileList) {
      const self = this;
      const { attachGroupId, attachValueId } = response.data.payload;
      await helpers
        .put(`/employees/${self.employeeId}/picture`, {
          employeeId: self.employeeId,
          pictureAttachGroupId: attachGroupId,
          pictureAttachValueId: attachValueId,
        })
        .then(() => {
          const result = {
            payload: {
              attachGroupId,
              attachValueId,
            },
          };
          self.fileList = self.mapFileList([file]);
          self.$emit("success", result, file, fileList);
          // eslint-disable-next-line handle-callback-err
        })
        .catch((error) => console.log("error: ", error));

      this.percent = -1;
      this.pending = false;
    },
  },
};
</script>
