import Vue from "vue";

// Common State.
const MAX_TABSIZE = 10;
const defaults = {
  passwordPrompt: {
    open: false,
    resolve: null,
    reject: null,
  },
  title: "",
  layout: "PublicLayout",
  tab: {
    currentIndex: null,
    currentTab: "", // ProgramCode
    tabs: [],
    tabsCompanyCode: {},
    tabsStandardYmd: {},
  },
  searchParamsReady: false,
  searchPanelOnOffFlag: {
    HPE1500: true,
    HPE1600: true,
    HPE1910: true,
    HPE1900: true,
    HPE1901: true,
    HPE1902: true,
    HPE2000: true,
    HAS1010: true,
    HAS1030: true,
    HAS1101: true,
    HAS1190: true,
    HAS1102: true,
    HAS1103: true,
    HAS1104: true,
    HPE2100: true,
    HTM1020: true,
    HTM1290: true,
    HTM1295: true,
    HTM1170: true,
    HTM1195: true,
    HTM1310: true,
    HTM1250: true,
    HTM1240: true,
    HTM1260: true,
    HTM1270: true,
    HTM1180: true,
    HTM1230: true,
    HTM1350: true,
    HTM1480: true,
    HTM1490: true,
    HTM1510: true,
    HTM1520: true,
    HTM1530: true,
    HPE1710: true,
    HPE1720: true,
    HPE1730: true,
    HPE1310: true,
    HPE3000: true,
  },
  searchParams: {
    effectiveDate: undefined,
    lookupValueId: undefined,
    companyListOptions: [],
  },
  hpeSearchParams: {
    dateStart: undefined,
    dateEnd: undefined,
    dateType: undefined,
    dutyCode: undefined,
    employeeId: undefined,
    organizationId: undefined,
    officeStatusSearchCode: undefined,
  },
  fetchTree: false,
  searchUser: null,
  cloudInfo: {},
  cloudLogo: [],
};
// Global module loaded on first app load.

export default {
  namespaced: true,

  state: Object.assign({}, defaults),

  mutations: {
    updatePasswordPromptOpen(state, value) {
      state.passwordPrompt = {
        ...state.passwordPrompt,
        open: value,
      };
    },
    updatePasswordPromptResolveAndReject(state, value) {
      state.passwordPrompt = {
        ...state.passwordPrompt,
        resolve: value.resolve,
        reject: value.reject,
      };
    },
    updateSearchUser(state, value) {
      state.searchUser = value;
    },
    updateFetchTree(state, value) {
      state.fetchTree = value;
    },

    hpeSearchParams(state, value) {
      state.hpeSearchParams = value;
    },
    setHPESearchParams(state, value) {
      state.hpeSearchParams = value;
    },
    setSearchParamsReady(state, bool) {
      state.searchParamsReady = bool;
    },

    updateEffectiveDate(state, date) {
      state.searchParams.effectiveDate = date;
    },

    updateLookupValueId(state, lookupValueId) {
      state.searchParams.lookupValueId = lookupValueId;
    },

    updateCompanyListOptions(state, companyListOptions) {
      state.searchParams.companyListOptions = [...companyListOptions];
    },

    updateSidebar(state, options) {
      state.sidebar = Object.assign(defaults.sidebar, options);
    },

    updateTitle(state, title) {
      state.title = title;
    },

    updateLayout(state, layout) {
      state.layout = layout;
    },

    updateDialog(state, options) {
      state.dialog = Object.assign({}, defaults.dialog, options);
    },

    updateSnackbar(state, options) {
      state.snackbar = Object.assign({}, defaults.snackbar, options);
    },

    error(state, options) {
      state.error = Object.assign({}, defaults.error, options);
    },

    clear(state) {
      // eslint-disable-next-line no-unused-vars
      state = Object.assign({}, defaults);
    },

    /**
     * 사용자가 선택한 법인ID를 탭별로 저장한다.
     * pageHeader 구조가 tab을 이동할 떄 pageHeader 컴포넌트의 생성이 계속되므로
     * 이전에 사용했던 법인ID의 저장이 필요하다.
     */
    setTabsCompanyCode(state, companyCode) {
      state.tab.tabsCompanyCode[state.tab.currentTab] = companyCode;
      state.tab.tabsCompanyCode = Object.assign({}, state.tab.tabsCompanyCode);
    },
    setTabsCompanyCodeForNewTab(state, { companyCode, currentTab }) {
      state.tab.tabsCompanyCode[currentTab] = companyCode;
      state.tab.currentTab = currentTab;
      state.tab.tabsCompanyCode = Object.assign({}, state.tab.tabsCompanyCode);
    },
    setTabsStandardYmd(state, standardYmd) {
      state.tab.tabsStandardYmd[state.tab.currentTab] = standardYmd;
      state.tab.tabsStandardYmd = Object.assign({}, state.tab.tabsStandardYmd);
    },
    setSearchPanelOnOffFlag(state, searchPanelOnOffFlag) {
      state.searchPanelOnOffFlag = {
        ...state.searchPanelOnOffFlag,
        ...searchPanelOnOffFlag,
      };
    },
    updateCloudInfo(state, data) {
      state.cloudInfo = data;
    },
    updateCloudLogo(state, data) {
      state.cloudLogo = data;
    },
  },
  getters: {
    searchUser: (state) => {
      return state.searchUser;
    },
    fetchTree: (state) => {
      return state.fetchTree;
    },

    hpeSearchParams: (state) => {
      return state.hpeSearchParams;
    },

    tab: (state) => {
      return state.tab;
    },
    tabs: (state) => {
      return state.tabs;
    },
    searchParams: (state) => {
      console.log(state);
      return state.searchParams;
    },
    searchParamsReady: (state) => {
      return state.searchParamsReady;
    },
    effectiveDate: (state) => state.searchParams.effectiveDate,
    /**
     * 사용자가 탭별 법인 ID를 반환한다.
     */
    tabsCompanyCode(state) {
      return state.tab.tabsCompanyCode[state.tab.currentTab];
    },
    tabsStandardYmd(state) {
      return state.tab.tabsStandardYmd[state.tab.currentTab];
    },
    companyListOptions(state) {
      return state.searchParams.companyListOptions;
    },
    cloudInfo: (state) => state.cloudInfo,
    cloudLogo: (state) => state.cloudLogo,
    searchPanelOnOffFlag(state) {
      return state.searchPanelOnOffFlag;
    },
  },
  actions: {
    clear({ commit, dispatch }) {
      commit("clear");
      dispatch("auth/clear", {}, { root: true });
      dispatch("settings/clear", {}, { root: true });
    },
    syncTab({ rootState, dispatch }) {
      let tab = {};
      tab.currentIndex = rootState.settings.tab.currentIndex;
      tab.currentTab = rootState.settings.tab.currentTab;
      rootState.settings.tab.tabs.forEach((code) => {
        dispatch("pushTab", { programCode: code });
      });
      dispatch("updateCurrentTab", tab);
    },

    updatePrograms({ commit }, data) {
      commit("updatePrograms", data);
    },

    updateCurrentTab({ state, commit, dispatch }, tab) {
      commit("updateCurrentTab", tab);
      dispatch("settings/updateTab", state.tab, { root: true });
    },

    removeTab({ state, commit, dispatch }, { instance, tab }) {
      let newTab = {};
      const noop = function () {};
      if (state.tab.tabs.length <= 1) {
        Vue.alert(instance.$tc("msg.removeTabmenuFailure")).then(noop);
        return false;
      }
      if (state.tab.tabs[tab.currentIndex] !== tab.currentTab) {
        Vue.alert(instance.$tc("msg.normalFailure")).then(noop);
        return false;
      }

      if (state.tab.currentIndex === tab.currentIndex) {
        if (state.tab.currentIndex === 0) {
          newTab.currentIndex = 0;
        } else {
          newTab.currentIndex = tab.currentIndex - 1;
        }
      } else if (tab.currentIndex < state.tab.currentIndex) {
        newTab.currentIndex = tab.currentIndex;
      } else {
        newTab.currentIndex = tab.currentIndex;
      }
      commit("pullTab", tab.currentIndex);
      newTab.currentTab = state.tab.tabs[newTab.currentIndex];
      dispatch("updateCurrentTab", newTab);
    },
    clearOtherTab({ state, dispatch }) {
      state.tab.tabs = [state.tab.currentTab];
      state.tab.tabsCompanyCode = {
        [state.tab.currentTab]: state.tab.tabsCompanyCode[state.tab.currentTab],
      };
      state.tab.currentIndex = 0;

      dispatch("updateCurrentTab", state.tab);
    },

    forceRemoveTab({ state, commit, dispatch }, programCode) {
      let tab = {};
      for (let i = 0; i < state.tab.tabs.length; i++) {
        if (state.tab.tabs[i] === programCode) {
          commit("pullTab", i);
          tab.currentIndex = i - 1;
          tab.currentTab = state.tab.tabs[i - 1] || "";
          dispatch("updateCurrentTab", tab);
        }
      }
    },

    pushTab({ state, commit, dispatch }, { instance, programCode }) {
      let tab = {};
      for (let index in state.tab.tabs) {
        // prevent duplicate
        if (state.tab.tabs[index] === programCode) {
          tab.currentTab = programCode;
          tab.currentIndex = index;
          commit("updateCurrentTab", tab);
          return false;
        }
      }

      if (state.tab.tabs.length >= MAX_TABSIZE) {
        Vue.alert(
          instance.$tc("msg.addTabmenuFailureMoreThan", null, {
            size: MAX_TABSIZE,
          }),
        );
        return false;
      }

      let regEx = /([a-zA-Z]{3,4})(\d{4})/g; // match 0 : full 1 : 3-4 matched letters 2 : 4digits
      let path = regEx.exec(programCode)[1];
      if (state.tab.tabs.length < MAX_TABSIZE) {
        Vue.component(programCode, function (resolve) {
          require(["@/pages/" + path + "/" + programCode + ".vue"], resolve)
            .then(() => {})
            .catch((err) => {
              if (err) {
                Vue.message({
                  type: "error",
                  message: instance.$tc("msg.noProgramCode", null, {
                    programCode: programCode,
                  }),
                });
                delete Vue.options.components[programCode];
                dispatch("forceRemoveTab", programCode);
              }
            });
        });

        tab.currentTab = programCode;
        tab.currentIndex = state.tab.tabs.length;
        commit("pushTab", programCode);
        dispatch("updateCurrentTab", tab);
      } else {
        let tab = {};
        tab.currentTab = state.tab.tabs[tab.currentIndex].programCode;
        tab.currentIndex = state.tab.tabs.length - 1;
        dispatch("updateCurrentTab", tab);
      }
    },

    updateSidebar({ commit }, options) {
      commit("updateSidebar", options);
    },

    updateTitle({ commit }, title) {
      commit("updateTitle", title);
    },

    updateLayout({ commit }, layout) {
      commit("updateLayout", layout);
    },

    updateDialog({ commit }, options) {
      commit("updateDialog", options);
    },

    updateSnackbar({ commit }, options) {
      commit("updateSnackbar", options);
    },
  },
};
