<template>
  <el-dialog
    ref="commandK"
    :visible.sync="visible"
    :show-close="false"
    width="640px"
    custom-class="headless"
    append-to-body
    @closed="resetCommandK"
  >
    <command-k @select="handleSelect">
      <header class="command-k-header">
        <iconify-icon
          width="24px"
          class="command-k-header__icon"
          :icon="searchIcon"
        />
        <command-k-input
          ref="input"
          v-model="query"
          :placeholder="$t('커맨드케이.검색_인풋.플레이스홀더')"
        />
      </header>

      <main class="command-k-body">
        <command-k-list>
          <command-k-group
            v-for="group in allItems"
            :key="group.groupId"
            :heading="group.heading"
            :group-id="group.groupId"
            :fixed="group.fixed"
          >
            <command-k-item
              v-for="child in group.children"
              :key="child.itemId"
              :item="child"
              @[EVENT_SELECT_ITEM]="handleSelect"
            >
              <command-k-item-search
                v-if="child.type === ITEM_TYPE_SEARCH"
                :item="child"
              />

              <command-k-item-user
                v-else-if="child.type === ITEM_TYPE_USER"
                :item="child"
              />

              <command-k-item-groupware
                v-else-if="child.type === ITEM_TYPE_COMMUNITY"
                :item="child"
              />

              <command-k-item-menu v-else :item="child" />
            </command-k-item>

            <command-k-item-more-items
              v-if="group.hasMoreItems"
              :target-host="hubBaseHost()"
            />
          </command-k-group>

          <command-k-empty
            >{{ $t("커맨드케이.리스트.검색결과_없음") }}
          </command-k-empty>
        </command-k-list>
      </main>

      <command-k-footer />
    </command-k>
  </el-dialog>
</template>

<script>
import { debounce } from "lodash";
import { nanoid } from "nanoid";

import {
  COMMAND_K,
  handleFocusTrap,
  makeHostPath,
  makeKeywords,
  makeItemType,
  makeItemIcon,
  makeGroupTitle,
} from "@/components/command-k/utils";

import CommandK from "@/components/command-k/CommandK.vue";
import CommandKInput from "@/components/command-k/CommandKInput.vue";
import CommandKList from "@/components/command-k/CommandKList.vue";
import CommandKGroup from "@/components/command-k/CommandKGroup.vue";
import CommandKItem from "@/components/command-k/CommandKItem.vue";
import CommandKFooter from "@/components/command-k/CommandKFooter.vue";
import CommandKEmpty from "@/components/command-k/CommandKEmpty.vue";
import CommandKItemUser from "@/components/command-k/command-k-item/CommandKItemUser.vue";
import CommandKItemGroupware from "@/components/command-k/command-k-item/CommandKItemGroupware.vue";
import CommandKItemMenu from "@/components/command-k/command-k-item/CommandKItemMenu.vue";
import CommandKItemSearch from "@/components/command-k/command-k-item/CommandKItemSearch.vue";
import CommandKItemMoreItems from "@/components/command-k/command-k-item/CommandKItemMoreItems.vue";

import { hubBaseHost } from "@/config/cloudEnv";

const {
  EVENT_SELECT_ITEM,
  FOCUS_TRAP_SELECTOR,
  ITEM_TYPE_USER,
  ITEM_TYPE_GW,
  ITEM_TYPE_EA,
  ITEM_TYPE_HR,
  ITEM_TYPE_FN,
  ITEM_TYPE_MENU,
  ITEM_TYPE_DRAFT,
  ITEM_TYPE_KANBAN,
  ITEM_TYPE_COMMUNITY,
  ITEM_TYPE_SEARCH,
  ITEM_MENU_TYPE_CODE_EXTERNAL,
} = COMMAND_K;

export default {
  name: "CommandKIndex",

  components: {
    CommandKItemMoreItems,
    CommandKItemSearch,
    CommandKItemGroupware,
    CommandKItemUser,
    CommandKEmpty,
    CommandKFooter,
    CommandKItem,
    CommandKGroup,
    CommandKList,
    CommandK,
    CommandKInput,
    CommandKItemMenu,
  },

  data() {
    return {
      hubBaseHost,

      ITEM_TYPE_USER,
      ITEM_TYPE_GW,
      ITEM_TYPE_EA,
      ITEM_TYPE_HR,
      ITEM_TYPE_FN,
      ITEM_TYPE_KANBAN,
      ITEM_TYPE_MENU,
      ITEM_TYPE_DRAFT,
      ITEM_TYPE_COMMUNITY,
      ITEM_TYPE_SEARCH,
      ITEM_MENU_TYPE_CODE_EXTERNAL,
      FOCUS_TRAP_SELECTOR,
      EVENT_SELECT_ITEM,

      // 통합 검색 결과 저장
      asyncItems: [],

      query: "",

      loadingTimeout: null,
    };
  },

  computed: {
    focusableElements() {
      return this.$store.state.commandK.focusableElements;
    },
    isSearching() {
      return this.$store.getters["commandK/isSearching"];
    },
    allItems() {
      // 검색어 입력중에는 통합 검색 아이템을 맨 위에 노출
      if (this.isSearching) {
        return [this.searchItem, ...this.groupedAllMenus, ...this.asyncItems];
      }
      return [...this.groupedAllMenus, ...this.asyncItems];
    },
    groupedAllMenus() {
      // 프론트에서 메뉴 정렬
      return this.allMenus
        .slice()
        .sort((a, b) => {
          if (a.menuName < b.menuName) {
            return -1;
          }
          if (a.menuName > b.menuName) {
            return 1;
          }
          return 0;
        })
        .reduce((acc, cur) => {
          // 메뉴 타입에 따라 그룹핑
          const type = makeItemType(cur);
          const group = acc.find((g) => g.type === type);

          const item = {
            type,
            itemId: cur.itemId,
            label: cur.menuName,
            fixed: false,
            keywords: makeKeywords(cur, "menuName"),
            icon: makeItemIcon(type),
            rawData: cur,
            action: () => {
              if (cur.system === ITEM_TYPE_HR) {
                this.$tabs.open(cur.urlPath);
                this.visible = false;
              } else if (cur.menuTypeCode === ITEM_MENU_TYPE_CODE_EXTERNAL) {
                // 외부 링크인 경우 새창으로 열기
                window.open(cur.urlPath, "_blank");
              } else {
                window.location.href = makeHostPath(cur.system, cur.urlPath);
              }
            },
          };

          if (group) {
            group.children.push(item);
          } else {
            acc.push({
              type,
              groupId: nanoid(),
              heading: makeGroupTitle(cur),
              children: [item],
            });
          }
          return acc;
        }, []);
    },
    allMenus() {
      return this.$store.state.commandK.allMenus.map((item) => ({
        ...item,
        itemId: nanoid(),
      }));
    },
    searchIcon() {
      return this.isLoading ? "eos-icons:loading" : "mi:search";
    },
    isFetching() {
      return this.$store.state.commandK.fetching;
    },
    isReady() {
      return this.$store.state.commandK.ready;
    },
    isLoading: {
      get() {
        return this.$store.state.commandK.loading;
      },
      set(boolean) {
        this.$store.commit("commandK/setLoading", boolean);
      },
    },
    visible: {
      get() {
        return this.$store.state.commandK.visible;
      },
      set(value) {
        // 초기 데이터가 없는 경우 다이얼로그를 노출하지 않음
        if (!this.isReady) {
          return;
        }
        this.$store.commit("commandK/setVisible", value);
      },
    },
  },

  watch: {
    query(value) {
      const query = value.trim();
      this.resetAsyncData();
      if (query.length === 0) {
        return;
      }

      this.search(value.trim());
    },
  },

  created() {
    this.searchItem = {
      heading: "",
      groupId: ITEM_TYPE_SEARCH,
      fixed: true,
      children: [
        {
          type: ITEM_TYPE_SEARCH,
          itemId: ITEM_TYPE_SEARCH,
          label: ITEM_TYPE_SEARCH,
          fixed: true,
          action: () => {
            window.location.href = `${hubBaseHost()}/search?keyword=${encodeURIComponent(
              this.query,
            )}`;
          },
        },
      ],
    };
    window.addEventListener("keydown", this.handleKeydown);
  },

  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydown);
    clearTimeout(this.loadingTimeout);
  },

  mounted() {
    this.$nextTick(() => {
      // 포커스 트랩 초기화
      this.findFocusableElement();
    });
  },
  updated() {
    // 지속적으로 포커스 가능 객체 초기화
    if (this.visible) {
      this.findFocusableElement();
    }
  },

  methods: {
    // DOM 업데이트 후 포커스가능 객체 초기화
    findFocusableElement() {
      this.$store.commit("commandK/setFocusableElements", [
        ...(this.$refs.commandK?.$el?.querySelectorAll(FOCUS_TRAP_SELECTOR) ??
          []),
      ]);
    },
    async fetchUnifiedSearch(query) {
      return this.$store.dispatch("commandK/fetchUnifiedSearch", query);
    },
    makeAsyncItems(rawData) {
      const items = [];
      if (!rawData) {
        return items;
      }
      const users = (rawData.userSearchResult.users ?? []).map((user) => ({
        itemId: nanoid(),
        label: user.userName,
        fixed: true, // allMenus 를 제외한 모든 아이템은 반드시 fixed: true
        type: ITEM_TYPE_USER,
        icon: makeItemIcon(ITEM_TYPE_USER),
        keywords: [],
        rawData: user,
        action: () => {
          this.$tabs.open({
            name: "HPE1000",
            params: {
              employeeId: user.userId,
            },
          });
          this.visible = false;
        },
      }));

      const posts = rawData.postSearchResult.posts.map((post) => ({
        itemId: nanoid(),
        label: post.title,
        fixed: true,
        type: ITEM_TYPE_COMMUNITY,
        icon: makeItemIcon(ITEM_TYPE_COMMUNITY),
        keywords: [],
        rawData: post,
        action: () => {
          this.routeToCommunity(post);
        },
      }));

      // 추가 아이템이 있는지 체크하기 위한 변수
      const hasMoreUsers =
        users.length < rawData.userSearchResult.userTotalHits;
      const hasMorePosts =
        posts.length < rawData.postSearchResult.postTotalHits;
      const userGroup = {
        hasMoreItems: hasMoreUsers,
        itemId: nanoid(),
        heading: this.$t("커맨드케이.리스트.해딩.통합검색_구성원"),
        fixed: true,
        children: users,
      };

      const postGroup = {
        hasMoreItems: hasMorePosts,
        itemId: nanoid(),
        heading: this.$t("커맨드케이.리스트.해딩.통합검색_소통"),
        fixed: true,
        children: posts,
      };
      if (users.length) {
        items.push(userGroup);
      }
      if (posts.length) {
        items.push(postGroup);
      }
      this.$store.commit("commandK/setAsyncData", {
        count: users.length + posts.length,
      });

      return items;
    },
    // 통합검색 진행 시 로컬 비동기 데이터 초기화
    resetAsyncData() {
      this.asyncItems = [];
      this.$store.commit("commandK/setAsyncData", { count: 0 });
    },
    search: debounce(async function (query) {
      this.isLoading = true;
      const result = await this.fetchUnifiedSearch(query);
      if (this.query === query) {
        this.asyncItems = this.makeAsyncItems(result);
      }
      // 연속 통신이 발생하는 경우 로딩이 끊기지 않도록 처리
      this.loadingTimeout = setTimeout(() => {
        if (this.isFetching) {
          clearTimeout(this.loadingTimeout);
          return;
        }
        this.isLoading = false;
      }, 25);
    }, 300),
    // 리스트에서 선택된 아이템을 최종적으로 반환 받는 함수 */
    handleSelect(item) {
      item.action();
    },
    handleKeydown(event) {
      // 문자 조합중에는 이벤트를 무시
      if (event.isComposing) {
        return;
      }

      /* NOTE: command(Ctrl) + k */
      if ((event.metaKey || event.ctrlKey) && event.keyCode === 75) {
        event.preventDefault();
        this.toggleVisible();
      }

      /* NOTE: Focus Trap */
      if (this.visible && (event.keyCode === 9 || event.key === "Tab")) {
        handleFocusTrap(event, this.focusableElements);
      }
    },
    resetCommandK() {
      this.$store.dispatch("commandK/reset");
      this.asyncItems = [];
      this.query = "";
    },
    // 소통 게시판으로 이동
    routeToCommunity(post) {
      let path = `${hubBaseHost()}/community/board/${post.menuId}/view/${
        post.listId
      }`;
      switch (post.enabledFlag) {
        case "9":
          path = `${hubBaseHost()}/community/board/${post.menuId}/insert/${
            post.listId
          }`;
          break;
        case "0":
          path = `${hubBaseHost()}/404`;
          break;
      }

      window.location.href = path;
    },
    toggleVisible() {
      this.visible = !this.visible;
    },
  },
};
</script>

<style scoped lang="scss">
$command-k-header-height: 56px;
$command-k-footer-height: 44px;
$command-k-empty-height: 48px;

::v-deep .command-k {
  display: flex;
  flex-direction: column;
  min-height: 0;
  background: $gpro-white;
  border-radius: $--border-radius-base;
  overflow: hidden;

  .command-k-empty {
    font-size: $--font-size-base;
    display: flex;
    align-items: center;
    justify-content: center;
    height: $command-k-empty-height;
    color: $--color-text-regular;
    padding-top: 16px;

    &--hidden {
      height: 0;
    }
  }

  .command-k-header {
    border-bottom: $gpro-border-base;
    height: $command-k-header-height;
    display: flex;
    align-items: center;
    padding: 0 12px;
    border-radius: $--border-radius-base $--border-radius-base 0 0;
  }

  .command-k-header__icon {
    flex: none;
  }

  .command-k-body {
    min-height: 0;
    padding: 0 20px;
    max-height: calc(
      75vh - $command-k-header-height - $command-k-footer-height
    );
    overflow-y: scroll;
  }

  .command-k-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    height: $command-k-footer-height;
    border-top: $gpro-border-base;
    font-size: $--font-size-extra-small;
    cursor: default;
    user-select: none;
  }

  .command-k-footer__commands {
    display: flex;
    align-items: center;
    column-gap: 16px;
  }

  .command-k-footer__command-key {
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: $--color-text-secondary;
  }

  .command-k-footer__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: $--border-radius-base;
    background: $gpro-warm-gray-100;
  }

  .command-k-list {
    height: var(--command-list-height);
    overscroll-behavior: contain;
    transition: 125ms ease;
    transition-property: height;
  }

  .command-k-sizer {
    padding-bottom: 16px;
    min-height: $command-k-empty-height;
  }

  .command-k-item {
    border-radius: $--border-radius-base;
    overflow: hidden;

    @mixin command-k-item__text {
      min-width: 0;
      color: $--color-text-primary;
      @include ellipsis();
    }

    .command-k-item__inner {
      display: flex;
      align-items: center;
      gap: 12px;
      min-height: 56px;
      padding: 8px 16px;
      position: relative;
      cursor: pointer;
      user-select: none;
      transition: all 150ms ease;
      transition-property: none;
      font-size: $--font-size-base;
      color: $--color-text-regular;
      border-radius: $--border-radius-base;
      background: $gpro-warm-gray-50;
      text-decoration: none;

      // 첫 번째 통합 검색 아이템
      &--search {
        margin-top: 16px;
      }
    }

    // 현재 선택된 아이템
    &[aria-selected="true"],
    &:hover {
      .command-k-item__inner {
        background: $gpro-warm-gray-200;
        color: $--color-text-primary;
      }

      .command-k-item__icon-wrapper {
        border: 1px solid $--border-color-base;
      }

      .comment-k-item__text--badge {
        border: 1px solid $--border-color-base !important;
      }
    }

    &[aria-disabled="true"] {
      color: $gpro-warm-gray-50;
      cursor: not-allowed;
    }

    .comment-k-item__label {
      flex: 1;
      display: flex;
      align-items: center;
      min-width: 0;
      overflow: hidden;
      white-space: nowrap;

      &--column,
      &--three-line {
        flex-direction: column;
        align-items: stretch;
        row-gap: 4px;
      }

      .comment-k-item__text-wrapper {
        display: flex;
        align-items: center;
      }

      .comment-k-item__text {
        @include command-k-item__text;
      }

      .comment-k-item__text--secondary {
        @include command-k-item__text;
        flex: none;
        color: $--color-text-regular;
        margin-left: 16px;
        font-size: $--font-size-extra-small;
      }

      .comment-k-item__text--badge {
        flex: none;
        will-change: background;
        transition: all 150ms ease;
        color: $--color-text-secondary;
        font-size: $--font-size-extra-small;
        background: $gpro-warm-gray-100;
        border-radius: $--badge-radius;
        font-weight: $gpro-font-weight-500;
        padding: 2px 6px;
        align-self: flex-start;
        border: 1px solid $--border-color-light;
      }
    }

    .comment-k-item__label--three-line {
      .comment-k-item__text {
        @include command-k-item__text;
      }

      .comment-k-item__text:nth-child(2) {
        color: $--color-text-secondary;
        font-size: $--font-size-extra-small;
      }

      .comment-k-item__text:nth-child(3) {
        color: $--color-text-regular;
        font-size: $--font-size-extra-small;
      }
    }

    .command-k-item__icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $gpro-warm-gray-100;
      border: 1px solid $--border-color-light;
      border-radius: $--border-radius-base;
      padding: 2px;
      will-change: background, color, border;
      transition: all 150ms ease;
    }

    .command-k-item__icon {
      width: 16px;
      height: 16px;
      color: $gpro-warm-gray-500;
    }

    &--user,
    &--community {
      display: contents;
    }

    &__profile-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .command-k-item__text--detail {
      font-size: $--font-size-extra-small;
    }
    .command-k-item__text--search {
      font-size: $--font-size-extra-small;
      color: $--color-text-regular;
    }
  }

  .command-k-more-item-link {
    color: $--link-color;
    align-self: flex-start;
    display: inline-block;
    padding: 4px 0;
  }

  .command-k-input {
    flex: 1;
    font-size: $--font-size-base;
    padding: 12px;
    outline: none;
    border: none;

    &::placeholder {
      color: $--color-text-placeholder;
    }
  }

  .command-k-group__heading {
    font-size: $--font-size-base;
    font-weight: $gpro-font-weight-600;
    color: $--color-text-primary;
    line-height: 32px;
    padding: 8px 0 0;
    z-index: 2;
    width: 100%;
  }

  .command-k-group__list {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}

::v-deep .command-k__user-dialog {
  display: none;
}
</style>
