import moment from "moment";
import { formatBytesToSize } from "@/lib/util";
import i18n from "@/i18n";

export const patterns = {
  integer: /^[0-9]*$/,
  number: /^[0-9]*$/,
  korean: /^[ㄱ-ㅎㅏ-ㅣ가-힣]*$/,
  english: /^[a-zA-Z\s]*$/,
  englishNot: /[^a-zA-Z\s]/g,
  englishLowerNot: /[^A-Z\s]*$/,
  email:
    /[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-_]*[a-zA-Z0-9])[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-_]*[a-zA-Z0-9])?/g,
  mobile: /(01[016789])([1-9]{1}[0-9]{2,3})([0-9]{4})$/,
  mobile2: /[\d \-+]+/,
  mobile2Not: /[^\d \-+()]+/,
  character: /^[ㄱ-ㅎㅏ-ㅣ가-힣|a-zA-Z\s]*$/,
  characterNot: /[^ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z]/g,
  characterWithSpaceNot: /[^ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z ]/g,
  characterNumNot: /[^ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9]/g,
  characterNumNotByCode: /[^ㄱ-ㅎㅏ-ㅣ가-힣a-zA-Z0-9\s-()/_]/g,
  gwIdNot: /[^a-zA-Z0-9.]/g, // 영어, 숫자, 마침표(.)가 아니면
  englishAndNumber: /^[A-Za-z0-9+]*$/,
  regNum1: /^[\d]{6}-[\d]{7}$/,
  regNumLeng: /^[\d-]{14}$/g,
  numDash: /[0-9-]/, // 숫자와 -만 허용
  numDashNot: /[^\d-]/g, // 숫자와 -가 아니면
  numNot: /[^\d]/g, // 숫자가 아니면
  numDotNot: /[^\d.]/g, // 숫자, 마침표 허용
  numDashDotNot: /[^\d-.]/g, // 숫자, 마침표, - 허용
  upperCaseUnderNumberNot: /[^A-Z0-9_]/g, // 대문자, 숫자, 언더바 Not
  upperCaseOrNumber: /^[A-Z0-9+]*$/, // 대문자 및 숫자형식만 허용
  upperCaseOrNumberNot: /[^A-Z0-9]/g, // 대문자 및 숫자가 아니면
  engOrNumberNot: /[^a-zA-Z0-9]/g, // 영어, 숫자 아니면
  engOrNumNot: /[^a-zA-Z0-9-]/g, // 영어, 숫자, 하이픈이 아니면
  langNumBlankBracketDashNot: /[^ㄱ-ㅎㅏ-ㅣ가-힣|a-zA-Z\s0-9-().:]/g, // 한글, 영어, 숫자, 공백, 하이픈, 괄호, 마침표,:가 아니면
  langNumBlankBracketDashNot2: /[^ㄱ-ㅎㅏ-ㅣ가-힣|a-zA-Z\s0-9-().,/&]/g, // 한글,F 영어, 숫자, 공백, 하이픈, 괄호, 마침표, and, 슬래쉬, 컴마 가 아니면
  address: /[{}[\]/?.;:|*~`!^_+<>@#$%&\\='"]/g,
  koreanAndNumberNot: /[^ㄱ-ㅎㅏ-ㅣ가-힣\d]/g, // 한글과 숫자가 아니면
  specialCharacter: /[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"|\d]/gi, // 특수 문자, 숫자 허용
  specialCharacter2: /[{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"]/gi, // 띄어쓰기, 숫자, 문자 허용
  organizationIdName: /[{}[\]/?.,;:|)*~`!^_+<>@#$%\\=('"]/gi, // 문자, 숫자, -, &, 띄어쓰기 허용
  documentNoNot: /[^a-zA-Z0-9-]/gi, // -, 숫자, 영어 허용,
  dotAndNumNot: /[^\d.]/g, // 숫자, 마침표 허용,
  geographicCoordinate: {
    latitude: /^[-+]?((([1-8]{1})?\d{1})(\.[0-9]{1,6})?|90(\.0{1,6})?)$/, // 위도 -+90 + 소수 6자리
    latitude7: /^[-+]?((([1-8]{1})?\d{1})(\.[0-9]{1,7})?|90(\.0{1,7})?)$/, // 위도 -+90 + 소수 7자리
    longitude:
      /^[-+]?(180(\.0{1,6})?|1[0-7]\d(\.[0-9]{1,6})?|[1-9]?\d{1}(\.[0-9]{1,6})?)$/, // 경도 -+180 + 소수 6자리
    longitude7:
      /^[-+]?(180(\.0{1,7})?|1[0-7]\d(\.[0-9]{1,7})?|[1-9]?\d{1}(\.[0-9]{1,7})?)$/, // 경도 -+180 + 소수 7자리
  },
  ip: /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/,
  decimal: /^\d*.?\d{1,3}$/, // 소수점 3자리
};

export const required = (value) => {
  if (value === null || value === undefined) {
    return false;
  }

  if (typeof value === "string" && value.trim() === "") {
    return false;
  }

  return true;
};

export const dateFormat = (date, format) => {
  return moment(date, format).isValid();
};

export const rangeOf = (value, min, max) => {
  const length = required(value) ? value.length : 0;
  return min <= length && max >= length;
};

export const isNumber = (value) => {
  return patterns["number"].test(value);
};

export const isDecimal = (value) => {
  return patterns["decimal"].test(value);
};

export const isInteger = (value) => {
  return patterns["integer"].test(value);
};

export const isEmail = (value) => {
  const regex = new RegExp(patterns["email"], "g");
  return regex.test(value);
};

export const isMobile = (value) => {
  const regex = new RegExp(patterns["mobile"]);
  return regex.test(value);
};

export const isMobile2 = (value) => {
  const regex = new RegExp(patterns["mobile2"]);
  return regex.test(value);
};

export const isOnlyKorean = (value) => {
  return patterns["korean"].test(value);
};

export const isOnlyEnglish = (value) => {
  return patterns["english"].test(value);
};

export const isOnlyChar = (value) => {
  return patterns["character"].test(value);
};

export const isOnlyCharWithSpace = (value) => {
  return patterns["characterWithSpace"].test(value);
};

export const isOnlyEnglishAndNumber = (value) => {
  return patterns["englishAndNumber"].test(value);
};

export const isOnlyRegNum2 = (value) => {
  return patterns["numDash"].test(value);
};

export const isOnlyNumDash = (value) => {
  return patterns["numDash"].test(value);
};

export const removeDash = (value) => {
  return value.split("-").join("");
};

export const extractPattern = (value, patternName) => {
  const trimValue = value.replace(/ |\u200B/g, "");
  const resultValue = new RegExp(patterns[patternName]).exec(trimValue);
  if (resultValue) {
    return resultValue[0];
  } else {
    return resultValue;
  }
};

export const extractLength = (value, min, max) => {
  const regex = new RegExp(`^.{${min},${max}}`);
  const resultValue = value.match(regex);
  if (resultValue) {
    return resultValue[0];
  } else {
    return resultValue;
  }
};

export const replacePattern = (value = "", patternName) => {
  return value.replace(patterns[patternName], "");
};

export const replaceRegnumAddDash = (value) => {
  return value.replace(/([\d*]{6})([\d*]{7})/, "$1-$2");
};
// export const hasSpecChar = (value) => {
//   const regex = new RegExp(patterns['specialCharacter'], 'gi');
//   return regex.test(value);
// };

export const replaceBirthdayAddDash = (value) => {
  return value.replace(/([\d*]{2})([\d*]{2})/, "$1-$2");
};

/**
 *
 * @param {object} file obj
 * @param {object} rules {allowExtensions, allowMimeTypes, maxSize}
 */
export const fileValidator = (file, rules = {}) => {
  let result = {
    valid: true,
    errors: [],
  };

  // Extension CHECK
  if (rules.allowExtensions && rules.allowExtensions.length) {
    const fileExt = file.name.split(".")[file.name.split(".").length - 1];
    if (
      rules.allowExtensions
        .map((ext) => ext.toUpperCase())
        .indexOf(fileExt.toUpperCase()) === -1
    ) {
      const formats = rules.allowExtensions.join(", ");

      result.errors.push(
        i18n.t("msg.fileWarning4", null, {
          type: formats,
        }),
      );
    }
  }

  // MIME TYPE CHECK
  if (rules.allowMimeTypes && rules.allowMimeTypes.length) {
    if (
      rules.allowMimeTypes
        .map((type) => type.toUpperCase())
        .indexOf(file.type.toUpperCase()) === -1
    ) {
      // TODO: i18n
      result.errors.push(i18n.t("msg.mimeTypeError"));
    }
  }

  // SIZE CHECK
  if (file.size > rules.maxSize) {
    // TODO: i18n
    result.errors.push(
      i18n.t("msg.maxSizeOver", null, {
        maxSize: formatBytesToSize(rules.maxSize),
      }),
    );
  }

  result.valid = result.errors.length < 1;
  return result;
};

export const isOnlyUpperCaseOrNumber = (value) => {
  if (!value) return false;
  return patterns["upperCaseOrNumber"].test(value);
};

export const isAfterDate = (startYmdMoment, endYmdMoment) => {
  if (!startYmdMoment || !endYmdMoment) {
    return false;
  }
  return startYmdMoment.isAfter(endYmdMoment);
};

/*
  입력된 문자열의 바이트수 계산하여 정수로 리턴
  한글 3바이트 , 영문,특수문자,숫자 1바이트
 */
export const getByteLength = (s, b = 0, i = 0, c) => {
  if (!s && s !== 0) {
    return 0;
  }
  if (typeof s === "number") {
    s += "";
  }
  // eslint-disable-next-line no-cond-assign
  for (; (c = s.charCodeAt(i++)); b += c >> 11 ? 3 : c >> 7 ? 2 : 1);
  return b;
};

/**
 * @param {object} str string
 * @param {object} maxByte number
 * @return boolean // maxByte보다 문자열 바이트가 적으면 true 크면 false
 */
export const isPassCheckByte = (str, maxByte) => {
  return getByteLength(str) <= maxByte;
};

export const gridDataCheckMaxByte = (data, column) => {
  if (!Array.isArray(data)) {
    data = [data];
  }

  let result = {};
  const byteCheckColumns = column.filter((col) => col.maxByte);
  data.map((row) => {
    byteCheckColumns.map((col) => {
      if (!isPassCheckByte(row[col.prop], col.maxByte)) {
        result.label = col.label;
        result.maxByte = col.maxByte;
        return false;
      }
    });
  });
  return result.label ? { msgType: "msg.maxByteError", param: result } : false;
};

export const gridDataRequired = (data, column) => {
  if (!Array.isArray(data)) {
    data = [data];
  }
  let result = {};
  const requiredColumns = column.filter((col) => col.required);
  data.map((row) => {
    requiredColumns.map((col) => {
      if (!required(row[col.prop])) {
        result.name = col.label;
        return false;
      }
    });
  });

  return result.name
    ? { msgType: "msg.requirementInvalid", param: result }
    : false;
};

export const checkGridData = (data, columns) => {
  let result;
  if (!result) {
    result = gridDataRequired(data, columns);
  }

  if (!result) {
    result = gridDataCheckMaxByte(data, columns);
  }
  return result;
};

export const isLatitude = (value) => {
  return (
    patterns.geographicCoordinate.latitude.test(value) ||
    patterns.geographicCoordinate.latitude7.test(value)
  );
};

export const isLongitude = (value) => {
  return (
    patterns.geographicCoordinate.longitude.test(value) ||
    patterns.geographicCoordinate.longitude7.test(value)
  );
};

export const isIp = (value) => patterns.ip.test(value);

export default {
  isIp,
  required,
  dateFormat,
  rangeOf,
  isMobile,
  isMobile2,
  isNumber,
  isInteger,
  isEmail,
  isOnlyKorean,
  isOnlyEnglish,
  isOnlyChar,
  fileValidator,
  isOnlyEnglishAndNumber,
  // hasSpecChar
  removeDash,
  isOnlyRegNum2,
  isOnlyNumDash,
  patterns,
  extractPattern,
  extractLength,
  replacePattern,
  replaceRegnumAddDash,
  replaceBirthdayAddDash,
  isOnlyUpperCaseOrNumber,
  isAfterDate,
  getByteLength,
  isPassCheckByte,
  gridDataCheckMaxByte,
  gridDataRequired,
  checkGridData,
  isLatitude,
  isLongitude,
  isDecimal,
};
