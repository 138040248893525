import { DATE_FORMAT } from "@/config/rule";
import i18n from "@/i18n";

/**
 * @desc <el-date-picker />
 * date type 베이스옵션
 */
export const datePicker = {
  type: "date",
  size: "small",
  format: DATE_FORMAT["longDate2"],
  valueFormat: DATE_FORMAT["longDateValue2"],
  clearable: false,
  pickerOptions: {
    shortcuts: [
      {
        text: i18n.t("el.datePicker.오늘"),
        onClick(picker) {
          picker.$emit("pick", new Date());
        },
      },
    ],
  },
};

export const datePicker2 = {
  type: "date",
  size: "small",
  format: DATE_FORMAT["longDate2"],
  valueFormat: DATE_FORMAT["longDateValue2"],
  clearable: false,
  pickerOptions: {
    disabledDate(date) {
      return date > new Date();
    },
    shortcuts: [
      {
        text: i18n.t("el.datePicker.오늘"),
        onClick(picker) {
          picker.$emit("pick", new Date());
        },
      },
    ],
  },
};

export const datePicker3 = {
  type: "date",
  size: "small",
  format: DATE_FORMAT["longDate2"],
  valueFormat: DATE_FORMAT["longDate2"],
  clearable: false,
  pickerOptions: {
    shortcuts: [
      {
        text: i18n.t("el.datePicker.오늘"),
        onClick(picker) {
          picker.$emit("pick", new Date());
        },
      },
    ],
  },
};

// 근태용 datePicker
export const datePicker4 = {
  type: "date",
  size: "small",
  format: DATE_FORMAT["longDate2"],
  valueFormat: DATE_FORMAT["longDateValue2"],
  clearable: false,
  pickerOptions: {
    shortcuts: [
      {
        text: i18n.t("el.datePicker.오늘"),
        onClick(picker) {
          picker.$emit("pick", new Date());
        },
      },
    ],
  },
};

/**
 * @desc <el-date-picker />
 * date-range type 베이스옵션
 */
export const daterangePicker = {
  type: "daterange",
  size: "small",
  format: DATE_FORMAT["longDate2"],
  valueFormat: DATE_FORMAT["longDateValue2"],
  rangeSeparator: "~",
  startPlaceholder: i18n.t("el.datePicker.시작일"),
  endPlaceholder: i18n.t("el.datePicker.종료일"),
  clearable: false,
  align: "left",
};

/**
 * @desc
 * <el-date-picker />
 * month type 베이스옵션
 */
export const monthPicker = {
  type: "month",
  size: "small",
  format: DATE_FORMAT["longMonth2"],
  valueFormat: DATE_FORMAT["longMonthValue2"],
  clearable: false,
  pickerOptions: {
    shortcuts: [
      {
        text: i18n.t("el.datePicker.이번달"),
        onClick(picker) {
          picker.$emit("pick", new Date());
        },
      },
    ],
  },
};

/**
 * @desc
 * <el-date-picker />
 * month type 베이스옵션
 */
export const yearPicker = {
  type: "year",
  size: "small",
  format: DATE_FORMAT["longYear2"],
  valueFormat: DATE_FORMAT["longYearValue2"],
  clearable: false,
};

/**
 * @desc
 * <el-tooltip />
 * tooltip 베이스옵션
 */
export const tooltip = {
  effect: "dark",
  popperClass: "el-table--tooltip-popper",
  placement: "bottom",
  transition: "none",
  openDelay: 500,
};
export default {
  datePicker,
  datePicker4,
  daterangePicker,
  monthPicker,
  yearPicker,
  tooltip,
};
