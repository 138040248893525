<template>
  <section class="vacation-info-detail">
    <!-- <div class="vacation-info-detail__header">{{computedTitle}}</div> -->
    <div class="vacation-info-detail__section" style="text-align: center">
      <p style="font-size: 25px">{{ $tc("노무수령거부.타이틀") }}</p>

      <p>{{ $tc("노무수령거부.내용1") }}</p>

      <p>{{ $tc("노무수령거부.내용2") }}</p>

      <p>{{ $tc("노무수령거부.내용3") }}</p>

      <p style="margin-bottom: 40px">{{ currentDate }}</p>
    </div>
    <div class="vacation-info-detail__section" style="text-align: center">
      <el-checkbox v-model="noticeYn" true-label="Y" false-label="N">
        <span class="check-all--label">{{ $tc("노무수령거부.체크내용") }}</span>
      </el-checkbox>
      <p>
        <el-button size="medium" @click="clickOk">
          {{ $tc("노무수령거부.버튼.확인") }}
        </el-button>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "NoticeSend",
  data() {
    return {
      currentDate: this.$moment().format("YYYY.MM.DD"),
      noticeYn: "N",
      isAllReady: false,
      employeeVacationInfo: [],
    };
  },
  mounted() {},
  methods: {
    clickOk() {
      if ("Y" !== this.noticeYn) {
        this.$message({
          type: "error",
          message: this.$t("노무수령거부.메시지.수령확인안함"),
        });
        return;
      }
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-top: 20px;
}
.no-border-bottom {
  .el-table {
    &:before {
      border: 0;
    }
  }
}
.vacation-info-detail {
  min-height: 224px;
  &__section {
    padding: 10px 20px;
    &:first-child {
      border-bottom: 1px solid $--border-color-light;
    }
    &--detail {
      max-height: 400px;
      overflow: scroll;
      overflow-x: hidden;
    }
  }
}
</style>
